import type moment from 'moment-timezone';
import { ApiGuid } from './ApiGuid';
import { ClockDetailsForActivity, ClockEvent, ClockEventMethod, ClockReport, CustomFieldDataGeneric } from './ClockEvent';
import { CsvCell } from './Csv';
import { TimezoneString } from './Datetime';
import { PaginationRequest } from './Pagination';
import { SignatureRequestStatus } from './SignatureRequest';
import { FacialRecognitionResult } from './FacialRecognition';

export enum TimesheetSearchTypes {
    InTimeInclusive = "inTimeInclusive", 
    InOrOutTimeInclusive = "inOrOutTimeInclusive",
    InOutTimeInclusive = "inOutTimeInclusive"
}

export interface TimeSheetQueryParams
{
    searchPeriod: {
        start: moment.Moment | string, //is converted to string for transport
        end: moment.Moment | string,
        searchType: TimesheetSearchTypes,
        // includeInProgressEvents?: boolean
    },
    calculateCost?: boolean,
    calculateOvertime?:boolean,
    applyScheduledDeductions?: boolean 
    activeFilter?: "active" | "deleted" | "all",
    approvedFilter?: "all" | "approved" | "unapproved",
    employeeList?: Array<ApiGuid>,
    jobList?: Array<ApiGuid>,
    groupList?: Array<ApiGuid>,

    includeDeletedCustomFields?: boolean, //custom fields included in clockEvent data will include any data of deleted custom fields
    reportType?: 'custom',      
    // calculateCost:boolean,
    // calculateOvertime:boolean,
    "requestExtraData"?: {
    //     "webInfoIn": true,
    //     "webInfoOut": true,
    //     "phoneInfoIn": true,
    //     "phoneInfoOut": true,
    //     "smsInfoIn": true,
    //     "smsInfoOut": true,
    //     "appInfoIn":true,
    //     "appInfoOut": true,
    //     "gpsInfoIn": true,
    //     "gpsInfoOut": true
        reportsIn?: boolean,    // extraDataVal("Include clock in reports for each clock event"),
        reportsOut?: boolean,    // extraDataVal("Include clock out reports for each clock event"),
        customFieldsIn?: boolean,    // extraDataVal("Include clock in custom field data for each clock event"),
        customFieldsOut?: boolean,    // extraDataVal("Include clock out custom field data for each clock event"),
        editEmployee?: boolean,    // extraDataVal("Include the last employee to edit each clock event"),
        editReport?: boolean,    // extraDataVal("Include all edit notes for each clock event"),
        webInfoIn?: boolean,    // extraDataVal("Include clock in details for web clocks if available. IP, Browser, etc..."),
        phoneInfoIn?: boolean,    //extraDataVal("Include clock in details for phone clocks if available. Caller ID, etc..."),
        smsInfoIn?: boolean,    //extraDataVal("Include clock in details for sms clocks if available. Caller ID, etc..."),
        appInfoIn?: boolean,    //extraDataVal("Include clock in details for app clocks if available. IP, Device, etc..."),
        gpsInfoIn?: boolean,    //extraDataVal("Include clock in GPS info if available. Lat, Lng, etc..."),
        webInfoOut?: boolean,    // extraDataVal("Include clock out details for web clocks if available. IP, Browser, etc..."),
        phoneInfoOut?: boolean,    //extraDataVal("Include clock out details for phone clocks if available. Caller ID, etc..."),
        smsInfoOut?: boolean,    //extraDataVal("Include clock out details for sms clocks if available. Caller ID, etc..."),
        appInfoOut?: boolean,    //extraDataVal("Include clock out details for app clocks if available. IP, Device, etc..."),
        gpsInfoOut?: boolean,    //extraDataVal("Include clock out GPS info if available. Lat, Lng, etc..."),    
        activityNotes?:boolean,
        activityFiles?:boolean,
    }
}

export type TimeSheetQueryResponse = ClockEvent[];

export type SimpleTimeSheetQueryColumns = 
    'employee_full_name'|'job_code_name'|'clock_in_date'|'clock_in_time'|'clock_out_time'|'clock_out_date'|'duration'|'job_guid'|'employee_guid'|'service_item_guid';
    
export type SimpleTimesheetRow = CsvCell[]

export type SimpleTimesheetResponse =
{
    data: SimpleTimesheetRow[],
    page: number,
    pageCount: number,
    recordsPerPage: number,
    totalRecords: number 
}

export type SplitShiftAtMidnightRequestParams = {
    clockEventGuids: ApiGuid[]
}

export type SplitShiftAtMidnightResponse = {
    clockEvents: ClockEvent[]
}

export type ClockEventForCreate = Pick<ClockEvent,"start"|"end"|"inDetails"|"outDetails"|"employeeGuid"|"jobGuid"|"serviceItemGuid"> & {
    manualDuration?: number,
}

export type CreateClockEventListParams = {
    clockEventsList: ClockEventForCreate[]
}

export const ActivitySortOrderList = [
    "firstname",
    "firstnamereverse",
    "lastname",
    "lastnamereverse",
    "group",
    "groupreverse",
    "intime",
    "intimereverse",
    "outtime",
    "outtimereverse",
    "duration",
    "durationreverse",
    "jobcode",
    "jobcodereverse",
    "jobname",
    "jobnamereverse",
    "serviceitemcode",
    "serviceitemcodereverse",
] as const;
type ActivitySortOrderTuple = typeof ActivitySortOrderList;
export type ActivitySortOrder = ActivitySortOrderTuple[number];

export interface ActivityQueryParams
{    
    searchRange: {
        start: string,
        end: string,
    },
    employeeFilter?:{
        employeeGuids: ApiGuid[],
        groupGuids: ApiGuid[],
    }
    jobFilter?: ApiGuid[],
    serviceItemFilter?: ApiGuid[],
    filterApproved?: boolean,
    filterUnapproved?: boolean,
    timeguardOnly?: boolean,
    geofenceClockedOnly?: boolean,
    geofenceWarningOnly?: boolean,

    includeDeleted?: boolean,
    includeConflicted?: boolean,

    includeCustomFields?: boolean,
    includeDailyTotals?: boolean,
    includeSignatureStatus?: boolean,
    sortOrder?: ActivitySortOrder,

    pagination?:Partial<PaginationRequest>,
}

export type ActivityRow = {
    guid:ApiGuid,
    edited: boolean,
    deleted: boolean,
    timeguard: boolean,
    employeeGuid: ApiGuid,
    jobGuid: ApiGuid|null,
    serviceItemGuid: ApiGuid|null,
    clockInTime: string,
    clockOutTime: string|null,
    clockInMethod: ClockEventMethod,
    clockOutMethod: ClockEventMethod|null,
    inReport: ClockReport|null,
    outReport: ClockReport|null,
    customFieldIn: CustomFieldDataGeneric[],
    customFieldOut: CustomFieldDataGeneric[],
    flags: number,
    gpsFlags: number,
    duration: number,
    dailyDuration?: number,
    signatureStatus?: SignatureRequestStatus,
    tpaStatus?: SignatureRequestStatus,
}

export type ActivityHistoryRow = {
    guid:ApiGuid,
    deleted: boolean,
    timeguard: boolean,
    employeeGuid: ApiGuid,
    jobGuid: ApiGuid|null,
    serviceItemGuid: ApiGuid|null,
    clockInTime: string,
    clockOutTime: string|null,
    clockInMethod: ClockEventMethod,
    clockOutMethod: ClockEventMethod|null,
    inReport: ClockReport|null,
    inReportChanged:boolean,
    outReport: ClockReport|null,
    outReportChanged:boolean,
    customFieldIn: CustomFieldDataGeneric[],
    customFieldOut: CustomFieldDataGeneric[],
    flags: number,
    gpsFlags: number,
    duration: number,
    editorGuid: ApiGuid|null,
    editTime: string|null,
    editReport: string|null,
}

export type HistoryResponse = {
    rows: ActivityHistoryRow[],
}

export type ActivityQueryResponse = {
    events: ActivityRow[],
    pagination: {
        page: number,
        perPage: number,
        totalRecords : number,
    },
}

export interface DetailedActivityQueryParams
{
    guid:ApiGuid,
}

export type DetailedActivityResponse = 
{
    rootGuid: ApiGuid,
    inDetails: ClockDetailsForActivity,
    outDetails?: ClockDetailsForActivity,
    notes: ApiActivityNote[],
}

export type ClearTimesheetFlagParams =
{
    clockEventGuid:ApiGuid,
    clearFlagMask:number,
    editNotes?:string,
}

export type ClearTimesheetFlagResponse =
{
    event: ActivityRow,
}

export type ChangeClockEventListStatusParams =
{
    clockEventsList: ApiGuid[],
    status: 'approved'|'unapproved',
}

export type ChangeClockEventListStatusResponse =
{
    editedEvents: ClockEvent[],
}

export type DeleteClockEventListParams =
{
    clockEventsList: ApiGuid[],
    delete: boolean,
}

export type DeleteClockEventListResponse =
{
    editedEvents: ClockEvent[],
}

export type ApiUserFile = {
    guid: ApiGuid
    createDate: string,
    fileURL: string,
    fileSize: number,
    fileType: 'Unknown'|'Image'|'Video',
    metadata: {
        fileName: string,
        originalFileName: string,
        description: string,
        facialRecognitionResult?: FacialRecognitionResult
    },
    clockEventRootGuid: ApiGuid,
    employeeGuid: ApiGuid,
    employeeName: string,       /** @deprecated - React JS doesn't need names */
    jobGuid: ApiGuid|null,
    jobName: string|null,            /** @deprecated - React JS doesn't need names */
}
//could get fancy - union with both null, or both string/guid

export type ApiUserReport = {
    guid: ApiGuid,
    createDate: string,
    report: string,
    clockEventRootGuid: ApiGuid,
    employeeGuid: ApiGuid,
    employeeName: string,       /** @deprecated - React JS doesn't need names */
    jobGuid: ApiGuid|null,
    jobName: string|null,            /** @deprecated - React JS doesn't need names */
}

export type ApiActivityNote = ApiUserFile | ApiUserReport;
export function isUserFile(thing:ApiActivityNote): thing is ApiUserFile {
    return (thing as ApiUserFile).fileURL !== undefined;
}

export type TimeSheetEntry = {
    active: boolean,
    deleted?: boolean,
    guid: ApiGuid,
    rootGuid: ApiGuid,
    employeeGuid: ApiGuid,
    jobGuid: ApiGuid|null,
    serviceItemGuid: ApiGuid|null
    
    // clockInTime: string,
    // clockOutTime: string|null,
    // duration?: number,                       //IF present then clockOutTime is null - manual duration event - (maybe set a bool too for assistence - could then typeguard things)

    // startTimestamp: number, //int - unix timestamp of clock in time, OR noon-in account timezone of the duration event
    // stopTimestamp: number|null,
    start: string,      //iso datetime in utc of clock in time
    end: string|null,   //iso datetime in utc of clock out time, or nul=not clocked out or duration event
    clockInMethod: ClockEventMethod,
    clockOutMethod: ClockEventMethod|null,
    
    durationMinutes: number|null,
    inReport: string|null,
    inCustomFieldData: CustomFieldDataGeneric[],

    outReport: string|null,
    outCustomFieldData: CustomFieldDataGeneric[],

    editNotes: string|null,  
    
    //readonly props - need to go into Omit for update type
}



//relevent details - but read only / not editable 
// {
//     clockInMethod: ClockEventMethod,        //needed for edit form as the type dictates the custom fields + job rules
//     clockOutMethod: ClockEventMethod|null,

// }

export type TimeSheetEntryForCreate = Omit<TimeSheetEntry,'guid'>;
//all optional but guid - which is required (guid functions as a sync ID for the event)
export type TimeSheetEntryForUpdate = TimeSheetEntry;
export type TimeSheetEntryForCreateOrUpdate = TimeSheetEntryForCreate | TimeSheetEntryForUpdate;

export function isTimeSheetEntryForUpdate(thing:TimeSheetEntryForCreateOrUpdate): thing is TimeSheetEntryForUpdate {
    return (thing as TimeSheetEntryForUpdate).guid !== undefined;
}
export function isTimeSheetEntryForCreate(thing:TimeSheetEntryForCreateOrUpdate): thing is TimeSheetEntryForCreate {
    return !isTimeSheetEntryForUpdate(thing);
}
export type CreateTimeSheetEntryRequest = {
    event: TimeSheetEntryForCreate,
    overwriteSignatureRequests?: boolean,
}
export type UpdateTimeSheetEntryRequest = {
    event: TimeSheetEntryForUpdate,
    overwriteSignatureRequests?: boolean,
}
export type CreateUpdateTimeSheetEntriesRequest = {
    createEvents: TimeSheetEntryForCreate[]
    updateEvents: TimeSheetEntryForUpdate[]
    overwriteSignatureRequests?: boolean,
}
export type CreateTimeSheetEntryResponse = {
    event: TimeSheetEntry,
}

export type UpdateTimeSheetEntryResponse = {
    event: TimeSheetEntry,
};
export type CreateUpdateTimeSheetEntriesResponse = {
    createdEvents: TimeSheetEntry[],
    updatedEvents: (TimeSheetEntry|null)[],
}

export type GetTimeSheetEntryRequest = {
    eventGuid: ApiGuid
}
export type GetTimeSheetEntryResponse = {
    event: TimeSheetEntry | null
};
export type QueryTimeSheetEntriesRequest = {
    searchPeriod: {
        start: string,      //datetime UTC
        end: string,        //datetime UTC
    },
    employeeGuids?: ApiGuid[],
}
export type QueryTimeSheetEntriesResponse = {
    events: TimeSheetEntry[]
}

export type TimeSheetEntryConflict = {
    guid: ApiGuid,
    start: string,
    end: string|null,    
    timezone: TimezoneString
}
type TimeSheetEntryConflictError = {
    code: "TimeSheetEntryConflictError",
    message: string,
    conflicts: TimeSheetEntryConflict[]
    isMoreConflicts: boolean
}
export function isTimeSheetEntryConflictError(err:unknown):err is TimeSheetEntryConflictError 
{
    if(err && (err as TimeSheetEntryConflictError)?.code === 'TimeSheetEntryConflictError')
        return true;
    return false;
}
export const MaxTimeSheetConflictResults = 3; //max results that are returned in the conflict errors - can tell us how to render error

//Activity Notes
export type ActivityNotesSearchQueryParams = {
    searchRange?: {
        start: string,
        end: string,
    },
    employeeGuid?: ApiGuid|null,
    jobGuid?: ApiGuid|null
    includeTextNotes?: boolean,
    includeFiles?: boolean,
    activityNoteGuids?: ApiGuid[],
}
export type ActivityNotesForClockEventRequestParams = {
    clockEventRootGuids: ApiGuid[]
}

export type ActivityNotesDeleteRequestParams = {
    activityNoteGuids: ApiGuid[]
}

export type ActivityNotesQueryReponse = {
    notes: ApiActivityNote[]
    limitExceeded?:boolean //indicator there were more, but limit hit - () the length of the notes == limit)
}
export type ActivityNotesDeleteRequest = {
    activityNoteGuids: ApiGuid[]
}
export type ActivityNotesDeleteReponse = {
    recordsDeleted: number
}

//make type for pure note AND file
export type ActivityNoteSendRequest = {
    clockEventRootGuid: ApiGuid,
    note: string,
    hasFile?: boolean,
}
export type ActivityNoteSendReponse = {
    note: ApiActivityNote
}


///activity view templates
//important: need to have defaults for fields that were never set...

export const ActivityViewColumnTypeValues = ["approve_button","signature_status","tpa_status","employee_id","employee_name","group",
    "gps_report_indicator","in_time","out_time",
    "job_code","job_id","job_name","job_other_info_1","job_other_info_2","job_other_info_3",
    "job_other_info_4","job_other_info_5","job_other_info_6",
    "service_item_code","service_item_id","service_item_name",
    "hours","hours_minutes","total_hours","total_hours_minutes","date",
    "facial_rec_status", "in_report", "out_report",
    "custom_field","custom_field_list_code","custom_field_list_name"] as const;

type ActivityViewColumnTypesTuple = typeof ActivityViewColumnTypeValues;
export type ActivityViewColumnTypes = ActivityViewColumnTypesTuple[number];

export type ActivityViewColumn = {
    type: ActivityViewColumnTypes,
    customFieldGuid?: ApiGuid, //if missing, not custom field - could use a better type here probably but a pain to do
    // width?: string, //if absent, use default - eventually can control, not yet
}
export type VcCodeExtension = {
    code: string
    enabled: boolean
}

export type ActivityViewTemplateTimeOfDayTypes = 'h:mm a'|'HH:mm';
export type ActivityViewTemplate = {
    guid: ApiGuid,
    name: string,
    isDefault: boolean,
    dateRange?: 'today'|'yesterday'|'thisWeek'|'lastWeek'|'thisMonth'|'lastMonth'|'thisPayPeriod'|'lastPayPeriod'|undefined, //null|undefined == not set/use default or ui range
    sortOrder?: ActivitySortOrder,
    columns: ActivityViewColumn[],
    filters?: {
        groupEmployeeFilter?: {
            employeeGuids: ApiGuid[],
            groupGuids: ApiGuid[],
        }
        jobFilter?: ApiGuid[],
        serviceItemFilter?: ApiGuid[],
    },
    timezone?: TimezoneString,
    timeOfDayFormat?:ActivityViewTemplateTimeOfDayTypes,
    autoRefreshInterval?: number, //in seconds
    paginationPerPageSetting?: number,
    deleteFilter?: 'none'|'showConflict'|'showDeleted',
    warningFilter?: ('timeguard'|'geofenceClock'|'geofenceWarn')[]
    approvalFilter?: 'approved'|'unapproved'|'none' //none is really undefined default
    
    extensions?: VcCodeExtension
    // shareInfo: {
    //     employeeGuids: ApiGuid[],
    //     groupGuids: ApiGuid[],
    //     adminOnly: boolean,
    //     managersOnly: boolean,
    // }
    createDate: string,
    updateDate: string,
}

export type ActivityViewTemplateForCreate = Omit<ActivityViewTemplate,'guid'|'createDate'|'updateDate'|'isDefault'>;
export type ActivityViewTemplateForUpdate = Omit<ActivityViewTemplate,'createDate'|'isDefault'>;
export type ActivityViewTemplateForDelete = Pick<ActivityViewTemplate,'guid'|'updateDate'>;

export function isActivityViewTemplateForUpdate(t:ActivityViewTemplateForCreate|ActivityViewTemplateForUpdate):t is ActivityViewTemplateForUpdate
{
    return (t as ActivityViewTemplateForUpdate).guid !== undefined;
}
export type QueryActivityViewSettingsParams = {
    viewTemplates?: boolean //default true
}
export type SetDefaultActivityViewTemplateParams = {
    templateGuid: ApiGuid
}

export type ActivityViewSettings = {
    defaultTemplateGuid: ApiGuid|null,
    viewTemplates: ActivityViewTemplate[]
}

export type SaveActivityViewTemplateParams = {
    setAsDefault: boolean,          //if true, this template will be made default, any other default will be cleared
    template: ActivityViewTemplateForCreate|ActivityViewTemplateForUpdate,
    replace?: boolean
    // shareInfo?: TemplateShareInfo[]  - maybe make this a separate thing?  Create THEN share... not part of the explicity thang?  But is apart of the read object? Omit'd from create/update?
}
export type CreateActivityViewTemplateResponse = {
    template: ActivityViewTemplate
}

export type DeleteActivityViewTemplateParams = {
    template: ActivityViewTemplateForDelete,
}
export type UpdateActivityViewTemplateParams = {
    template: ActivityViewTemplateForUpdate,
}
export type UpdateActivityViewTemplateResponse = {
    template: ActivityViewTemplate
}
