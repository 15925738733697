import { ApiGuid } from './ApiGuid';
import { GroupCustomSettings } from './Company';
import { EmployeeClockStatus, EmployeeLimited } from './Employee';
import { ThingStatus } from './Thing';


//cannot combine criteria currently
//no criteria - get all groups
export interface GroupQueryOptions
{
    //search criteria 
    searchText?: string,
    
    status?: GroupStatus|GroupStatus[] //coming soon
    //group guids to retrieve
    groupGuids?: ApiGuid[],

    // deprecated!  This happens automatically now.  managers only get their own groups always.
    //managingEmployeeGuid?: ApiGuid,   //get all groups managed by this employee 

    //pagination
    count?: number,
    offset?: number,

    includeListOfManagers?: boolean,
    includeListOfEmployees?: boolean,

    //sync id's for accounting integration
    includeIntegrationMapping?: boolean,
    // return only groups that have an integration mapping binding them to a class item.
    filterNonIntegrationMapped?: boolean,

    teamView?: boolean, //if true, requesting access for team view purposes
}

export type ClassGroupIntegrationMapping = {
    syncId:string,
    syncName:string,
    customData:any,
}

export interface GroupCreateListParams
{
    groupsList: GroupForCreate[],
}

export interface GroupUpdateListParams
{
    groupsList: GroupForUpdate[],
}
export type GroupCreateUpdateListParams =
{
    groupsList: GroupForCreateOrUpdate[],
}
export type GroupCreateUpdateListResponse = {
    groups: Group[],
}

export interface GroupDeleteListParams
{
    groupGuids:ApiGuid[],
}

export type GroupStatus = ThingStatus; //'active'|'inactive'|'deleted'; //coming soon

//the info a member of this group would see
export type BasicGroup = {
    is: 'group',
    guid: ApiGuid,
    name: string,
    status: GroupStatus, //coming soon - once on, some ts-expect-errors should trigger
    settings: {
        customSettings?: null | GroupCustomSettings
    }
}

export function getBasicGroupFromGroup(group:Group):BasicGroup 
{
    let { is, guid, name, status } = group;
    return {
        guid,
        name,
        status,
        is,
        settings: group.settings
    }
}
export interface Group extends BasicGroup
{

    description?: string|null, //fixed type to match output (db can be null, and doesn't seem to ever not be defined)
    employees?:EmployeeLimited[],
    managingEmployeeList?:ApiGuid[],
    classIntegrationMapping?:ClassGroupIntegrationMapping|null,
}

export type GroupForCreate = Partial<Omit<Group,'guid'>> & Required<Pick<Group,'name'>>
export type GroupForUpdate = Partial<Group> & Required<Pick<Group,"guid">>
export type GroupForCreateOrUpdate = GroupForCreate|GroupForUpdate;

export function isGroupForUpdate(group:Partial<Group>):group is GroupForUpdate
{
    return group.guid !== undefined
}

export type GroupForKioskActive = Pick<Group,"guid"|"name"|"description"|"managingEmployeeList"|"settings"> & { status: 'active'};
export type GroupForKioskInactiveDeleted = {
    guid: ApiGuid,
    status: 'inactive'|'deleted',
};
export type GroupForKiosk = GroupForKioskActive|GroupForKioskInactiveDeleted;

export type GroupForWorkerAppActive = GroupForKioskActive;
export type GroupForWorkerAppInactiveDeleted = GroupForKioskInactiveDeleted;
export type GroupForWorkerApp = GroupForWorkerAppActive|GroupForWorkerAppInactiveDeleted; 
export function isGroupForWorkerAppActive(g:GroupForWorkerApp):g is GroupForWorkerAppActive
{
    if(g.status === 'active')
        return true;
    return false;
}
export type GetAllGroupClockStatusResponse = {
    clockStatus: Record<ApiGuid, EmployeeClockStatus>
}