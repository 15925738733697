import React, { ReactNode, useCallback } from 'react';
import { PaginationResponse } from '../../../../../../vericlock_api/src/types';

export function PaginationWidget(props: {
    pagination: PaginationResponse,
    setPage: (page:number) => void,
    itemCountName?: { singular: string, plural: string },
    itemCountPrefex?: ReactNode,
    zeroIndexed?:boolean,    //true if incoming page numbers start at 0, otherwise 1
    stopPropagation?:boolean,
    extraClass?:string,
    disabled?:boolean,
})
{
    const { page:pageOrig, perPage, pageCount:passedInPageCount, totalRecords } = props.pagination;
    const { setPage, zeroIndexed=false, stopPropagation=false, extraClass, disabled } = props;
    const page = zeroIndexed ? pageOrig : pageOrig -1;
    const pageCount = passedInPageCount ?? Math.ceil(totalRecords/perPage);

    const MAX_SIZE_PAGINATION_OPTIONS = 11;
    const pageForward = useCallback( (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
    {
        if(disabled)
            return;
        if (stopPropagation) 
            e.stopPropagation();
        if(page < pageCount - 1)
            setPage(page+1);            
    },[page,setPage, pageCount, stopPropagation, disabled]);
    const pageBack = useCallback( (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
    {
        if(disabled)
            return;
        if (stopPropagation) 
            e.stopPropagation();
        if(page > 0)
            setPage(page-1)
    },[page,setPage,stopPropagation, disabled]);
    const pageClicked = useCallback( (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
    {
        if(disabled)
            return;
        if (stopPropagation) 
            e.stopPropagation();
        let pageAttr = e.currentTarget.attributes.getNamedItem('data-page');
        let pageStr = pageAttr?.value;
        if(pageStr === undefined) {
            console.warn('Page button undefined data-page attr!');
            return;
        }
        let nextPage = parseInt(pageStr);
        if(nextPage >= 0 && nextPage < pageCount) {
            setPage(nextPage)
        }
    },[pageCount, setPage, stopPropagation, disabled]);


    let indexes:(number|'...')[];
    if(pageCount <= MAX_SIZE_PAGINATION_OPTIONS)
        indexes = Array.from(Array(pageCount).keys());
    else {
        let indexesNeeded = { 
            "0": true, "1": true, "2": true, //first 3
            [pageCount-1]: true,    //last 3
            [pageCount-2]: true,
            [pageCount-3]: true,
            [page-2] : true,
            [page-1] : true,
            [page] : true,          //+/- 2 around cur page
            [page+1] : true,
            [page+2] : true
        }

        let indexesNeededSorted = Object.keys(indexesNeeded)
            .map(k => parseInt(k))
            .filter(k => k >= 0)
            .filter(k => k < pageCount)              //when page is on last page, page+1,2 exceeds
            .sort((a,b) => a - b);
        //filter out any that we less 0.. curPage - 2 == -2 when its 0
        let i=0;
        let lastVal = -1;
        indexes = [];
        while(i < indexesNeededSorted.length)
        {
            const thisVal = indexesNeededSorted[i];
            if(thisVal - lastVal > 1)  //jump > 1, an ellipsis is needed
            {
                lastVal = thisVal;
                indexes.push('...');   
                continue; 
            }
            indexes.push(thisVal);
            lastVal = thisVal;
            i++;
        }
    }
    
    if(indexes.length <= 1)
        return <ItemCountNameBar itemCountName={props.itemCountName} itemCountPrefex={props.itemCountPrefex} count={totalRecords} extraClass={extraClass}/> //no paging displayed if only 1 page!

    return <nav aria-label="Table navigation">
    <ul className={"pagination " + extraClass}>
        <ItemCountNameBarInternal itemCountName={props.itemCountName} itemCountPrefex={props.itemCountPrefex} count={totalRecords} />
      <li>
        <a aria-label="Previous" onClick={pageBack}>
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
        {indexes.map( (pageNum, idx) => {
            const activeClass = pageNum === page ? 'active' : '';
            return <li className={activeClass} key={pageNum + '_' + idx}><a data-page={pageNum} onClick={pageClicked}>{pageNum !== '...' ? pageNum+1 : pageNum}</a></li>
        })}

      <li>
        <a aria-label="Next" onClick={pageForward}>
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
}

export function ItemCountNameBar(props: {
    itemCountName?: { singular: string, plural: string },
    itemCountPrefex?: ReactNode,
    count: number,
    extraClass?:string,
})
{
    if(!props.itemCountName)
        return null;
        
    return <nav aria-label="Table navigation">
        <ul className={"pagination "+props.extraClass}>
            <ItemCountNameBarInternal itemCountName={props.itemCountName} itemCountPrefex={props.itemCountPrefex} count={props.count} />
        </ul>
    </nav>
}
function ItemCountNameBarInternal(props: {
    itemCountName?: { singular: string, plural: string },
    itemCountPrefex?: ReactNode,
    count: number
})
{
    if(!props.itemCountName)
        return null;
        
    return <>
    { props.itemCountPrefex &&
        <li><a>{props.itemCountPrefex}</a></li>
    }
    <li>
        <a>{props.count} {props.count == 1 ? props.itemCountName.singular : props.itemCountName.plural}</a>
    </li></>
        
}