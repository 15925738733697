import { ApiGuid } from './ApiGuid';
import { ThingStatus } from './Thing';


//query options are either or - blank will return ALL active service items
export type ServiceItemQueryStatusType = ThingStatus|ThingStatus[];
export interface ServiceItemQueryOptions
{
    //defaults to 'active' if ommitted
    status?: ServiceItemQueryStatusType,

    //find a specific guid
    guid?: ApiGuid,
    serviceItemGuids?:ApiGuid[], //retrieve a list of service items by guid

    //searchs the job NAME or CODE for this text
    // will enhance soon to search the combined job code AND name as well, and possibly the job description
    searchText?: string,

    teamView?: boolean, //if true, requesting access to all items for team view purposes
}

export interface ServiceItem
{
    guid: ApiGuid,
    serviceItemCode: number,
    name: string
    description: string,
    status: 'active'|'inactive'|'deleted',
    parentGuid: ApiGuid|null,
    createdDate: string,
    updateDate: string,
    otExempt: boolean,
    autoDeductionExempt: boolean,
    hourlyRate: string,         //$'s , decimal string
    hourlyRatePennies: number, //in pennies | integer
    otherInfo1: string,
    otherInfo2: string,
    otherInfo3: string,
    jobAssignment: { jobGuids: ApiGuid[] },
    employeeAssignment: { employeeGuids: ApiGuid[], groupGuids: ApiGuid[] },

    employeeAccessControl: 'none'|'allowList'|'denyList',
    jobAccessControl: 'none'|'allowList'|'denyList'
    adminCanAssign: boolean,    //if assignments are not all, can an admin/manager override the assignments and use the job for someone that doesn't otherwise have it assigned/access
    updateSyncId: number,   //incremented each time employee is updated
}

type ServiceItemExtraCreateUpdateParams = {
    parentCode?:number|null          //can optionally specify the parent's job code, instead of guid, during create - can work with job from higher in import list as it will be created by the time this job gets inserted    
    parentName?:string|null         //used by integrations

    // Used interally for accouting integrations:
    syncId?: string | undefined;
    syncName?: string | undefined;
    customData?: string 
}

export type ServiceItemAllOptional = Partial<ServiceItem> & ServiceItemExtraCreateUpdateParams;
export type ServiceItemForCreate = Omit<Partial<ServiceItem>,'guid'|'createdDate'|'updateSyncId'> & ServiceItemExtraCreateUpdateParams
export type ServiceItemForUpdate = Partial<ServiceItem> & Required<Pick<ServiceItem,"guid">> & ServiceItemExtraCreateUpdateParams
export type ServiceItemForCreateOrUpdate = ServiceItemForCreate|ServiceItemForUpdate;

export type ServiceItemForKioskActive = Pick<ServiceItem,'guid'|'serviceItemCode'|'name'|'description'|'parentGuid'|'createdDate'|'jobAssignment'|'employeeAssignment'|'employeeAccessControl'|'jobAccessControl'|'adminCanAssign'> & {status:'active'}
export type ServiceItemForKioskInactiveDeleted = {
    guid: ApiGuid,
    status: 'inactive'|'deleted',
};
export type ServiceItemForKiosk = ServiceItemForKioskActive | ServiceItemForKioskInactiveDeleted;
export function isServiceItemForKioskActive(serviceItem:ServiceItemForKiosk):serviceItem is ServiceItemForKioskActive
{
    return serviceItem.status === 'active';
}

export interface ServiceItemForWorkerAppActive extends ServiceItemForKioskActive {}
//export type ServiceItemForWorkerAppActive = ServiceItemForKioskActive;
export interface ServiceItemForWorkerAppInactiveDeleted extends ServiceItemForKioskInactiveDeleted {}
// export type ServiceItemForWorkerAppInactiveDeleted = ServiceItemForKioskInactiveDeleted;
export type ServiceItemForWorkerApp = ServiceItemForWorkerAppActive | ServiceItemForWorkerAppInactiveDeleted;
export function isServiceItemForWorkerAppActive(serviceItem:ServiceItemForWorkerApp):serviceItem is ServiceItemForWorkerAppActive
{
    return serviceItem.status === 'active';
}

export function isServiceItemForUpdate(si:Partial<ServiceItemForUpdate>):si is ServiceItemForUpdate
{
    return typeof(si.guid) !== "undefined";
}

export interface RequestServiceItemCreateUpdateListParams {
    serviceItems: ServiceItemForCreateOrUpdate[]
    clientType?: number
}
