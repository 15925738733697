import type { ApiGuid } from '../../../vericlock_api/src/types';

//row wrapper - to maintain the ORIGINAL rowIndex to pass to external users
export type SortRowWrapper<D> = {
    data: D,
    rowIndex: number
}
export type GetParentRowIndexFunction<D=unknown> = (row: D) => {
    rowIndex: number;
    data: D;
} | null;

//shape of an object that works with the 'parentGuid' exander type
export type ParentGuidExpander = { guid: ApiGuid, parentGuid: ApiGuid|null }
function isParentGuidExpander(thing:any):thing is ParentGuidExpander
{
    return(thing.guid !== undefined && thing.parentGuid !== undefined)
}
//typeguard that the type matches the { parentGuid, guid } interface
export function isParentGuidExpanderArray(thing:any):thing is ParentGuidExpander[]
{
    return(Array.isArray(thing) && thing.length > 0 && thing[0].guid !== undefined && thing[0].parentGuid !== undefined)
}

export function makeParentGuidExpanderFunctions<D extends ParentGuidExpander>(data:D[])
{
    //construct the caches to speed lookups
    //if a guid is in this map, it has children
    const dataItemHasChildrenMap:Record<ApiGuid,boolean> = {};
    data.forEach(j => {
        if(isParentGuidExpander(j) && j.parentGuid)
            dataItemHasChildrenMap[j.parentGuid] = true; //could inject j into a list here to get children list
    });
    
    const dataItemGuidToItemAndRowIndex:Record<ApiGuid,SortRowWrapper<D>> = data.reduce((prev,cur,rowIndex) => {
        prev[cur.guid] = {
            rowIndex,
            data: cur
        }
        return prev;
    },{} as Record<ApiGuid,SortRowWrapper<D>>);

    const ancestorMap = generateAncestorMap(data);

    const doesRowHaveChildren = (item:D) => {
        return dataItemHasChildrenMap[item.guid] === true        
    }

    const getParentRowIndex:GetParentRowIndexFunction<D> =  (item:D) => {
        if(item.parentGuid && dataItemGuidToItemAndRowIndex[item.parentGuid] !== undefined)
        {
            return dataItemGuidToItemAndRowIndex[item.parentGuid];
        }
        return null; //null => top level/no parent in this list
    }

    const isDescendent = (child:D, ancestor:D) => {
        const ancestors = ancestorMap[child.guid];
        if(!ancestors || ancestors.list.length === 1)
            return false; //has no ancestors

        return ancestors.map[ancestor.guid];
    }
    const getOldestAncestor = (wrap:SortRowWrapper<D>) => {
        let jwrap = wrap;
        while(jwrap.data.parentGuid && dataItemGuidToItemAndRowIndex[jwrap.data.parentGuid])
        {
            jwrap = dataItemGuidToItemAndRowIndex[jwrap.data.parentGuid];
        }
        return jwrap;
    }

    const getOldestSiblingAncestorsOrOldestAncestor = (a:SortRowWrapper<D>,b:SortRowWrapper<D>) => {
        //walk up the a ancestor tree and count how many levels it takes to get an ancestor of b
        //walk up the b ancestor tree and count how many levels it takes to get an ancestor of a
        //return the pair at 1 level below the common ancestor (siblings)
        if(a.data.guid === b.data.guid) //this will fail down the road hard, so lets just catch it here
            return { a, b }; //same item, just return them as is

        if(a.data.parentGuid === b.data.parentGuid)
            return { a, b }; //siblings

        const aAncestorMap = ancestorMap[a.data.guid];
        const bAncestorMap = ancestorMap[b.data.guid];

        //if these two are related, then the last entries in the list will be the same
        if(aAncestorMap.list[aAncestorMap.list.length-1] !== bAncestorMap.list[bAncestorMap.list.length-1])
        {
            return { 
                a: dataItemGuidToItemAndRowIndex[aAncestorMap.list[aAncestorMap.list.length-1]], 
                b: dataItemGuidToItemAndRowIndex[bAncestorMap.list[bAncestorMap.list.length-1]] 
            }; //not related - so return oldest ancestor period
        }
        //now we find the first siblings by walking down the list until they diverge
        let i=1;
        while(i < aAncestorMap.list.length && i < bAncestorMap.list.length) //prevent overflow
        {
            let candidateSiblingA = aAncestorMap.list[aAncestorMap.list.length- 1 -i];
            let candidateSiblingB = bAncestorMap.list[bAncestorMap.list.length- 1 -i];
            if(candidateSiblingA !== candidateSiblingB) 
            {
                //found the divergence, they are the siblings (parents will be previous entry)
                return { 
                    a: dataItemGuidToItemAndRowIndex[candidateSiblingA], 
                    b: dataItemGuidToItemAndRowIndex[candidateSiblingB] 
                }; //not related
            }
            i++;
        }
        //falling through should not happen
        console.warn('fell through sibling ancestor check - they are ancestors, but no sibling pair found', aAncestorMap, bAncestorMap);
        //returning the oldest ancestor again
        return { 
            a: dataItemGuidToItemAndRowIndex[aAncestorMap.list[aAncestorMap.list.length-1]], 
            b: dataItemGuidToItemAndRowIndex[bAncestorMap.list[bAncestorMap.list.length-1]] 
        };
    }
                
    const areSiblings = (a:SortRowWrapper<D>,b:SortRowWrapper<D>) => {
        //table is not parentGuid aware - though we could potentially support this specific 
        // tree structure (parent identifiar) and pass in a prop to the table
        //   parentIdentifier , keyof T, or (a:T) => string|null, and it can do it internally 
        // cleanup all this tree structure callbacks and simplify
        return a.data.parentGuid===b.data.parentGuid;
    }


    return {
        doesRowHaveChildren,
        getParentRowIndex,
        isDescendent,
        getOldestAncestor,
        areSiblings,
        getOldestSiblingAncestorsOrOldestAncestor
    }
}

type AncestorMap = {
    map: Record<ApiGuid, true>,
    list: ApiGuid[],
    // [key: string]: never
}
type AncestorMaps = Record<ApiGuid, AncestorMap>
function addAncestors<D extends ParentGuidExpander>(itemMap:Record<ApiGuid, D>, ancestorMap:AncestorMaps, item:D)
{
    //init the ancestor map for this item to empty 
    const aMap = ancestorMap[item.guid] = { map: {
        [item.guid]: true //add self to the list
    }, list: [
        item.guid //add self to list
    ] } as AncestorMap;// //local copy of just this job's ancestors //note had double = 
    let tmpItem = item; 
    while(tmpItem.parentGuid !== null)
    {
        const parent = itemMap[tmpItem.parentGuid];
        if(!parent) //could have an ancestor not in this list (inactive job with active parent)
            break; //done
        if(aMap.map[parent.guid])
            throw new Error('circular reference detected in vtable-expander-item/parent hierarchy, guid:['+item.guid+'] - parentGuid seen 2nd time:['+parent.guid+']');
        aMap.map[parent.guid] = true;
        aMap.list.push(parent.guid);
        tmpItem = parent; //move on to the parent        
    }
}
function generateAncestorMap<D extends ParentGuidExpander>(data:D[])
{
    //helper lookup map for quick lookup
    const itemMap:Record<ApiGuid, D> = data.reduce((prev,cur) => {
        prev[cur.guid] = cur;
        return prev;
    }, {} as Record<ApiGuid, D>);

    const ancestors:AncestorMaps = {};
    for(let i=0; i < data.length; i++)
    {
        const item = data[i];
        // if(item.parentGuid !== null) //parent guid, has ancestors        
        // {
            addAncestors(itemMap, ancestors, item)
        // }
    }
    return ancestors;
}