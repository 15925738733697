import { CustomFieldType } from './CustomField';
import { ApiGuid } from './ApiGuid';
import { PayrollInfo } from './Payroll';
import { DeviceDetails } from './Devices';
// interface CustomField
// {
//     guid: ApiGuid,
//     name: string,
//     clockInEnabled: boolean,
//     clockOutEnabled: boolean,
//     phoneEnabled: boolean,
//     smsEnabled: boolean,
//     webEnabled: boolean,
//     deleted: boolean,
//     globalOn: boolean,
//     jobs: Array<ApiGuid|null>  //null was the no-job type
// }

export const ClockEventFlags = {
    //Clockonce:                      0x00000001,   // Deprecated
    Approval:                       0x00000002,
    GroupClock:                     0x00000004,
    AccessControlCallerIdClockIn:   0x00000008,
    AccessControlCallerIdClockOut:  0x00000010,
    JobRulesAssignmentMismatch:     0x00000020,
    OfflineClock:                   0x00000040,
    OfflineClockConflict:           0x00000080,
    NegativeTime:                   0x00000100,
    FutureInTime:                   0x00000200,
    FutureOutTime:                  0x00000400,
    InToPreviousOutTime:            0x00000800,     //flag to indicate event's out time was set to previous event's in time, to avoid lost time on short in/out
    AccessControlIPAddressClockIn:  0x00001000,
    AccessControlIPAddressClockOut: 0x00002000,
    GeofenceClockIn:                0x00004000,     // Geofence clocked user in - Currently not used, here to futureproof
    GeofenceClockOut:               0x00008000,     // Geofence clocked user out
    GeofenceWarning:                0x00010000,     // Geofence was violated during shift
    ActivityNote:                   0x00020000,     // Clock event has/had an activity note
    ActivityNoteAttachment:         0x00040000,     // Clock event has/had an activity note with attachment
    MidnightSplitShiftFirst:        0x00080000,     //if a clock event has this flag, it was split by the midnight splitter and this is the original shift edited from In time to Midnight <- signals the UI to fetch ALL gps points related to this event 
    MidnightSplitShiftOther:        0x00100000,     //if a clock event has this flag, it was split by the midnight splitter and this is a created shift for midnight -> end (or midnight -> midnight if a middle shift aswell)
    FacialRecognitionInOn:          0x00200000,     // Facial recognition was enabled for this user at the time of clock in/out
    FacialRecognitionInSuccess:     0x00400000,     // Facial recognition matched the user at
    FacialRecognitionOutSuccess:    0x00800000,     // Facial recognition matched the user at
    FacialRecognitionInProblem:     0x01000000,     // Facial recognition had a problem (e.g. match failed/ no face / no picture)    
    FacialRecognitionOutProblem:    0x02000000,     // Facial recognition had a problem (e.g. match failed/ no face / no picture)    
    FacialRecognitionOutOn:         0x04000000,     // Facial recognition was enabled for this user at the time of clock in/out
} 

export const GpsFlags = {
    In:                 0x00000001,
    Out:                0x00000002, //GpsFlags.Out ^ 
    InError:            0x00000004,
    OutError:           0x00000008,
    InDisallowed:       0x00000010,
    OutDisallowed:      0x00000020,
    InNotSupported:     0x00000040,
    OutNotSupported:    0x00000080,
    InTimeout:          0x00000100,
    OutTimeout:         0x00000200,
    // Deprecated
    // Continuous:         0x00000400,
    InPending:          0x00000800,
    OutPending:         0x00001000,
    Ping:               0x00002000,
    PingPending:        0x00004000
};

export interface TimeBreakdown
{
    _all: number,
    // [key: string]:number
    [key: string]:number //for debug purposes
}
export interface CustomFieldData
{
    guid: ApiGuid,
    name: string,
    type: CustomFieldType
}

export type CustomFieldDataGeneric = CustomFieldDataNumber | CustomFieldDataString | CustomFieldDataList;

export type CustomFieldDataList = CustomFieldData &
{
    type: CustomFieldType.List,
    // value: string,
    listItemValue: number,
    listItemGuid: ApiGuid,
    listItemName: string
}
export type CustomFieldDataString = CustomFieldData &
{
    type: CustomFieldType.String,
    value: string
}
export type CustomFieldDataNumber = CustomFieldData &
{
    type: CustomFieldType.Number,
    value: number
}

export function isCustomFieldDataList(thing:CustomFieldData):thing is CustomFieldDataList
{
    if(typeof((thing as CustomFieldDataList).listItemValue) != 'undefined')
        return true;

    return false;
}
export function isCustomFieldDataString(thing:CustomFieldData):thing is CustomFieldDataString
{
    return thing.type === CustomFieldType.String;
}
export function isCustomFieldDataNumber(thing:CustomFieldData):thing is CustomFieldDataNumber
{
    return thing.type === CustomFieldType.Number;
}
export type ClockReport = ClockReportText | ClockReportAudio;
export type ClockReportText = {
    type: 'text'|'edit'
    value: string
};
export type ClockReportAudio = {
    type: 'audio'
    url: string
}

export enum DevicePlatformType
{
    IOS = 10,
    WindowsPhone = 30,
    Android = 40,
    Blackberry = 80,
}

type GeotaggingError = {
    error:string,
    message:string
};
export type ClockEventMethod = "phone"|"web"|"mobileWeb"|"api"|"edit"|"sms"|"manualEntry"|"machine"|"kiosk";
type ClockDeviceInfo = {
    deviceType: 'app',
    deviceGuid?: ApiGuid,
    // deviceName: string
} |
{
    deviceType: 'kiosk',
    deviceGuid?: ApiGuid,
    // deviceName: string
}
export type ClockDetails =
{
    method: ClockEventMethod,
    geoTagging: {
        latitude: number,
        longitude: number,
        accuracy: number,
    } | GeotaggingError | null
    report?: ClockReport
    customFields?: Array<CustomFieldDataGeneric> //type is the data
    ipAddress?: string,
    userAgent?: string,
    callerID?: string,
    calledNumber?: string,
    callUID?: string,
    callLength?: number,
    rawSMS?: string,
    appVersion?: string,
    appPlatform?: DevicePlatformType,
    device?: string,
    osVersion?: string,
    extraDeviceInfo?: ClockDeviceInfo
}
export function isGeotaggingError(thing:ClockDetails['geoTagging']):thing is GeotaggingError
{
    if(thing)
    {
        if(typeof((thing as GeotaggingError).error) != 'undefined')
            return true;
    }
    return false;
}
export type ClockDetailsForActivity = ClockDetails &
{
    groupManagerGuid?: ApiGuid,
    deviceDetails?:DeviceDetails,
}

export interface OvertimeEntry
{
    displayName: string,
    minutes: number,
    timeTypeId: number, //the time type Id 
    //wage?
    //payrollitem?
}

export interface ClockEventOvertimeBreakdown
{
    regularMinutes: number,
    ot1Minutes: number,
    ot2Minutes: number,
    customerOtTypes?:OvertimeEntry[]
    payrollItemByTimeType?:{
        [key:number]: Pick<PayrollInfo,'guid'|'name'>,
    }|null //null => mapped to none
}

export interface OriginalTime
{
    durationBeforeAdjustments: number,
    durationMinutes: number
}

export enum GpsInfoType {
    Start = 0,
    Stop = 1,
    Ping = 2,
    Locate = 3,
}

// Adding GPS Data definition here so that when the API supports reading position data
// we won't have two competing definitions.
export const enum GpsDataStatus 
{
    Final = 0,
    Pending = 1,
    Error = 2,
    NotFound = 3,
    // 4 - old location method related error code
    CarrierUnavailable = 5,
    AccountRemoved = 6,
    DeviceOnline = 7,
    Disabled = 8,
    PermissionDenied = 9,
}
export interface GpsData
{
    type: GpsInfoType,
    status: GpsDataStatus,
    latitude: number,
    longitude: number,
    altitude: number,
    accuracy: number,
    gpsTime: string
}

export type GeofenceMap = Record<ApiGuid, {
    identifier: string,
    latitude: number,
    longitude: number,
    radius: number,
}>


export interface ClockEvent 
{
    guid: ApiGuid | null //null if creating a new one?  Or should we use 000's ?
    jobGuid: ApiGuid | null,
    jobName: string | null,
    jobCode: number | null,
    serviceItemGuid: ApiGuid | null,
    serviceItemCode: number | null,
    serviceItemName: string | null,
    employeeGuid: ApiGuid,
    flags: number,
    start: string,      //on the API its a string date 
    end: string | null,
    duration: number,
    approved?: boolean,
    inDetails: ClockDetails,
    outDetails: ClockDetails | null,
    overtime?: ClockEventOvertimeBreakdown,
    originalTime: OriginalTime,
    cost?: number, //if cost was requested in the query
    // costDetails?: CostDetails,
    missingRatesByPayrollItem?: string[], //array of payroll item names that are missing rates for the employee
    missingPayrollItemsByTimeType?: string[], //array of time type names that are missing payroll item mappings for the employee
    timeGuarded?:boolean,
    updateDate: string, // Last time this record was updated.  Comes form edit_time in Db.
}
// type CostDetails = {
//     byTimeType: Record<number, {
//         cost: number,
//         rate: number|null,
//         payrollItem: 
//     }>
// }
export type SummarySlots = 'daily'|'weekly'|'payPeriod';
export type TimeSummary = {
    punchMinutes: number,  //unrounded in/out times without adjustments
    totalMinutes: number,  //rounded + adjusted 
    breakdown: TimeBreakdown,
    timeguardCount: number,
}

export type TimeSummaryAllSlots = Record<SummarySlots,TimeSummary>;
export type TimeSummaries = {

    //employeeGuid => summaries
    summaries:Record<ApiGuid,TimeSummaryAllSlots>
}

export const enum ApprovalFilterType 
{
    All='all',
    Approved='approved',
    Unapproved='unapproved'
}
