import { UseQueryOptions, UseQueryResult } from "react-query";
import { isApiValidationError } from "../../../vericlock_api/src/validation/ApiValidationErrorResponse";

//passthrough to useQuery - allows us to add as needed, and think things through, maybe create a wrapper and apply defaults
export type ReactQueryOptions = {
    enabled?:boolean
}

export type OurUseQueryOptions<D> = Omit<UseQueryOptions<D>,'queryKey' | 'queryFn'>;

//CAREFUL with changes - this is used in a lot of places
export const ReactQueryCommonUseQueryOptions:OurUseQueryOptions<any> = {
    staleTime: Infinity, //never refetch except on invalidation
    refetchOnWindowFocus: false, //freshens data after leavin the window (less chance of stale data)

    /******* BEWARRRRRRREEEEE ********/ 
    // This is a tri-state var, true means it only refetches on mount WHEN STALE.  "Always" 
    refetchOnMount: true, //refetch if stale - see infinity staleTime
    refetchOnReconnect: false, //unsure
}


export enum VQueryKey {
    CoreData="CoreData",
    JobBudgetInfoProgress="JobBudgetInfoProgress",
    ThirdPartyContacts="ThirdPartyContacts",
    JobList="JobList",
    JobRules="JobRules",
    EmployeeList="EmployeeList",
    EmployeeDeleteWarnings="EmployeeDeleteWarnings",
    GroupList="GroupList",
    PayrollItems="PayrollItemList",
    PayrollItemRules="PayrollItemRules",
    ServiceItems="ServiceItems",
    ServiceItemRules="ServiceItemRules",
    Company="Company",
    CustomReports="CustomReports",
    TimeTypes="TimeTypes",
    EmployeeIntegrationMaps="EmployeeIntegrationMaps",
    JobIntegrationMaps="JobIntegrationMaps",
    ServiceItemIntegrationMaps="ServiceItemIntegrationMaps",
    PayrollItemIntegrationMaps="PayrollItemIntegrationMaps",
    DashboardData="DashboardData",
    PtoAccrualAssignments="PtoAccrualAssignments",
    PtoRecords="PtoRecords",
    PtoRules="PtoRules",
    PtoTypes="PtoTypes",
    PtoBalances="PtoBalances",
    SignatureRecords="SignatureRecords",
    DashboardEmployeeLocations="DashboardEmployeeLocations",

    ScheduledShifts="ScheduledShifts",
    ScheduledShiftConflicts="ScheduledShiftConflicts",
    ScheduledShiftSingleQuery="ScheduledShiftSingleQuery",
    SchedulingSettings="SchedulingSettings",
    SchedulingCustomColourPalette="SchedulingCustomColourPalette", //could this be more generic? 'CustomColourPalette' with other cache key == palette key
    SchedulingTemplates="SchedulingTemplates",

    AuditTrail="AuditTrail",
    CustomFieldList="CustomFieldList",
    ApiAccountSettings="ApiAccountSettings",
    SsoSamlSettings="SsoSamlSettings",
    SsoGoogleSettings="SsoGoogleSettings",
    LoginAlertsSettings="LoginAlertsSettings",
    MiscServerInfo="MiscServerInfo",
    GeofenceList="GeofenceList",
    UserClockState="UserClockState",        //prob will not use
    EmployeeClockState="EmployeeClockState",
    KioskSettings="KioskSettings",
    ServiceItemRateRules="ServiceItemRateRules",
    PayrollInfoEmployees="PayrollInfoEmployees",
    TimeEntryMinimumRules="TimeEntryMinimumRules",
    Comments="Comments",
    TimeSheetEntry="TimeSheetEntry",
    TimeSheetEntries="TimeSheetEntries",
    ActivityViewSettings="ActivityViewSettings",
    QueryDeviceTelemetry="QueryDeviceTelemetry",
    AccessPhoneNumbers="AccessPhoneNumbers",
    RoundingBlockingRuleAssignments="RoundingBlockingRuleAssignments",
    CompanyFiles="CompanyFiles",
    ActivityNotesForClockEvent="ActivityNotesForClockEvent",
    DeviceDetails="DeviceDetails",
    PushNotificationRoster="PushNotificationRoster",
    TermsOfService="TermsOfService",
    // PersonalSettings="PersonalSettings",
    EmailPreferences="EmailPreferences",
  }
  
export enum VSharedQueryKey {
    BadgePrintContexts="BadgePrintContexts",
}
function isQueryErrorMessage(queryError:any):queryError is { message: string }
{
    if(queryError && typeof(queryError) === 'object' && typeof(queryError['message']) === 'string')
        return true;
    return false;
}
export function ReactQueryExtractErrorString(queryKey:string, query:UseQueryResult): {
    errorString: string|null,
    errorObj:unknown|null,
    isError:boolean         //pass through from query.isError
}
{
    let errorString = null;
    let errorObj = null;
    const logger = (errOrMsg:unknown) => {
        console.trace(`useQuery(${queryKey}) err:`);
        console.warn(errOrMsg);
    }
    if(query.isError)
    {
        if(query.error === null || query.error === undefined)
        {
            logger(`isError is true, but error is null|undefined - should never happen - is probably not an error - ignoring`);
        }
        else if(query.error instanceof Error)
        {
            console.log(query.error);
            errorString = query.error.message;
        }
        else if(isApiValidationError(query.error))
        {
            errorString = 'There were parameter validation errors'; //this is not helpful, but this type of error should typically be picked up elsewhere and rendered correctly
            logger(query.error);
        }
        else if(isQueryErrorMessage(query.error))
        {
            errorString = query.error['message'];
            logger(query.error);
        }
        else //everything else should have a functional toString capability
        {
            if(hasToStringCapability(query.error))
                errorString = query.error.toString();
            else 
                errorString = 'Unusual Error, please refresh and try again'; //really should never happen
            logger(errorString);            
        }
    }

    return {
        isError: query.isError,
        errorString,
        errorObj
    }
}
interface ToStringHaver {
    toString: () => string
}
function hasToStringCapability(thing:any):thing is ToStringHaver
{
    if(thing === null || thing === undefined)
        return false;
    
    return true; //everything has toString except null/undefined
}

//matches the isLoading/loadError of various hook responses
export type CommonQueryResponseParams = { isLoading: boolean, loadError: string|null, isFetched: boolean }
export function combineIsLoading( useThings: Pick<CommonQueryResponseParams,'isLoading'>[] ):boolean
{
    const isLoading = useThings.reduce( (prev, cur) => prev || cur?.isLoading, false); 
    return isLoading;
}
export function combineIsFetched( useThings: Pick<CommonQueryResponseParams,'isFetched'>[] ):boolean
{
    const isFetched = useThings.reduce( (prev, cur) => prev || cur.isFetched, false); 
    return isFetched;
}

export function combineLoadErrors( useThings: Pick<CommonQueryResponseParams,'loadError'>[] ):null|string[] 
{
    let errors = useThings.filter(t => (t?.loadError ?? null) !== null).map(t => t?.loadError ? t.loadError : ""); //conditional
    if(errors.length === 0)
        return null;
    return errors;
}