import { ApiGuid } from "./ApiGuid";
import { FileRef } from "./CompanyFile";

export type IdBadgeShape = 'circle' | 'square' | 'rotated-square' | 'solid';
export type IdBadgeImageShape = 'rounded' | 'circle' | 'square';

export interface WorkerIdBadgeSettingsAppInfo {
    title?: string,
    menuText?: string, //default: ID Badge
    menuPosition?: number, //position in the app menu
}

export type WorkerIdBadgeSettingsNameFormat =
    "firstLast" |
    "lastFirst" |
    "firstMiddleLast" |
    "firstMiddleInitialLast" |
    "lastFirstMiddle" |
    "lastFirstMiddleInitial";

export const workerIdBadgeSettingsNameFormatLookup = [
    "firstLast",
    "lastFirst",
    "firstMiddleLast",
    "firstMiddleInitialLast",
    "lastFirstMiddle",
    "lastFirstMiddleInitial"
];

export const workerIdBadgeSettingsNameFormatOptions = [
    { value: "firstLast", label: "First Last" },
    { value: "lastFirst", label: "Last, First" },
    { value: "firstMiddleLast", label: "First Middle Last" },
    { value: "firstMiddleInitialLast", label: "First MiddleInitial Last" },
    { value: "lastFirstMiddle", label: "Last, First Middle" },
    { value: "lastFirstMiddleInitial", label: "Last, First MiddleInitial" }
];

export const workerIdBadgeSettingsBackgroundStyleOptions = [
	{ value: "circle", label: "Circle" },
	{ value: "square", label: "Square" },
	{ value: "rotated-square", label: "Rotated Square" },
	{ value: "solid", label: "Solid" }
];

export const workerIdBadgeSettingsBorderOptions = [
	{ value: "circle", label: "Circle" },
	{ value: "rounded", label: "Rounded" },
	{ value: "square", label: "Square" }
];

export const workerIdBadgeSettingsImageBorderStyleLookup = {
	circle: '50%',
	rounded: '10%',
	square: '0%'
};

export type WorkerIdBadgeSettingsIdNoun =
    'employee' |
    'worker' |
    'id';

export const workerIdBadgeSettingsIdNounLookup = [
    'employee',
    'worker',
    'id'
];

export const workerIdBadgeSettingsIdNounOptions =[
    {value: 'employee', label: 'Employee' },
    {value: 'worker', label: 'Worker' },
    {value: 'id', label: 'ID' }
];

export interface WorkerIdBadgeSettingsStyle {
    topColor: string;
    bottomColor: string;
    badgeStyle: IdBadgeShape;
    textColor: string;
    imageBorderStyle: IdBadgeImageShape;
    nameFormat: WorkerIdBadgeSettingsNameFormat;
    showDateTime: boolean;
    showJobTitle: boolean;
    showIdText: boolean;
    idNoun: WorkerIdBadgeSettingsIdNoun;
    logoFileRef?: FileRef;
    logoWidth: number;
}

export interface WorkerIdBadgeSettings {       //default missing - indicating not enabled inherently
    enabled?: boolean, //on for everyone  - can be overidden per group / employee
    // qrCodeValidation?: boolean, //on by default/for everyone? maybe enum, with different options?
    appInfo?: WorkerIdBadgeSettingsAppInfo,
    style: WorkerIdBadgeSettingsStyle,
}

export interface WorkerIdBadgeTemplate extends Partial<WorkerIdBadgeSettingsStyle> {
    name: string;
}

export type BadgeDisplayContext = {
    workerIdBadgeSettings: WorkerIdBadgeSettings
    employeeIdPhoto?: FileRef
    companyLogo?: FileRef
    qrCodePayload?: string           //full validation payload
    companyName: string,
    firstName: string;
    middleName: string;
    lastName: string;
    phoneID: string;
    jobTitle: string;
}

export type QrCodeInfo = {
    url: string,
    payload: {
        qrCodeVersion: number,
        counter: number,
        employeeGuid: ApiGuid,
        ts: number,
        otp: string,    //text
        blob: string,   //base64
    }
}
export type QrCodePayloadArray = [
    QrCodeInfo['payload']['qrCodeVersion'],
    QrCodeInfo['payload']['counter'],
    QrCodeInfo['payload']['employeeGuid'],
    QrCodeInfo['payload']['ts'],
    QrCodeInfo['payload']['otp'],
    QrCodeInfo['payload']['blob'],
];


//https://app.vericlock.com/v?b=<QrCodePayloadArray - v1 = scrambled>
export type QrCodeUrlParams = {
    b: string //encrypted QrCodeInfo payload
}
export type GetWorkerIdBadgeContextResponse = {
    context: BadgeDisplayContext
}

export type GetWorkerIdBadgeContextRequest = {
    count?: string //get params are strings
}
export type GetBulkWorkerIdBadgeContextsForPrintingRequest = {
    employeeGuids?: ApiGuid[]
}
type BadgePrintContext = {
    qrCodePayload?: string,
}
export type GetBulkWorkerIdBadgeContextsForPrintingResponse = {
    contexts: Record<ApiGuid, BadgePrintContext>
}
export type ValidateWorkerIdBadgeRequest = {
    payload: string
}
export type ValidateWorkerIdBadgeResponse = {
    valid: boolean,
    context?: Omit<BadgeDisplayContext,'qrCodePayload'>,
    // reason?: 'inactiveWorker'|'workerIdBagdesNotEnabled'|'badgeExpired'|'corruptPayload'|'unknown',
    reason?: 'other'|'badgeExpired',
}




type BadgeLogCommonInfo = {
    userAgent?: string,
    xForwardedFor?: string,
    //if no x-forwarded for, store the ip of the connecting user
    clientIp?: string,
    qrCodeUrl: string,
}

export type BadgeLogGenerateInfo = BadgeLogCommonInfo & {
    tryNumber?: GetWorkerIdBadgeContextRequest['count']
    deviceGuid?: ApiGuid,
    accountGuid?: ApiGuid,
    platformType?:string,
    osVersion?:string,
    appVersion?:string,
    deviceModel?:string,
}
export type BadgeLogValidateInfo = BadgeLogCommonInfo & {
    result: 'valid'|'employeeNotActive'|'tamperedWith'|'expired',
    code?: number
}

export type WorkIdBadgeLogType = 'generate'|'validate';

export type WorkerIdBadgeLogEntry = {
    guid: ApiGuid,
    employeeGuid: ApiGuid,
    operationDate: string,
    type: WorkIdBadgeLogType
} & (
    {
        type: 'generate',
        dataBlob: BadgeLogGenerateInfo
    } | {
        type: 'validate',
        dataBlob: BadgeLogValidateInfo
    }
);

export type QueryWorkerIdBadgeLogsRequest = {
    searchRange: {
        start: string, //iso datetime UTC
        end: string    //iso datetime UTC
    },
    employeeFilter?: {
        allActiveEmployees?: boolean,
        employeeGuids?: ApiGuid[]
        groupGuids?: ApiGuid[]
    }
}
export type QueryWorkerIdBadgeLogsResponse = {
    logs: WorkerIdBadgeLogEntry[]
}