import { ApiGuid } from "./ApiGuid";

export enum AppTelemetryEventType 
{
    LocationAuthorizationChanged="lsChanged",
    HighPrecisionModeChanged="pmChanged",
    AppTerminated='appTerminated'
    // AppOpened="AppOpened",
    // AppClosed="AppClosed",
}
export const AppTelemetryEventTypeList = Object.keys(AppTelemetryEventType).map(k => AppTelemetryEventType[k as keyof typeof AppTelemetryEventType]);
type Base<T> = {
    eventDate: string, //datetime iso string
    type: T
}


//WARNING - if a type is changed after it is in the wild, it must be compatible with the old type
// consider making a new type, or adding additional versioning info to the request
type AppTelemetryEventHighPrecisionModeChangeEvent = Base<AppTelemetryEventType.HighPrecisionModeChanged> & {
    rootGuid: ApiGuid,
    before: 'on'|'off',
    after: 'on'|'off',
}
export function isAppTelemetryEventHighPrecisionModeChangeEvent(e:AppTelemetryEvent):e is AppTelemetryEventHighPrecisionModeChangeEvent
{
    return e.type === AppTelemetryEventType.HighPrecisionModeChanged;
}
export type AppTelemetryLocationAuthorizationChangeEvent = Base<AppTelemetryEventType.LocationAuthorizationChanged> & {
    rootGuid: ApiGuid,
    before: 'always'|'whileUsing'|'never',
    after: 'always'|'whileUsing'|'never',
}
export function isAppTelemetryLocationAuthorizationChangeEvent(e:AppTelemetryEvent):e is AppTelemetryLocationAuthorizationChangeEvent
{
    return e.type === AppTelemetryEventType.LocationAuthorizationChanged;
}
type AppTelemetryAppTerminatedEvent = Base<AppTelemetryEventType.AppTerminated> & {
    rootGuid: ApiGuid,
}
export function isAppTelemetryAppTerminatedEvent(e:AppTelemetryEvent):e is AppTelemetryAppTerminatedEvent
{
    return e.type === AppTelemetryEventType.AppTerminated;
}
export type AppTelemetryEvent = AppTelemetryAppTerminatedEvent|AppTelemetryLocationAuthorizationChangeEvent|AppTelemetryEventHighPrecisionModeChangeEvent;

// } : T extends AppTelemetryEventType.AppOpened ? {
//     type: T,
//     temp: string
// } : T extends AppTelemetryEventType.AppClosed ? {
//     type: T,
//     bort: string


export type LogAppTelemetryRequest = {
    events: AppTelemetryEvent[]
}

export type LogAppTelemetryResponse = Record<string, never>; //empty for now
export type QueryAppTelemetryRequest = {
    employeeGuid: ApiGuid,
    rootGuid: ApiGuid
}
export type QueryAppTelemetryResponse = {
    events: AppTelemetryEvent[];
}