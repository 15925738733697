import { CsvCell } from '../../../vericlock_api/src/types/Csv';

function hasDownloadSupport()                    
{
    var a = document.createElement('a');
    if (typeof a.download != "undefined") {
        return(true); 
    }

    return(false);
}
var escapePrefix = "'"; //prefix for csv export 
var prefixRequiredChars = ['=','+','@'];

//could expand this to allow formulas to continue to exist should the user want it?
function csvEscape(x:CsvCell)
{
    if(typeof(x) === "string" && x && x.length >= 1)
    {
        var firstChar = x.charAt(0);
        if(prefixRequiredChars.indexOf(firstChar) !== -1)
        {   //prefix required char found - put the prefix in place!
            x = escapePrefix + x;
        }
        return( '"' + x.replace(/"/g,'""') + '"');
    }   
    return(x); //if not string, no need
}
function tsvEscape(x:CsvCell)
{
    if(typeof(x) === "string" && x.indexOf("\t") >= 0)
    {
        console.warn('tab detected in cell - removing'); //no spec on how to escape 
        return x.replace(/\t/g,'   '); ///replace with 4 spaces
    }
    return x;
}
export function exportDataToTSV(tsvFilename:string, arrayDataToExport:CsvCell[][])
{
    return doActualExport(tsvFilename, arrayDataToExport, 'tsv');
}
export function exportDataToCSV(csvFilename:string, arrayDataToExport:CsvCell[][])
{
    return doActualExport(csvFilename, arrayDataToExport, 'csv');
}

//csv - comma separated
//tsv - tab separated
function doActualExport(filename:string, arrayDataToExport:CsvCell[][], fileType:'csv'|'tsv')
{
    var csvData = [];
    var csvRows = [];

    let escaper;
    let separator;
    let mimeType;
    let extension;
    switch(fileType)
    {
        case 'csv':
            escaper = csvEscape;
            separator = ',';
            mimeType = 'text/csv;charset=utf-8;';
            extension = '.csv';
            break;
        case 'tsv':
            escaper = tsvEscape;
            separator = "\t";
            mimeType = 'text/plain';
            extension = '.txt';
            break;
        default:
            throw new Error('unknown export filetype');
    }
    

    for(var row=0; row < arrayDataToExport.length; row++)
    {
        var newRow = [];
        for(var col=0; col < arrayDataToExport[row].length; col++)
        {
            newRow.push(escaper(arrayDataToExport[row][col]));
        }
        csvData.push(newRow);
    }

    for(var i=0; i < csvData.length; i++){
        csvRows.push(csvData[i].join(separator));
    }

    var csvString = csvRows.join("\r\n");
    exportRawFile(filename, extension, mimeType, csvString);
}

export function exportRawFile(filename:string, extension:string, mimeType: string, fileData:string)
{
    if(hasDownloadSupport())
    {
        //new code using blob to bypass some browser file size restrictions on data uri in anchors 
        // - thank you luk2302+stackoverflow
        var blob = new Blob([fileData], { type: mimeType });
        let csvFilenameToUse = filename;
        if(filename.toLowerCase().indexOf(extension) != filename.length - 4)
            csvFilenameToUse += extension; //add csv extension
                   
         //removed ie support vis navigator.msSaveBlob
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", csvFilenameToUse);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    else
    {
        //todo (though this likely never triggers based on our browser support, throwing up a common dialogue to broswers we support
        //plus download links, might be useful (and firing off a client-side logging msg/err would also be useful)
        throw new Error('Browser does not support generated file download - try a different and more modern browser');
    }
}