//Push this up the stack somewhere and pass in
// then node will import from that file which passes back the client constructor
// or browser will import from another that passes back a different contructor
// client will use a 'fetch' that appears the same
// some params will diff - like cookie auth vs token auth

import type {
    ApiGuid,
    GroupCreateListParams,
    GroupDeleteListParams,
    GroupForCreate,
    GroupForUpdate,
    GroupUpdateListParams,
    PayrollInfoForCreate,
    PayrollInfoForUpdate,
    PayrollItemsQueryParams,
    CustomColorPaletteQueryParams,
    SchedulingSettings,
    SchedulingSettingsForUpdate,
    ServiceItemForCreate,
    ServiceItemForUpdate,
    ServiceItemRule,
    ServiceItemRuleOriginal,
    ThirdPartyContactAllOptionalForCreate,
    ThirdPartyContactAllOptionalForUpdate,
    CustomColorPaletteResponse,
    KioskEventSyncRequest,
    KioskEventSyncResponse,
    PtoRecordForUpdate,
    PtoRecord,
    PtoRecordForCreate,
    CreateClockEventListParams,
    PtoBalanceEditRequest,
    PtoBalanceImportResponse,
    PtoRecordCancelRequest,
    PtoRecordSetStatusRequest,
    ActivityQueryParams,
    ActivityQueryResponse,
    DetailedActivityQueryParams,
    GetPointsRequest,
    GetPointsResponse,
    DetailedActivityResponse,
    HistoryResponse,
    ClearTimesheetFlagResponse,
    ClearTimesheetFlagParams,
    ChangeClockEventListStatusParams,
    ChangeClockEventListStatusResponse,
    DeleteClockEventListResponse,
    DeleteClockEventListParams,
    AlertReportQueryRequest,
    AlertReportQueryResponse,
    QueryPtoTypeRulesResponse,
    QueryPtoTypeRuleAssignmentResponse,
    PTOAccrualRuleAssignment,
    PtoAccrualAssignmentParams,
    PtoTypeForCreate,
    PtoTypeForUpdate,
    PtoType,
    PtoAccrualRuleForCreate,
    PtoAccrualRuleForUpdate,
    PTOAccrualRule,
    GetClockAccessCallerIDRulesResponse,
    GetClockAccessIpAddressRulesResponse
 } from './types';
import * as ApiType from './types';
import { isApiGuid } from './types/ApiGuid';
import { deepCopyEmployee } from './types/Employee';
import { convertPayrollItemRuleApiToOriginal, convertPayrollItemRuleOriginalToApi } from './types/PayrollItemRule';

import { stringify as queryStringStringify } from 'query-string';
import type { Moment } from 'moment-timezone';
import { GetThingListOptions, ThingStatus } from './types/Thing';
import { JSONType } from './types/utility/jsonTypes';
import { IdempotencyHeaders } from './types/Idempotency';
import { AssignOvertimeRuleset, AssignOvertimeRulesetResponse, DefaultOvertimeResponse, OvertimeRulesetData, OvertimeSettings } from './types/OvertimeSettingsApiDataTypes';

import type * as ApiTypes from './types';
import type { IntuitSsoSetupSession, QueryIntuitSsoSetupSession } from '../../lib/src/integrations/IntuitIntegration';
import { TestApiConnectionParams, TestApiConnectionResponse } from './types/TestApiConnection';

//window can be present, but in some builds, dom lib is not there, hence why we check for it - declare a type
declare const window: Window|undefined;

const retrySchedule = [100,500,1000,2000,5000];
function getRetrySleepAmmount(tryCount:number)
{
    const tryIndex = Math.min(tryCount, retrySchedule.length -1);
    return retrySchedule[tryIndex];
}


type IdempotencyOptions = {
    idempotencyKey:string,
    // maxRetries?
    // retryWait?
    // ?
}

export interface ApiResponse<PAYLOAD>
{
    requestId: ApiGuid|"N/A"; //N/A not available - not from the server
    payload: PAYLOAD;
}

export enum ApiErrorCode
{
    NetworkError = "NetworkError",          //an issue likely before the server was reached
    Http3xxResponse = "Http3xxResponse",    //301,302, etc redirect - which our server should not issue
    UnknownContent = "UnknownContent",      //non-json response (server down with bad html response type or some other issue)
    ObsoleteClientVersionError="ObsoleteClientVersionError",
    AuthorizationRequired="AuthorizationRequired"
}
export type ApiConnectionSettings = {
    label: string,
    url: string
}
export interface ApiErrorResponse extends ApiResponse<unknown> //no payload in an error response
{
    code: ApiErrorCode
    message: string
}

class NetworkError extends Error
{
    _isNetworkError:true
    constructor(msg:string)
    {
        super(msg);
        this._isNetworkError = true;
    }
}
export function isNetworkError(err:any):err is NetworkError
{
    return (err as NetworkError)._isNetworkError ? true : false;
}
//passed back if fetch barfs in some way - could make the 3xx response use this
class ApiError implements ApiErrorResponse
{
    requestId: ApiGuid;
    code: ApiErrorCode;
    message: string;
    payload: unknown;

    // payload? any;
    //
    response:Response;          //for debugging + possible use
    constructor(response:Response, code: ApiErrorCode, message: string, payload: unknown )
    {
        this.requestId = response.headers.get('vericlock-request-id') ?? 'N/A';
        this.code = code;
        this.message = message;
        //customized for client lib
        this.response = response;
        this.payload = payload;
    }
}
export function isApiErrorObject(err:any):err is ApiError
{
    return ( typeof(err) === 'object' && (err as ApiError).response !== undefined )
}
//wrapper error - when the client gets an error response that was a replay - it uses this error, and we then know not to retry with the same key
class IdempotencyReplayApiError
{
    _idempotentReplayActualErr:ApiErrorResponse|Error
    constructor(err:ApiErrorResponse|Error)
    {
        this._idempotentReplayActualErr = err;
    }
    getMessage()
    {
        return this._idempotentReplayActualErr.message;
    }
}
export function isIdempotencyReplayApiError(err:any):err is IdempotencyReplayApiError
{
    return ( (err as IdempotencyReplayApiError)._idempotentReplayActualErr !== undefined);
}
//confirm for type guard and other purposes this is an ApiErrorResponse
export function isApiError(response:any):response is ApiErrorResponse
{
    //note - this may still be insufficient - it originalyl misidentified a Job as an error as it has a code field!
    return response && response.code !== undefined;  //!! ensures we get a bool out of an undefined or otherwise non bool value
}

// type FetchFn = window.fetch;
export type VeriClockApiOptions = Partial<VeriClockApiBaseOptions> & {
    publicKey: string,
    //fetch function signature - to pass in window/polyfill/other
    // fetchFn?: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>
    // responseObserver?: (isError:boolean, httpResonseCode: number, ApiResponse<any>)
}

const defaultOptions:VeriClockApiOptions =
{
    apiEndpoint: '',           //default to current browser page
    publicKey: '__invalid__',
    // fetchFn: fetch ? fetch : undefined  //try to use window.fetch
}

declare const __global_monkeys_bane:string;

export type ResponsePluginAction = {
    action: 'continue' //possibly others, like dropping the response
}
export type ReqInfo = {
    url: string,
    method: string
}
export type VeriClockApiBaseOptions = {
    apiEndpoint: string,
    consoleLogApiRequests?:(reqInfo:ReqInfo) => void,     //optionally log api requests to console
    uriPrefix?: string, //   defaults to api/
    responsePlugin?: (reqInfo:ReqInfo, res:Response, json:any) => Promise<ResponsePluginAction> //called after every request, regardless of success/fail - can hook into response headers for notification logic
    onBeforeResponseProcessed?: (res:Response, payload:JSONType) => void //for valid responses, JSON type, called BEFORE the async response passes the response BACK to the invoker (this could throw and invoking code would never receive a response, and instead receive the thrown error)
}
export abstract class VeriClockApiBase
{
    private baseOptions: VeriClockApiBaseOptions;

    constructor(options: VeriClockApiBaseOptions)
    {
        const { uriPrefix, ...restOptions } = options;
        this.baseOptions = {
            uriPrefix: uriPrefix !== undefined ? uriPrefix : "/api",
            ...restOptions
        };
    }
    //implemented by extending client
    protected abstract constructExtraHeaders():Record<string,string>;

    public setApiEndpoint(url:string)
    {
        this.baseOptions.apiEndpoint = url;
    }
    //urlSuffix ex: /1.0/employees/query
    public makeFullUrl(urlSuffix:string)
    {
        const fullUrl = this.baseOptions.apiEndpoint + 
            this.baseOptions.uriPrefix + urlSuffix;
        // console.log('made full url at', fullUrl);
        return fullUrl;
    }
    public getReachabilityUrl()
    {
        return this.makeFullUrl('/1.0/reachable');
    }
    protected async sendBinaryRequest(requestOptions: {
        method:"GET"|"POST"|"DELETE"|"PUT" //methods used by VeriClockApi
        uriSuffix:string
    }): Promise<string | null> {
        const requestInit: RequestInit = {
            method: requestOptions.method
        };

        // Fixup to deal with the high likelihood of being given
        // a full url when asking for the path to an image, so
        // we anticipate a uriSuffix that starts with
        // /api/xxxx/, and drop those two components, since
        // makeFullUrl is going to prefix our argument with
        // the api and version path components.
        let inputUriSuffix = requestOptions.uriSuffix;

        // const apiVerRegex = /^\/api\//;
        // const apiVerMatch = inputUriSuffix.match(apiVerRegex);
        // if (apiVerMatch) {
        //     inputUriSuffix = '/' + inputUriSuffix
        //         .substring(apiVerMatch[0].length);
        // }

        let url = this.makeFullUrl(inputUriSuffix);

        console.log('Making binary request at --- ', url);

        let response;
        try {
            response = await fetch(url, requestInit);
        } catch (err) {
            console.log('fetch failed catastrophically', err);
            throw err;
        }

        if (response.status >= 400) {
            console.warn('Binary request failed for', url);
            throw new Error('Binary request failed for ' + url);
        }
        console.log('Binary request succeeded for', url);
        let blob = await response.blob();
        console.log('Binary request got', blob.size, 'bytes');
        return new Promise<string | null>((resolve, reject) => {
            let reader = new FileReader();
            reader.addEventListener('loadend', () => {
                const dataUrl = reader.result as string;
                console.log('Binary request returning data url', dataUrl);
                resolve(dataUrl);
            });
            reader.addEventListener('error', () => {
                var err = new Error(
                    'Failed to download binary data from ' + 
                    requestOptions.uriSuffix);
                reject(err);
            });
            reader.readAsDataURL(blob);
        });
        
    }

    protected async sendRequest<RESPONSE_TYPE>(requestOptions:{
        method:"GET"|"POST"|"DELETE"|"PUT" //methods used by VeriClockApi
        uriSuffix:string,
        payload: any,
        formDataForFilePost?: FormData,
        idempotencyOptions?: IdempotencyOptions
    }):Promise<ApiResponse<RESPONSE_TYPE>> //throws ApiErrorResponse or Error
    {
        //ensure the uriSuffix has a leading slash
        if(requestOptions.uriSuffix.length == 0 || requestOptions.uriSuffix.charAt(0) != '/')
            throw new Error('uriSuffix must start with a forward slash(/)');

        let res:Response|null = null;
        let tryCount = 0;

        let lastErr:unknown|null = null;
        const maxRetryCount = 5; //for network errors - probably enough
        //can retry if we have an idempotencyKey passed in
        const canRetry = !!requestOptions.idempotencyOptions?.idempotencyKey;
        let url = this.makeFullUrl(requestOptions.uriSuffix);
        //construct the api version portion of the URI
        let headers:{[keyof:string]:string} = this.constructExtraHeaders();

        //add idempotency key
        if(requestOptions.idempotencyOptions?.idempotencyKey)
            headers['idempotency_key'] = requestOptions.idempotencyOptions.idempotencyKey;

            //basic request object
        let fetchOptions:RequestInit = {
            method: requestOptions.method,
            headers: headers,
            credentials: 'include'
        };

        do {
            if(tryCount > 0) {
                // console.log('client - retry - sleep tryCount: ' + tryCount);
                await sleepAsync(getRetrySleepAmmount(tryCount-1)); //sleep a brief smidgen to give things a chance to correct
            }
            //add the request body as a json string OR to the query parameters (eventually)
            if(requestOptions.payload)
            {
                if(requestOptions.method == 'GET')
                {
                    //convert the object into a querystring compatible object
                    if(url.indexOf('?') >= 0) //prefix with ? or append if url already has query string
                        url += '&' + queryStringStringify(requestOptions.payload, {arrayFormat: 'bracket'});
                    else
                        url += '?' + queryStringStringify(requestOptions.payload, {arrayFormat: 'bracket'});
                }
                else if(requestOptions.method == 'POST' || requestOptions.method == 'DELETE' || requestOptions.method == 'PUT')
                {
                    fetchOptions.body = JSON.stringify(requestOptions.payload)
                }
            }
            else if(requestOptions.method == 'POST' && requestOptions.formDataForFilePost)
            {
                if (typeof fetchOptions.headers == 'object' && Object.prototype.hasOwnProperty.call(fetchOptions.headers,'Content-Type')) {
                    // VS Code doesn't seem to work with the above type guard, so I cast to any to suppress the error.
                    delete (fetchOptions.headers as any)['Content-Type'];
                }
                fetchOptions.body = requestOptions.formDataForFilePost;
            }

            //make the request
            try {
                tryCount++;
                if(this.baseOptions.consoleLogApiRequests)
                    this.baseOptions.consoleLogApiRequests({
                        url,
                        method: fetchOptions.method ?? 'unknown[default?]'
                    });
                res = await fetch(url, fetchOptions);
                break; //done
            }
            catch (err) {
                lastErr = err; //store, so it can be thrown again if we are done.

                //fetch only throws for a few reasons:
                //https://developer.mozilla.org/en-US/docs/Web/API/fetch
                //
                if(err instanceof Error)
                {
                    if(err.name === 'TypeError')
                    {
                        //network error OR formatting problems - ignore formatting ones as we will catch those in dev
                        //so skip this, and retry if allowed, otherwise will log + throw this below
                    }
                    else if(err.name === 'AbortError') //Abort() via AbortController invoked
                    {
                        throw err; //no response object to attach
                    }
                    else
                    {
                        console.warn('unknown err from fetch()', err);
                        throw err;
                    }
                }
            }
        }
        while(canRetry && tryCount < maxRetryCount);

        if(!res)
        {
            if(lastErr)
                throw lastErr;

            //probably simply can't happen, so this is more for typeguard purposes
            console.error('fetch() response not set, but lastErr also not set!');
            throw new Error('Unexpected - fetch() response not set, but lastErr also not set');
        }

        //

        function throwApiError(res:Response, errToThrow:ApiErrorResponse|Error):never
        {
            const isIdempotentReplay = res.headers.get(IdempotencyHeaders.replayedOutbound) ? true : false;

            if(isIdempotentReplay)
                throw new IdempotencyReplayApiError(errToThrow);
            throw errToThrow;
        }

        //process if its a valid http status code we can handle, otherwise fire back an appropriate error
        if(res.status)
        {
            //check for redirect first
            if(res.status >= 300 && res.status < 400)
            {
                //a redirect?  we should either auto-follow, or error out
                throwApiError(res, new ApiError(res, ApiErrorCode.Http3xxResponse, "3xx responses are not expected and not handled", undefined));
                // throw ;
            }

            const contentType = res.headers.get('content-type');
            if (!contentType || !contentType.includes('application/json'))
            {
                if(res.status === 204) //content-type can be null here, as a 202 is empty, no content successful req
                {
                    let requestId = res.headers.get('vericlock-request-id');
                    let json = {
                        requestId
                    }
                    return json as ApiResponse<RESPONSE_TYPE>
                }
                console.error(`We got content-type[${contentType}] instead of application/json`);
                throwApiError(res, new ApiError(res,ApiErrorCode.UnknownContent, "Response is malformed", undefined));
            }

            //we know its JSON now, extract it
            let json = await res.json();
            if(this.baseOptions.responsePlugin)
            {
                //todo - could get actions here to ...mutate response, for example or drop it entirely (not sure we could / should do that with a non-resolving promise, for example)
                await this.baseOptions.responsePlugin({
                    url,
                    method: fetchOptions.method ?? 'unknown[default?]'
                }, res,json);
            }



            //low level error
            if(json.code == 'ResourceNotFound')
                throwApiError(res,new Error('URI resource not found: ' + json.message))
            else if(json.code == 'MethodNotAllowedError')
                throwApiError(res,new Error(`HTTP ${requestOptions.method} method is not allowed on this URI`));

            if(!json.requestId) //missing a request Id?
            {
                let requestId = res.headers.get('vericlock-request-id');

                //old api request - try to upgrade the response
                if(requestId)
                {
                    //api errors are in the correct format, they just need the requestID adjusted
                    if(isApiError(json) && res.status >= 400) //must be >= 400 to be an error
                    {
                        json.requestId = requestId;
                        ///@ts-ignore
                        delete json.requestID; //remove
                    }
                    else
                    {
                        let newJson = {
                            requestId: requestId,
                            payload: json
                        };
                        json = newJson;
                    }
                }

                //non api response - probably didn't tranform the end point
                if(!json.requestId) //missing a request Id still - was not upgraded or pre-api-code type error
                {
                    console.log(json);
                    throwApiError(res, new Error("Does not look like an upgraded api response format, missing requestId - was the endpoint upgraded? Or Bad endpint - check URI"));
                }
            }

            if(this.baseOptions.onBeforeResponseProcessed)
                this.baseOptions.onBeforeResponseProcessed(res, json);

            if(res.status >= 200 && res.status < 300)
            {
                //todo - pre-return observer
                //todo - result mutator
                //post return observer?
                return json as ApiResponse<RESPONSE_TYPE>;   //send back parsed json cast as response type

            }
            else if(res.status >= 400)
            {
                //todo: error observer
                if(isApiError(json)) { //old API error type
                    throwApiError(res,{...json, __response:res }  as ApiErrorResponse); //cast to error
                    // throwApiError(res, new ApiError(res, json.code, json.message, json.payload));
                }
                else if(isApiError(json.payload)) { //new api error type

                    throwApiError(res, {...json.payload, __response:res } as ApiErrorResponse); //cast to error
                    // throwApiError(res, new ApiError(res, json.payload.code, json.payload.message, json.payload));
                }
                // else if(res.status == 503)
                // {
                //     //gateway error - often just dev server down
                //     console.log('Gateway error (503)');
                //     let err = new Error("Gateway error (503) - api server could not be reached.  Wait a moment and try again.");
                //   i  err.humanFriendlyError = "Gateway error (503) - api server could not be reached.  Wait a moment and try again.";
                //     throw err;
                // }
                console.log('Unrecognized 400+ response');
                console.log(json);
                console.log('Bad API Error -- end');
                throwApiError(res, new ApiError(res,ApiErrorCode.UnknownContent, "Response is malformed", json));
            }
        }
        //todo: error observer
        //useful debug info - add other info
        console.warn('request url:' + url);
        console.warn('response status: ' + res.status);
        throwApiError(res, new ApiError(res,ApiErrorCode.NetworkError, "Unexpected response", undefined));
    }
}
export class VeriClockApi extends VeriClockApiBase
{
    // options: VeriClockApiOptions;
    private options:VeriClockApiOptions;
    // private fetchFn: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>

    //the authtoken to use for requests
    private authtoken:ApiGuid|null;
    private subdomain:string|null;

    constructor(options:VeriClockApiOptions)
    {
        super({
            ...options,
            apiEndpoint: options.apiEndpoint ? options.apiEndpoint : ''
        });
        //override default options with passed in options
        this.options = {...defaultOptions, ...options};
        this.authtoken = null;
        this.subdomain = null;

        if(!options.publicKey)
            throw new Error("VeriClockApi constructor error: options requires a publicKey");

        if(!isApiGuid(options.publicKey))
            throw new Error("VeriClockApi constructor error: publicKey must be a valid guid like similar to 41de7e5a-81fc-4b39-8219-4e51b19aa5c3");

        if(!fetch)
        {
            throw new Error("VeriClockApi requires a \"fetch\" implementation be available in the global context");
        }
    }

    protected async sendRequest<RESPONSE_TYPE>(requestOptions:{
        version?:string,
        method:"GET"|"POST"|"DELETE"|"PUT" //methods used by VeriClockApi
        uriSuffix:string,
        payload: any,
        formDataForFilePost?: FormData,
        idempotencyOptions?: IdempotencyOptions     //optional - maybe add some enforcement client side for routes that require it
    }):Promise<ApiResponse<RESPONSE_TYPE>> //throws ApiErrorResponse or Error
    {
        //ensure the uriSuffix has a leading slash
        if(requestOptions.uriSuffix.length == 0 || requestOptions.uriSuffix.charAt(0) != '/')
            throw new Error('uriSuffix must start with a forward slash(/)');

        //construct the api version portion of the URI
        let apiVersion = (requestOptions.version ? requestOptions.version : '1.0');
        let uriSuffix = '/' + apiVersion + requestOptions.uriSuffix;

        //invoke parent
        return super.sendRequest({
            ...requestOptions,
            uriSuffix,
        });
    }

    protected constructExtraHeaders(): Record<string,string>
    {
        let extraHeaders:Record<string,string> =
        {
            'Content-Type': 'application/json',
            'vericlock_api_public_key': this.options.publicKey,
        };
        if(this.authtoken)
            extraHeaders['vericlock_authtoken'] = this.authtoken;
        if(this.subdomain)
            extraHeaders['vericlock_domain'] = this.subdomain;
        //if the window is present, assume we are in browser context and look for the CSRF token
        if(typeof(window) !== 'undefined' && typeof(__global_monkeys_bane) !== 'undefined') //if we're in the vericlock web-app global context, grab our csrf token
            extraHeaders['X-VERICLOCK-CSRF'] = __global_monkeys_bane;

        return extraHeaders;
    }

    //if posting to API from an external lib
    public getHttpHeaders()
    {
        return this.constructExtraHeaders();
    }


    public setAuthInfo(authtoken:ApiGuid, subdomain: string)
    {
        if(!isApiGuid(authtoken))
            throw new Error('authtoken must be a valid guid like similar to 41de7e5a-81fc-4b39-8219-4e51b19aa5c3');
        if(typeof(subdomain) != 'string' || subdomain.length == 0)
            throw new Error(`subdomain must be a valid string from the accounts private url: <subdomain>.vericlock.com`);

        this.authtoken = authtoken;
        this.subdomain = subdomain;
    }

    public async testApiConnnection(params:TestApiConnectionParams)
    {
        return this.sendRequest<TestApiConnectionResponse>(
        {
            method: 'POST',
            uriSuffix: '/testApiConnection',
            payload: params
        });
    }
    public async testApiConnnection2(params:TestApiConnectionParams)
    {
        return this.sendRequest<TestApiConnectionResponse>(
        {
            method: 'POST',
            uriSuffix: '/testApiConnection2',
            payload: params
        });
    }
    public async authenticatePublic(params:ApiType.ApiPublicAuthenticationRequest)
    {
        let res = await this.sendRequest<ApiType.ApiPublicAuthenticationResponse>({
            method: 'POST',
            uriSuffix: '/auth',
            payload: params,
            version: '1.1'
        });
        return res;
    }

    async queryEmployees( options:ApiType.EmployeeQueryOptions )
    {
        //could validate some of the query parameters
        return this.sendRequest<ApiType.Employee[]>(
            {
                method: 'POST',
                uriSuffix: '/employee/query',
                payload: options
            });
    }

    async checkEmployeeIdAvailable( options:{id:string} ):Promise<ApiResponse<string>>
    {
        return this.sendRequest<string>(
            {
                method: 'POST',
                uriSuffix: '/employee/checkIdAvailable',
                payload: options
            });
    }

    // Do some translate/clean up.  Replacing the API routes should eliminate this step.
    _employeeCleanup<T extends ApiType.EmployeeForCreateOrUpdate>( e:T ):T {

        let employee = deepCopyEmployee(e);
        if (employee.settings?.phone && employee.settings.phone.pin==null)
        {
            delete employee.settings.phone.pin;
        }
        if (employee.password==null)
        {
            delete employee.password;
        }
        delete employee.passconf;
        return employee;
    }

    async createEmployee( employee:ApiType.EmployeeForCreate )
    {
        const res = await this.sendRequest<ApiType.Employee[]>(
            {
                method: 'POST',
                uriSuffix: '/employee/create',
                payload: this._employeeCleanup(employee)
            });
        if(res.payload.length == 1)
            return res.payload[0];
        throw new Error(`createEmployee did not error out but returned ${res.payload.length} entries`);
    }

    async updateEmployee( employee:ApiType.EmployeeForUpdate ):Promise<ApiResponse<ApiType.Employee>>
    {
        return this.sendRequest<ApiType.Employee>(
        {
            method: 'POST',
            uriSuffix: '/employee/'+employee.guid+'/update',
            payload: this._employeeCleanup(employee)
        });
    }

    async createEmployeeList( employees:ApiType.EmployeeForCreate[] ):Promise<ApiResponse<ApiType.Employee[]>>
    {
        return this.sendRequest(
        {
            method: 'POST',
            uriSuffix: '/employee/createlist',
            payload: {
                employeeList: employees.map(e => this._employeeCleanup(e))
            }
        });
    }
    async updateEmployeeList( employees:ApiType.EmployeeForUpdate[] ):Promise<ApiResponse<ApiType.Employee[]>>
    {
        return this.sendRequest(
        {
            method: 'POST',
            uriSuffix: '/employee/updateList',
            payload: {
                employeeList: employees.map(e => this._employeeCleanup(e))
            }
        });
    }
    async createUpdateEmployeeList( employees:(ApiType.EmployeeForCreate|ApiType.EmployeeForUpdate)[]):Promise<ApiResponse<ApiType.Employee[]>>
    {
        return this.sendRequest(
        {
            method: 'POST',
            uriSuffix: '/employee/createUpdateList',
            payload: {
                employeeList: employees.map(e => this._employeeCleanup(e)),
                // employeeListUpdate: employeesToUpdate.map(e => this._employeeCleanup(e))
            }
        });
    }

    async getEmployeeDeleteWarnings( params: ApiType.EmployeeDeleteWarningsCheckRequestParams ):Promise<ApiResponse<ApiType.EmployeeDeleteWarningsCheckResponse>>
    {
        return this.sendRequest<ApiType.EmployeeDeleteWarningsCheckResponse>(
        {
            method: 'POST',
            uriSuffix: '/employee/getDeleteWarnings',
            payload: params
        });
    }
    async queryGroups( options:ApiType.GroupQueryOptions )
    {
        //could validate some of the query parameters
        return this.sendRequest<ApiType.Group[]>(
            {
                method: 'POST',
                uriSuffix: '/group/querylist',
                payload: options
            });
    }

    async queryJobs( options:ApiType.JobQueryOptions )
    {
        //could validate some of the query parameters
        return this.sendRequest<ApiType.Job[]>(
            {
                method: 'POST',
                uriSuffix: '/job/query',
                payload: options
            });
    }
    async getJob(jobGuid:ApiGuid, accessControlLists: boolean)
    {
        return this.sendRequest<ApiType.Job|null>({
            method: 'GET',
            uriSuffix: '/job/' + jobGuid,
            payload: null
        })
    }
    async getJobBudget( jobGuid: ApiGuid, jobBudgetRange: ApiType.JobBudgetRange )
    {
        return this.sendRequest<ApiType.JobBudgetInfo>({
            method: 'GET',
            uriSuffix: `/job/${jobGuid}/getCost/${jobBudgetRange}`,
            payload: null,
        });
    }
    async createJob( job:ApiType.JobForCreate )
    {
        return this.sendRequest<ApiType.Job>(
        {
            method: 'POST',
            uriSuffix: '/job/create',
            payload: job
        });
    }
    async updateJob( job:ApiType.JobForUpdate )
    {
        return this.sendRequest<ApiType.Job>(
        {
            method: 'POST',
            uriSuffix: `/job/${job.guid}/update`,
            payload: job
        });
    }
    async createUpdateJobList( params:ApiType.RequestJobCreateUpdateListParams )
    {
        return this.sendRequest<ApiType.Job[]>(
        {
            method: 'POST',
            uriSuffix: '/job/createUpdateList',
            payload: params
        });
    }
    async createUpdateServiceItemList( params:ApiType.RequestServiceItemCreateUpdateListParams )
    {
        return this.sendRequest<ApiType.ServiceItem[]>(
        {
            method: 'POST',
            uriSuffix: '/serviceItem/other/createUpdateList',
            payload: params
        });
    }

    async createGroup(group:GroupForCreate)
    {
        let payload:GroupCreateListParams = {
            groupsList: [group]
        }
        let res = await this.sendRequest<ApiType.Group[]>({
            method: 'POST',
            uriSuffix: '/group/createlist',
            payload
        });
        return { //repackage the api response with modified singular payload
            ...res,
            payload: res.payload[0], //get here, always succeeded
        }
    }
    async updateGroup(group:GroupForUpdate)
    {
        let payload:GroupUpdateListParams = {
            groupsList: [group]
        }
        let res = await this.sendRequest<ApiType.Group[]>(
        {
            method: 'POST',
            uriSuffix: '/group/updatelist',
            payload
        });
        return {
            ...res,
            payload: res.payload[0], //get here, always succeeded
        }
    }
    async createUpdateGroupList(groups:(GroupForCreate|GroupForUpdate)[])
    {
        let payload:ApiType.GroupCreateUpdateListParams = {
            groupsList: groups
        }
        return this.sendRequest<ApiType.GroupCreateUpdateListResponse>(
        {
            method: 'POST',
            uriSuffix: '/group/createUpdateList',
            payload
        });
    }
    async deleteGroup(group:GroupForUpdate)
    {
        let payload: GroupDeleteListParams = {
            groupGuids: [group.guid]
        }
        await this.sendRequest<ApiType.Group[]>(
        {
            method: 'POST',
            uriSuffix: '/group/deletelist',
            payload
        });
    }
    async createServiceItem(serviceItem:ServiceItemForCreate)
    {
       return this.sendRequest<ApiType.ServiceItem>(
        {
            method: 'POST',
            uriSuffix: '/serviceItem/create',
            payload: serviceItem
        });
    }
    async updateServiceItem(serviceItem:ServiceItemForUpdate)
    {
       return this.sendRequest<ApiType.ServiceItem>(
        {
            method: 'POST',
            uriSuffix: `/serviceItem/${serviceItem.guid}/update`,
            payload: serviceItem
        });
    }
    async getJobRules()
    {
        return this.sendRequest<ApiType.JobRule[]>({
            method: 'GET',
            uriSuffix: '/job/rule/query/', //trailing / is important - routes set up strangly in jobRules.js
            payload: null
        })
    }
    async createJobRule(jobRule: ApiType.JobRuleForCreate)
    {
        return this.sendRequest<ApiType.JobRule>({
            method: 'POST',
            uriSuffix: '/job/rule/edit',
            payload: jobRule
        })
    }
    async deleteJobRule(jobRule: ApiType.JobRuleForCreate)
    {
        return this.sendRequest<void>({
            method: 'POST',
            uriSuffix: '/job/rule/delete',
            payload: jobRule
        })
    }

    convertOldToNewServiceItemRule(old:ServiceItemRuleOriginal):ServiceItemRule
    {
        let rule:ServiceItemRule = {
            employee: old.employeeGuid === null ? 'any' : old.employeeGuid,
            group: old.groupGuid === null ? 'any' : old.groupGuid,
            job: old.jobGuid === null ? 'any' : old.jobGuid,
            //none|optional|required|ApiGid
            serviceItem: old.serviceItemType !== 'specified' ? old.serviceItemType : old.serviceItemGuid === null ? 'none' : old.serviceItemGuid,
            createDate: old.createDate
        }
        return rule;
    }
    convertNewServiceItemRuleToOld(rule:ApiType.ServiceItemRuleForCreate):Omit<ServiceItemRuleOriginal,'createDate'>
    {
        let oldRule:Omit<ServiceItemRuleOriginal,'createDate'> = {
            employeeGuid: rule.employee === 'any' ? null : rule.employee,
            groupGuid: rule.group === 'any' ? null : rule.group,
            jobGuid: rule.job === 'any' ? null : rule.job,
            serviceItemType: (rule.serviceItem === 'optional' || rule.serviceItem === 'required') ? rule.serviceItem : 'specified',
            serviceItemGuid: (rule.serviceItem === 'optional' || rule.serviceItem === 'required' || rule.serviceItem === 'none') ? null : rule.serviceItem,
            // createDate: rule.createDate
        }
        return oldRule;
    }
    async getServiceItemRules()
    {
        let res = await this.sendRequest<ApiType.ServiceItemRuleOriginal[]>({
            method: 'POST',
            uriSuffix: '/serviceItem/queryRules', //trailing / is important - routes set up strangly in jobRules.js
            payload: null
        });
        let converted = res.payload.map(old => this.convertOldToNewServiceItemRule(old));
        let copy = {...res, payload: converted }
        return copy;
    }
    async createServiceItemRule(rule: ApiType.ServiceItemRuleForCreate)
    {
        let orig = this.convertNewServiceItemRuleToOld(rule);
        let res = await this.sendRequest<ApiType.ServiceItemRuleOriginal>({
            method: 'POST',
            uriSuffix: '/serviceItem/createRule',
            payload: orig
        })
        let converted = this.convertOldToNewServiceItemRule(res.payload);
        let copy = {...res, payload: converted }
        return copy;

    }
    async deleteServiceItemRule(rule: ApiType.ServiceItemRule)
    {
        let orig = this.convertNewServiceItemRuleToOld(rule);
        return this.sendRequest<void>({
            method: 'POST',
            uriSuffix: '/serviceItem/deleteRule',
            payload: orig
        })
    }


    async getServiceItemRateRules()
    {
        let res = await this.sendRequest<ApiType.QueryServiceItemRateRuleResponse>({
            method: 'POST',
            uriSuffix: '/serviceItem/queryRateRules', //trailing / is important - routes set up strangly in jobRules.js
            payload: null
        });
        return res;
    }
    async createServiceItemRateRules(rules: ApiType.ServiceItemRateRuleForCreate[])
    {
        let res = await this.sendRequest<ApiType.CreateServiceItemRateRulesResponse>({
            method: 'POST',
            uriSuffix: '/serviceItem/createRateRules',
            payload: { rules }
        })
        return res;
    }
    async createServiceItemRateRule(rule: ApiType.ServiceItemRateRuleForCreate)
    {
        let res = await this.sendRequest<ApiType.CreateServiceItemRateRuleResponse>({
            method: 'POST',
            uriSuffix: '/serviceItem/createRateRule',
            payload: { rule }
        })
        return res;
    }
    async deleteServiceItemRateRule(rules: ApiType.ServiceItemRateRule[])
    {
        return this.sendRequest<void>({
            method: 'POST',
            uriSuffix: '/serviceItem/deleteRateRule',
            payload: { rules }
        })
    }

    //scheduled based rounding + blocking rules (not strictly schedule related)
    async getRoundingBlockingRuleAssignments()
    {
        let res = await this.sendRequest<ApiType.QueryRoundingBlockingRuleAssignmentsResponse>({
            method: 'POST',
            uriSuffix: '/serviceItem/queryRateRules', //trailing / is important - routes set up strangly in jobRules.js
            payload: null            
        });
        return res;
    }
    async createRoundingBlockingRuleAssignments(rule: ApiType.RoundingBlockingRuleAssignmentForCreate[])
    {
        let res = await this.sendRequest<ApiType.CreateRoundingBlockingRuleAssignmentResponse>({
            method: 'POST',
            uriSuffix: '/serviceItem/createRateRule',
            payload: { rule }
        })
        return res;
    }
    async deleteRoundingBlockingRuleAssignments(rules: ApiType.RoundingBlockingRuleAssignment[])
    {
        return this.sendRequest<void>({
            method: 'POST',
            uriSuffix: '/serviceItem/deleteRateRule',
            payload: { rules }
        })
    }
    
    
    async getTimeEntryMinimumRules()
    {
        let res = await this.sendRequest<ApiType.QueryTimeEntryMinimumRuleResponse>({
            method: 'POST',
            uriSuffix: '/settings/queryTimeEntryMinimumRules', //trailing / is important - routes set up strangly in jobRules.js
            payload: null
        });
        return res;
    }

    async createTimeEntryMinimumRule(rule: ApiType.TimeEntryMinimumRuleForCreate)
    {
        let res = await this.sendRequest<ApiType.CreateTimeEntryMinimumRuleResponse>({
            method: 'POST',
            uriSuffix: '/settings/createTimeEntryMinimumRule',
            payload: { rule }
        })
        return res;
    }
    async deleteTimeEntryMinimumRule(rules: ApiType.TimeEntryMinimumRule[])
    {
        return this.sendRequest<void>({
            method: 'POST',
            uriSuffix: '/settings/deleteTimeEntryMinimumRule',
            payload: { rules }
        })
    }
    async getPayrollItemRules()
    {
        let items = await this.sendRequest<ApiType.PayrollItemRuleOriginal[]>({
            method: 'POST',
            uriSuffix: '/payroll/queryRules', //trailing / is important - routes set up strangly in jobRules.js
            payload: null
        })
        let { payload:old, ...rest } = items;
        let payload = old.map(pi => convertPayrollItemRuleOriginalToApi(pi));

        return {
            payload,
            ...rest,
        }
    }
    async createPayrollItemRule(rule: ApiType.PayrollItemRuleForCreate)
    {
        let newPi = convertPayrollItemRuleApiToOriginal(rule);
        let item = await this.sendRequest<ApiType.PayrollItemRuleOriginal>({
            method: 'POST',
            uriSuffix: '/payroll/createRule',
            payload: newPi
        });
        let { payload:old, ...rest } = item;
        let payload = convertPayrollItemRuleOriginalToApi(old);

        return {
            payload,
            ...rest,
        }
    }
    async deletePayrollItemRule(rule: ApiType.PayrollItemRule)
    {
        let delRule = convertPayrollItemRuleApiToOriginal(rule);
        return this.sendRequest<void>({
            method: 'POST',
            uriSuffix: '/payroll/deleteRule',
            payload: delRule
        })
    }


// export interface JobCreateUpdateParams {
//     jobs: Array<JobForCreate|JobForUpdate>
// }
    async queryServiceItems(options:ApiType.ServiceItemQueryOptions)
    {
        return this.sendRequest<ApiType.ServiceItem[]>(
        {
            method: 'POST',
            uriSuffix: '/serviceItem/query',
            payload: options
        });
    }

    async queryPayrollInfo(params:PayrollItemsQueryParams)
    {
        return this.sendRequest<ApiType.PayrollInfo[]>(
        {
            method: 'POST',
            uriSuffix: '/payroll/query',
            payload: params
        });
    }
    async updatePayrollItem(item:PayrollInfoForUpdate)
    {
        return this.sendRequest<ApiType.PayrollInfo>(
        {
            method: 'POST',
            uriSuffix: `/payroll/${item.guid}/update`,
            payload: item
        });
    }
    async createPayrollItem(item:PayrollInfoForCreate)
    {
        return this.sendRequest<ApiType.PayrollInfo>(
        {
            method: 'POST',
            uriSuffix: `/payroll/create`,
            payload: item
        });
    }
    async queryEmployeePayrollInfo(employeeGuid:ApiGuid)
    {
        return this.sendRequest<ApiType.EmployeePayrollInfo[]>(
        {
            method: 'POST',
            uriSuffix: '/payroll/queryPayrollInfo',
            payload: {employeeGuid:employeeGuid}
        });
    }
    async queryEmployeesPayrollInfo(params: ApiType.RequestEmployeesPayrollInfoParams)
    {
        return this.sendRequest<ApiType.EmployeesPayrollInfoResponse>(
        {
            method: 'POST',
            uriSuffix: '/payroll/queryEmployeesPayrollInfo',
            payload: params
        });
    }


    async saveEmployeePayrollInfo(payrollItems:ApiType.EditEmployeePayrollInfoOptions[])
    {
        return this.sendRequest<void>(
        {
            method: 'POST',
            uriSuffix: '/payroll/savePayrollInfo',
            payload: {payrollInfo:payrollItems},
        });
    }

    async sendEmployeeInvite(employeeGuids: ApiType.ApiGuid[]) :Promise<ApiResponse<void>>
    {
        return this.sendRequest<void>(
            {
                method: 'POST',
                uriSuffix: '/employee/sendInvite',
                payload: {employeeGuids:employeeGuids},
            });
    }

    async sendEmployeeReset(employeeGuids: ApiType.ApiGuid[])
    {
        return this.sendRequest<void>(
            {
                method: 'POST',
                uriSuffix: '/employee/sendReset',
                payload: {employeeGuids:employeeGuids},
            });
    }


    //// Scheduling
    async createScheduledShifts(options:ApiType.ApiParamsCreateShifts)
    {
        return this.sendRequest<ApiType.ApiCreateShiftsResponse>(
        {
            method:'POST',
            uriSuffix: '/scheduling/shift/create',
            payload:options
        });
    }
    async editScheduledShifts(options:ApiType.ApiParamsEditShifts)
    {
        return this.sendRequest<ApiType.ApiEditShiftsResponse>(
        {
            method:'POST',
            uriSuffix: '/scheduling/shift/edit',
            payload:options
        });
    }

    async queryScheduledShifts(options:ApiType.ApiParamsShiftQuery|ApiType.ApiParamsShiftQueryByGuids)
    {
        return this.sendRequest<ApiType.ApiScheduledShift[]>(
        {
            method:'POST',
            uriSuffix: '/scheduling/shift/query',
            payload:options
        });
    }

    async deleteScheduledShifts(options:ApiType.ApiParamsDeleteShifts)
    {
        return this.sendRequest<ApiType.ApiDeleteShiftsResponse>(
        {
            method:'DELETE',
            uriSuffix: '/scheduling/shift/delete',
            payload:options
        });
    }
    async queryScheduleConflicts(options:ApiType.ApiParamsScheduleConflictQuery)
    {
        return this.sendRequest<ApiType.ShiftConflict[]>({
            method:'POST',
            uriSuffix: '/scheduling/conflict/query',
            payload:options
        });
    }
    async dismissScheduleConflicts(options:ApiType.ApiParamsScheduleConflictDismissQuery)
    {
        return this.sendRequest<ApiType.ShiftConflict[]>({
            method:'POST',
            uriSuffix: '/scheduling/conflict/dismiss',
            payload:options
        });
    }
    async queryScheduleTemplates(options:ApiType.ApiParamsScheduleTemplateQuery)
    {
        return this.sendRequest<ApiType.ScheduleTemplate[]>({
            method:'POST',
            uriSuffix: '/scheduling/template/query',
            payload:options
        });
    }

    async createScheduleTemplate(options:ApiType.ScheduleTemplateForCreate)
    {
        return this.sendRequest<ApiType.ScheduleTemplate>(
            {
                method:'POST',
                uriSuffix: '/scheduling/template/create',
                payload:options
            });
    }
    async updateScheduleTemplate(options:ApiType.ScheduleTemplateForEdit)
    {
        return this.sendRequest<ApiType.ScheduleTemplate>(
            {
                method:'POST',
                uriSuffix: '/scheduling/template/edit',
                payload:options
            });
    }
    async deleteScheduleTemplate(options:ApiType.ApiParamsDeleteScheduleTemplate)
    {
        return this.sendRequest<void>(
            {
                method:'DELETE',
                uriSuffix: '/scheduling/template/delete',
                payload:options
            });
    }
    // async createScheduleTemplateShifts(options:ApiType.ApiParamsCreateTemplateShifts)
    // {
    //     return this.sendRequest<ApiType.ApiCreateTemplateShiftsResponse>(
    //     {
    //         method:'POST',
    //         uriSuffix: '/scheduling/template/shift/create',
    //         payload:options
    //     });
    // }
    // async editScheduleTemplateShifts(options:ApiType.ApiParamsEditTemplateShifts)
    // {
    //     return this.sendRequest<ApiType.ApiEditTemplateShiftsResponse>(
    //     {
    //         method:'POST',
    //         uriSuffix: '/scheduling/template/shift/edit',
    //         payload:options
    //     });
    // }
    // async deleteScheduleTemplateShifts(options:ApiType.ApiParamsDeleteTemplateShifts)
    // {
    //     return this.sendRequest<void>(
    //     {
    //         method:'DELETE',
    //         uriSuffix: '/scheduling/template/shift/delete',
    //         payload:options
    //     });
    // }
    //todo - remove, edit/create/del via full template edit?

    async getSchedulingSettings()
    {
        return this.sendRequest<SchedulingSettings>(
        {
            method:'GET',
            uriSuffix: '/scheduling/settings',
            payload: null
        });
    }
    async editSchedulingSettings(params:SchedulingSettingsForUpdate)
    {
        return this.sendRequest<SchedulingSettings>(
        {
            method:'POST',
            uriSuffix: '/scheduling/settings',
            payload: params
        });
    }
    async getSchedulingCustomColourPalette(params:CustomColorPaletteQueryParams)
    {
        return this.sendRequest<CustomColorPaletteResponse>(
        {
            method:'POST',
            uriSuffix: '/colorPalette/query',
            payload: params
        });
    }

////////////////////////////////////
// PTO
////////////////////////////////////
    async getPto(options:ApiType.ApiParamsPtoQuery)
    {
        return this.sendRequest<ApiType.ApiPtoQueryResponse>(
            {
                method:'GET',
                uriSuffix: '/pto/record',
                payload:options
            });
    }

    async getPtoRecords( params:ApiType.ApiParamsPtoQuery )
    {
        return this.sendRequest<ApiType.ApiPtoQueryResponse>(
            {
                method: 'POST',
                uriSuffix: '/pto/record/query',
                payload: params
            });
    }
    async getPtoTypes( params:GetThingListOptions )
    {
        return this.sendRequest<ApiType.ApiPtoTypeResponse>(
            {
                method: 'GET',
                uriSuffix: '/pto/type',
                payload: params
            });
    }
    async addPtoType( params:PtoTypeForCreate )
    {
        return this.sendRequest<PtoType>(
            {
                method: 'POST',
                uriSuffix: '/pto/type',
                payload: params
            });
    }
    async updatePtoType( params:PtoTypeForUpdate )
    {
        return this.sendRequest<PtoType>(
            {
                method: 'PUT',
                uriSuffix: '/pto/type',
                payload: params
            });
    }
    async getPtoBalances( params:ApiType.PtoBalanceQuery )
    {
        return this.sendRequest<ApiType.ApiPtoBalanceResponse>(
            {
                method: 'GET',
                uriSuffix: '/pto/accrual/balance',
                payload: params
            });
    }
    async getPtoBalanceHistory( params:ApiType.PtoBalanceHistoryQuery )
    {
        return this.sendRequest<ApiType.ApiPtoBalanceHistoryResponse>(
            {
                method: 'GET',
                uriSuffix: '/pto/accrual/balance/history',
                payload: params
            });
    }
    async createPtoRecord( params:PtoRecordForCreate )
    {
        return this.sendRequest<PtoRecord>(
            {
                method: 'POST',
                uriSuffix: '/pto/record',
                payload: params
            });
    }
    async updatePtoRecord( params:PtoRecordForUpdate )
    {
        return this.sendRequest<PtoRecord>(
            {
                method: 'PUT',
                uriSuffix: '/pto/record',
                payload: {datesIncluded:true, ...params}
            });
    }
    async setPtoRecordStatus( params: PtoRecordSetStatusRequest )
    {
        return this.sendRequest<void>(
            {
                method: 'POST',
                uriSuffix: '/pto/record/status',
                payload: params
            });
    }

    async cancelPtoRecord( params: PtoRecordCancelRequest )
    {
        return this.sendRequest<void>(
            {
                method: 'POST',
                uriSuffix: '/pto/record/cancel',
                payload: params
            });
    }

    async updatePtoBalance( ptoTypeGuid:ApiGuid, employeeGuid:ApiGuid, params: PtoBalanceEditRequest )
    {
        return this.sendRequest<void>(
            {
                method: 'POST',
                uriSuffix: '/pto/accrual/balance?employeeGuid='+employeeGuid+'&ptoTypeGuid='+ptoTypeGuid,
                payload: params
            });
    }

    async exportPtoBalances()
    {
        return this.sendRequest<string>(
            {
                method: 'GET',
                uriSuffix: '/pto/accrual/balance/export',
                payload: null
            });
    }

    //Beware - RN + files via FormData will prob not work without effort
    async importPtoBalances( formData: FormData )
    {
        return this.sendRequest<PtoBalanceImportResponse>(
            {
                method: 'POST',
                uriSuffix: '/pto/accrual/balance/import',
                formDataForFilePost:formData,
                payload: null
            });
    }

    async getPtoClockRootGuids(params: {ptoRecordGuid:ApiGuid})
    {
        return this.sendRequest<{clockEvents:{guid:ApiGuid}[]}>(
            {
                method: 'GET',
                uriSuffix: '/pto/clockevents/'+params.ptoRecordGuid,
                payload: null
            });
    }

    async getPtoTypeRules( params:{guid?:ApiGuid, ptoTypeGuid?:ApiGuid} )
    {
        return this.sendRequest<QueryPtoTypeRulesResponse>(
            {
                method: 'GET',
                uriSuffix: '/pto/accrual/rule',
                payload: params
            });
    }

    async createPtoAccrualRule( params:PtoAccrualRuleForCreate )
    {
        return this.sendRequest<PTOAccrualRule>(
            {
                method: 'POST',
                uriSuffix: '/pto/accrual/rule',
                payload: params
            });
    }

    async updatePtoAccrualRule( params:PtoAccrualRuleForUpdate )
    {
        return this.sendRequest<PTOAccrualRule>(
            {
                method: 'PUT',
                uriSuffix: '/pto/accrual/rule',
                payload: params
            });
    }

    async deletePtoTypeRule( params:{guid:ApiGuid} )
    {
        return this.sendRequest<void>(
            {
                method: 'DELETE',
                uriSuffix: '/pto/accrual/rule?guid='+params.guid,
                payload: null
            });
    }

    async getPtoTypeRuleAssignments( params:{ptoTypeGuid:ApiGuid} )
    {
        return this.sendRequest<QueryPtoTypeRuleAssignmentResponse>(
            {
                method: 'GET',
                uriSuffix: '/pto/accrual/assignment',
                payload: params
            });
    }

    async createPtoTypeRuleAssignment( params:PtoAccrualAssignmentParams[] )
    {
        return this.sendRequest<PTOAccrualRuleAssignment[]>(
            {
                method: 'POST',
                uriSuffix: '/pto/accrual/assignment',
                payload: {assignments:params}
            });
    }

    async updatePtoTypeRuleAssignment( params:PtoAccrualAssignmentParams[] )
    {
        return this.sendRequest<PTOAccrualRuleAssignment[]>(
            {
                method: 'PUT',
                uriSuffix: '/pto/accrual/assignment',
                payload: {assignments:params}
            });
    }


    async deletePtoTypeRuleAssignment( params:PtoAccrualAssignmentParams )
    {
        return this.sendRequest<void>(
            {
                method: 'DELETE',
                uriSuffix: '/pto/accrual/assignment?employeeGuid='+(params.employeeGuid??'')+'&groupGuid='+(params.groupGuid??'')+'&ptoTypeGuid='+params.ptoTypeGuid,
                payload: null
            });
    }

    async getComments( params:ApiType.QueryCommentsParams )
    {
        return this.sendRequest<ApiType.QueryCommentsResponse>(
            {
                method: 'POST',
                uriSuffix: '/comment/query',
                payload: params
            });
    }

    async getSignatureRequests(params:ApiType.ApiSignatureRequestQueryParams)
    {
        return this.sendRequest<ApiType.ApiSignatureRequestQueryResponse>(
        {
            method: 'POST',
            uriSuffix: '/signatures/requests/query',
            payload: params
        });
    }

////////////////////////////////////
// Timesheet
////////////////////////////////////
    public async getTimesheet(options:ApiType.TimeSheetQueryParams):Promise<ApiResponse<ApiType.TimeSheetQueryResponse>>
    {
        return this.sendRequest<ApiType.TimeSheetQueryResponse>(
            {
                method:'POST',
                uriSuffix: '/timesheet/query',
                payload:options
            });

    }

    public async fetchSimpleData (options: {
        searchPeriod: {
            start: Moment | string, //is converted to string for transport
            end: Moment | string,
        },
        columns: ApiType.SimpleTimeSheetQueryColumns[]
    }):Promise<ApiResponse<ApiType.SimpleTimesheetResponse>>
    {
        return this.sendRequest<ApiType.SimpleTimesheetResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/query/simple',
            payload:options
        });
    }

    public async splitShiftsAtMidnight(params: ApiType.SplitShiftAtMidnightRequestParams)
    {
        return this.sendRequest<ApiType.SplitShiftAtMidnightResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/splitShiftsAtMidnight',
            payload: params
        });
    }


////////////////////////////////////
// Notifications
////////////////////////////////////
    public async sendNotification(options: ApiType.SendNotificationParams)
    {
        return this.sendRequest<ApiType.NotificationSendResponse>(
        {
            method:'POST',
            uriSuffix: '/notifications/send',
            payload:options
        });
    }
    public async getNotifications(options: ApiType.GetNotificationParams)
    {
        return this.sendRequest<ApiType.GetNotificationResponse>(
        {
            method:'POST',
            uriSuffix: '/notifications/query',
            payload:options
        });
    }
    public async getNotificationLogs(options: ApiType.GetNotificationLogsParams)
    {
        return this.sendRequest<ApiType.GetNotificationLogsResponse>(
        {
            method:'POST',
            uriSuffix: '/notifications/sentLogs',
            payload:options
        });
    }
    public async setNotificationReceivedStatusFlag(options: ApiType.SetNotificationReceivedParams)
    {
        return this.sendRequest<ApiType.SetNotificationReceivedResponse>(
            {
                method:'POST',
                uriSuffix: '/notifications/receivedFlag',
                payload:options
            });
    }
    public async setNotificationReadStatusFlag(options: ApiType.SetNotificationReadParams)
    {
        return this.sendRequest<ApiType.SetNotificationReadResponse>(
            {
                method:'POST',
                uriSuffix: '/notifications/readFlag',
                payload:options
            });
    }
    public async deleteNotifications(options: ApiType.DeleteNotificationParams)
    {
        return this.sendRequest<ApiType.DeleteNotificationResponse>(
        {
            method:'DELETE',
            uriSuffix: '/notifications/delete',
            payload:options
        });
    }

    public async getPushNotificationRoster()
    {
        return this.sendRequest<ApiType.PushNotificationRosterResponse>(
        {
            method:'GET',
            uriSuffix: '/notifications/pushRoster',
            payload:null
        });
    }
//////// broadcast emails
    public async sendBroadcastEmail(params:ApiType.BroadcastEmailApiParams)
    {
        return this.sendRequest<ApiType.BroadcastEmailApiResponse>(
        {
            method:'POST',
            uriSuffix: '/messages/broadcast',
            payload:params
        });
    }

//////// scheduled reports
    public async getScheduledReports(params:ApiType.GetScheduledReportsParams)
    {
        return this.sendRequest<ApiType.GetScheduledReportsResponse>(
        {
            method:'POST',
            uriSuffix: '/reports/scheduledReports/query',
            payload:params
        });
    }
    public async deleteScheduledReport(params: ApiType.DeleteScheduledReportsParams)
    {
            return this.sendRequest<ApiType.DeleteScheduledReportsResponse>(
            {
                method:'DELETE',
                uriSuffix: '/reports/scheduledReports/delete',
                payload:params
            });
    }
    public async updateScheduledReport(params: ApiType.UpdateScheduledReportsParams)
    {
        return this.sendRequest<ApiType.UpdateScheduledReportsResponse>(
        {
            method:'POST',
            uriSuffix: '/reports/scheduledReports/update',
            payload:params
        });
    }
    public async createScheduledReport(params: ApiType.CreateScheduledReportsParams)
    {
        return this.sendRequest<ApiType.UpdateScheduledReportsResponse>(
        {
            method:'POST',
            uriSuffix: '/reports/scheduledReports/create',
            payload:params
        });
    }

    public async resetScheduledReports(params: ApiType.ResetScheduledReportsParams)
    {
        return this.sendRequest<ApiType.ResetScheduledReportsResponse>(
        {
            method:'POST',
            uriSuffix: '/reports/scheduledReports/reset',
            payload:params
        });
    }

    public async getCustomFields(params:{
        status?:ThingStatus[]
        teamView?:boolean,
    })
    {
        return this.sendRequest<ApiType.CustomField[]>(
        {
            method:'GET',
            uriSuffix: '/settings/customFields',
            payload:null
        });
    }
    public async createCustomField(params:ApiType.CreateCustomFieldRequestParams)
    {
        return this.sendRequest<ApiType.CreateCustomFieldResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/customFields/create',
            payload:params
        });
    }

    public async moveCustomField(params: ApiType.MoveCustomFieldRequestParams)
    {
        return this.sendRequest<ApiType.MoveCustomFieldResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/customFields/move',
            payload: params
        });
    }
    public async updateCustomField(params:ApiType.UpdateCustomFieldRequestParams)
    {
        return this.sendRequest<ApiType.UpdateCustomFieldResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/customFields/update',
            payload:params
        });
    }

    public async updateCompanySettings(settings:ApiType.CompanySettingsForUpdate)
    {
        return this.sendRequest<ApiType.Company>(
        {
            method:'POST',
            uriSuffix: '/settings/company/update',
            payload: settings
        });
    }
    public async getCompany()
    {
        return this.sendRequest<ApiType.Company>(
        {
            method:'POST',
            uriSuffix: '/company/query',
            payload: null
        });
    }
    public async getCoreData()
    {
        return this.sendRequest<ApiType.QueryCoreDataResponse>(
        {
            method:'GET',
            uriSuffix: '/account/coreData',
            payload: null
        });
    }

    public async getCustomReportsEnabled()
    {
        return this.sendRequest<ApiType.CustomReportsEnabled>(
        {
            method:'GET',
            uriSuffix: '/company/customReports',
            payload: null
        });
    }

    public async getDeviceStatus(employeeGuid:ApiGuid, clockEventGuid:ApiGuid)
    {
        return this.sendRequest<ApiType.DeviceStatusResponse>(
        {
            method:'POST',
            uriSuffix: '/employee/getDeviceStatus',
            payload:{employeeGuid,clockEventGuid}
        });
    }
    public async getPersonalSettings()
    {
        return this.sendRequest<ApiType.QueryPersonalSettingsResponse>(
        {
            method:'GET',
            uriSuffix: '/settings/personal2',
            payload: null
        });
    }
    public async updatePersonalSettings(settings:ApiType.UpdatePersonalSettingsRequestParams)
    {
        return this.sendRequest<ApiType.QueryPersonalSettingsResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/personal2',
            payload: settings
        });
    }

    public async getEmailPreferences()
    {
        return this.sendRequest<ApiType.EmailPreferencesResponse>(
        {
            method:'GET',
            uriSuffix: '/settings/emailPreferences',
            payload: null
        });
    }
    public async updateEmailPreferences(params:ApiType.EmailPreferencesForUpdate)
    {
        return this.sendRequest<ApiType.EmailPreferencesResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/emailPreferences',
            payload: params
        });
    }
    public async getEmailPreferencesPublic(params: ApiType.GetEmailPreferencesPublicRequest)
    {
        return this.sendRequest<ApiType.EmailPreferencesResponse>(
        {
            method:'GET',
            uriSuffix: '/settings/publicEmailPreferences',
            payload: params
        });
    }
    public async updateEmailPreferencesPublic(params:ApiType.UpdateEmailPreferencesPublicRequestParams)
    {
        return this.sendRequest<ApiType.EmailPreferencesResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/publicEmailPreferences',
            payload: params
        });
    }

    public async updateListVisualization(settings:ApiType.UpdateListVisualizationSettingsRequestParams)
    {
        return this.sendRequest<ApiType.UpdateListVisualizationSettingsRequestParams>(
        {
            method:'POST',
            uriSuffix: '/settings/listVisualization',
            payload: settings
        });
    }

    public async requestLocation(employeeGuid:ApiGuid)
    {
        return this.sendRequest<ApiType.RequestLocationResponse>(
        {
            method:'POST',
            uriSuffix: '/employee/requestLocation',
            payload:{employeeGuid}
        });
    }

    public async pollLocation(employeeGuid:ApiGuid,gpsId:number)
    {
        return this.sendRequest<ApiType.GpsData>(
        {
            method:'POST',
            uriSuffix: '/employee/pollLocation',
            payload:{employeeGuid,gpsId}
        });
    }

    public async getThirdPartyContact(contactGuid:ApiGuid)
    {
        return this.sendRequest<ApiType.ThirdPartyContactApiGetResponse>(
        {
            method:'GET',
            uriSuffix: '/signatures/thirdPartyContact/' + contactGuid,
            payload: null
        });
    }
    public async getThirdPartyContactList(status:ThingStatus[])
    {
        return this.sendRequest<ApiType.ThirdPartyContactListApiGetResponse>(
        {
            method:'POST',
            uriSuffix: '/signatures/thirdPartyContact/query',
            payload: null
        });
    }
    public async createThirdPartyContact(contact: ThirdPartyContactAllOptionalForCreate)
    {
        return this.sendRequest<ApiType.ThirdPartyContactApiCreateUpdateResponse>(
        {
            method:'POST',
            uriSuffix: '/signatures/thirdPartyContact/create',
            payload: {
                tpContact: contact
            }
        });
    }
    public async updateThirdPartyContact(contact: ThirdPartyContactAllOptionalForUpdate)
    {
        return this.sendRequest<ApiType.ThirdPartyContactApiCreateUpdateResponse>(
        {
            method:'POST',
            uriSuffix: '/signatures/thirdPartyContact/update',
            payload: {
                tpContact: contact
            }
        });
    }
    public async fetchTimeTypes()
    {
        return this.sendRequest<ApiType.TimeType[]>(
        {
            method:'POST',
            uriSuffix: '/settings/time/timetypes/query',
            payload: {
                status: 'active'
            },
        });
    }
    public async createTimeCode(newTimeCode:string)
    {
        return this.sendRequest<ApiType.CreateTimeTypeResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/time/timetypes/create',
            payload: {
                label: newTimeCode
            },
        });
    }

    public async getEmployeeIntegrationMaps(queryParams:ApiType.IntegrationMapQueryParams)
    {
        return this.sendRequest<ApiType.EmployeeIntegrationMap[]>(
        {
            method:'POST',
            uriSuffix: '/integrationmapping/employee/query',
            payload: queryParams,
        });
    }
    public async getJobIntegrationMaps(queryParams:ApiType.IntegrationMapQueryParams)
    {
        return this.sendRequest<ApiType.JobIntegrationMap[]>(
        {
            method:'POST',
            uriSuffix: '/integrationmapping/job/query',
            payload: queryParams,
        });
    }
    public async getServiceItemIntegrationMaps(queryParams:ApiType.IntegrationMapQueryParams)
    {
        return this.sendRequest<ApiType.ServiceItemIntegrationMap[]>(
        {
            method:'POST',
            uriSuffix: '/integrationmapping/serviceitem/query',
            payload: queryParams,
        });
    }
    public async getPayrollItemIntegrationMaps(queryParams:ApiType.IntegrationMapQueryParams)
    {
        return this.sendRequest<ApiType.PayrollItemIntegrationMap[]>(
        {
            method:'POST',
            uriSuffix: '/integrationmapping/payrollitem/query',
            payload: queryParams,
        });
    }
    public async getDashboardTimeSummary(weekStartDate:string, groupGuid:ApiType.ApiGuid|null)
    {
        return this.sendRequest<ApiType.DashboardTimeResponse>(
        {
            method:'POST',
            uriSuffix: '/dashboard/weekSummary',
            payload: {
                weekStart: weekStartDate,
                groupGuid
            },
        });
    }
    public async dashboardGetEmployeeLocations()
    {
        return this.sendRequest<ApiType.DashboardEmployeeLocationsResponse>(
            {
                method:'GET',
                uriSuffix: '/employee/locations',
                payload: null,
            });
    }

    public async queryAuditTrail(params:ApiType.QueryAuditTrailParams)
    {
        return this.sendRequest<ApiType.QueryAuditTrailResponse>(
            {
                method:'POST',
                uriSuffix: '/auditTrail/query',
                payload: params,
            });
    }

    public async getApiSettings()
    {
        return this.sendRequest<ApiType.GetApiSettingsResponse>(
        {
            method:'GET',
            uriSuffix: '/settings/api',
            payload: null,
        });
    }
    public async updateApiSettings(params:ApiType.ApiSettingsForUpdate)
    {
        return this.sendRequest<ApiType.UpdateApiSettingsResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/api',
            payload: params,
        });
    }

    public async getSsoSamlSettings()
    {
        return this.sendRequest<ApiType.SsoSamlSettings>(
        {
            method:'GET',
            uriSuffix: '/settings/sso/saml',
            payload: null,
        });
    }
    public async updateSsoSamlSettings(params:ApiType.SsoSamlSettingsForUpdate)
    {
        return this.sendRequest<ApiType.SsoSamlSettings>(
        {
            method:'POST',
            uriSuffix: '/settings/sso/saml',
            payload: params,
        });
    }
    public async getSsoGoogleSettings()
    {
        return this.sendRequest<ApiType.SsoGoogleSettings>(
        {
            method:'GET',
            uriSuffix: '/settings/sso/google',
            payload: null,
        });
    }
    public async updateSsoGoogleSettings(params:ApiType.SsoGoogleSettingsForUpdate)
    {
        return this.sendRequest<ApiType.SsoGoogleSettings>(
        {
            method:'POST',
            uriSuffix: '/settings/sso/google',
            payload: params,
        });
    }
    public async getLoginAlertsSettings()
    {
        return this.sendRequest<ApiType.LoginAlertsSettings>(
        {
            method:'GET',
            uriSuffix: '/settings/loginAlerts',
            payload: null,
        });
    }
    public async updateLoginAlertsSettings(params:ApiType.LoginAlertsSettings)
    {
        return this.sendRequest<ApiType.LoginAlertsSettings>(
        {
            method:'POST',
            uriSuffix: '/settings/loginAlerts',
            payload: params,
        });
    }
    public async getMiscServerInfo()
    {
        return this.sendRequest<ApiType.MiscServerInfo>(
        {
            method:'GET',
            uriSuffix: '/settings/miscServerInfo',
            payload: null,
        });
    }

    public async getGeofences()
    {
        return this.sendRequest<ApiType.ApiGeofenceListResponse>(
        {
            method:'POST',
            uriSuffix: '/location/geofence/query',
            payload: null,
        });
    }

    public async createGeofence(p:ApiType.GeofenceForCreate)
    {
        return this.sendRequest<ApiType.Geofence>(
        {
            method:'POST',
            uriSuffix: '/location/geofence/create',
            payload: p,
        });
    }
    public async updateGeofence(p:ApiType.GeofenceForUpdate)
    {
        return this.sendRequest<ApiType.Geofence>(
        {
            method:'POST',
            uriSuffix: '/location/geofence/update',
            payload: p,
        });
    }
    public async deleteGeofence(p:ApiType.GeofenceForDelete)
    {
        return this.sendRequest<void>(
        {
            method:'DELETE',
            uriSuffix: '/location/geofence/delete',
            payload: p,
        });
    }

    public async forwardGeocoder(p:ApiType.ForwardGeocoderRequestParams)
    {
        return this.sendRequest<ApiType.ApiGeocoderResults>(
        {
            method:'POST',
            uriSuffix: '/location/geocoder/forward',
            payload: p,
        });
    }

    public async getClockState()
    {
        return this.sendRequest<ApiType.ClockStateResponse>(
        {
            method:'GET',
            uriSuffix: '/punchClock/clockState',
            payload: null,
        });
    }

    public async clockEmployeeIn(params:ApiType.ClockInRequest)
    {
        return this.sendRequest<ApiType.ClockInResponse>(
        {
            method:'POST',
            uriSuffix: '/punchClock/web/clockIn',
            payload: params,
        });
    }

    public async clockEmployeeOut(params:ApiType.ClockOutRequest)
    {
        return this.sendRequest<ApiType.ClockOutResponse>(
        {
            method:'POST',
            uriSuffix: '/punchClock/web/clockOut',
            payload: params,
        });
    }
    public async groupClockEmployeeIn(params:ApiType.GroupClockInRequest)
    {
        return this.sendRequest<ApiType.GroupClockInResponse>(
        {
            method:'POST',
            uriSuffix: '/punchClock/web/groupClockIn',
            payload: params,
        });
    }
    public async groupClockEmployeeOut(params:ApiType.GroupClockOutRequest)
    {
        return this.sendRequest<ApiType.GroupClockOutResponse>(
        {
            method:'POST',
            uriSuffix: '/punchClock/web/groupClockOut',
            payload: params,
        });
    }
    public async getKioskSettings()
    {
        return this.sendRequest<ApiType.KioskSettings>(
        {
            method:'GET',
            uriSuffix: '/settings/kiosks',
            payload: null,
        });
    }
    public async kioskChangeApproval(params: ApiType.KioskChangeApprovalRequest)
    {
        return this.sendRequest<ApiType.KioskApprovalResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/kiosks/changeApproval',
            payload: params,
        });
    }
    public async kioskSaveSettings(params: ApiType.KioskSaveSettingsRequest)
    {
        return this.sendRequest<ApiType.KioskConfigurationSettings>(
        {
            method:'POST',
            uriSuffix: '/settings/kiosks/save',
            payload: params,
        })
    }
    public async kioskDelete(params: ApiType.KioskDeleteRequest)
    {
        return this.sendRequest<ApiType.KioskDeleteResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/kiosks/delete',
            payload: params,
        });
    }

    public async getEmailLogs(params: ApiType.EmailLogsRequest)
    {
        return this.sendRequest<ApiType.EmailLogsResponse>(
        {
            method:'GET',
            uriSuffix: '/customer/email/logs',
            payload: params,
        });
    }
    public async unblockEmail(params: ApiType.EmailUnblockRequest)
    {
        return this.sendRequest<void>(
            {
                method:'POST',
                uriSuffix: '/emails/unblock',
                payload: params,
            });
    }

    public async enableOvertime()
    {
        return this.sendRequest<void>(
        {
            method:'POST',
            uriSuffix: '/settings/overtime/enable',
            payload: null
        });
    }
    public async disableOvertime()
    {
        return this.sendRequest<void>(
        {
            method:'POST',
            uriSuffix: '/settings/overtime/disable',
            payload: null
        });
    }
    public async getOvertimeSettings()
    {
        return this.sendRequest<OvertimeSettings>(
        {
            method:'GET',
            uriSuffix: '/settings/overtime',
            payload: null
        });
    }

    // VeriClockAPI.prototype.createOvertimeRuleset = function(ruleset, callback)
    // {
    //   var uri = '/settings/overtime/ruleset/create';
    //   this.postRequest(uri, ruleset, callback);
    // }
    // VeriClockAPI.prototype.updateOvertimeRuleset = function(name, ruleset, callback)
    // {
    //   var uri = '/settings/overtime/ruleset/update';
    //   var payload = $.extend({ //inject the oldName into the payload...
    //       oldName: name
    //     },ruleset);
    //   this.postRequest(uri, payload, callback);
    // }
    // VeriClockAPI.prototype.deleteOvertimeRuleset = function(name, callback)
    // {
    //   var uri = '/settings/overtime/ruleset';
    //   this.deleteRequest(uri, {name: name}, callback);
    // }
    // VeriClockAPI.prototype.setOvertimeRulesetDefault = function(name, callback)
    // {
    //   var uri = '/settings/overtime/ruleset/default';
    //   this.postRequest(uri, {name: name}, callback);
    // }

    // VeriClockAPI.prototype.assignOvertimeRuleset = function(assignmentPayload, callback)
    // {
    //   var uri = '/settings/overtime/ruleset/assign';
    //   this.postRequest(uri, assignmentPayload, callback);
    // }

    public async createOvertimeRuleset(ruleset:OvertimeRulesetData)
    {
        return this.sendRequest<OvertimeRulesetData>(
        {
            method:'POST',
            uriSuffix: '/settings/overtime/ruleset/create',
            payload: ruleset
        });
    }

    public async updateOvertimeRuleset(name:string, ruleset:OvertimeRulesetData)
    {
        return this.sendRequest<OvertimeRulesetData>(
        {
            method:'POST',
            uriSuffix: '/settings/overtime/ruleset/update',
            payload: {
                oldName: name,
                ...ruleset
            }
        });
    }
    public async deleteOvertimeRuleset(name:string)
    {
        return this.sendRequest<OvertimeRulesetData>(
        {
            method:'DELETE',
            uriSuffix: '/settings/overtime/ruleset',
            payload: {
                name,
            }
        });
    }

    public async setDefaultOvertimeRuleset(name:string)
    {
        return this.sendRequest<DefaultOvertimeResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/overtime/ruleset/default',
            payload: {
                name,
            }
        });
    }

    public async assignOvertimeRuleset(assignmentPayload:AssignOvertimeRuleset)
    {
        return this.sendRequest<AssignOvertimeRulesetResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/overtime/ruleset/assign',
            payload: assignmentPayload
        });
    }

    public async queryAlertReports(queryParams: AlertReportQueryRequest)
    {
        return this.sendRequest<AlertReportQueryResponse>(
        {
            method:'POST',
            uriSuffix: '/reports/alertReports/query',
            payload: queryParams
        });
    }
    
    // Returns data url
    public async getActivityNoteImage(
        guid: string | null
    ) : Promise<string | null> {
        if (!guid)
            return null;

        if (guid.includes('%'))
            console.error('Nonsensical GUID: ', guid);

        var url = "/api/1.0/timesheet/activityNote/" +
            encodeURIComponent(guid);

        let dataUrl = await this.sendBinaryRequest({
            method:'POST',
            uriSuffix: url
        });

        console.log('Got binary data url from ', url, 'got', dataUrl);

        return dataUrl;
    }

    public async queryActivityViewSettings(params:ApiType.QueryActivityViewSettingsParams)
    {
        return this.sendRequest<ApiType.ActivityViewSettings>(
        {
            method:'POST',
            uriSuffix: '/settings/user/activity/query',
            payload: params
        });
    }
    public async setDefaultActivityViewTemplate(params: ApiType.SetDefaultActivityViewTemplateParams)
    {
        return this.sendRequest<ApiType.ActivityViewSettings>(
        {
            method:'POST',
            uriSuffix: '/settings/user/activity/template/default',
            payload: params
        });
    }

    public async saveActivityViewTemplate(params:ApiType.SaveActivityViewTemplateParams)
    {
        return this.sendRequest<ApiType.CreateActivityViewTemplateResponse>(
        {
            method:'POST',
            uriSuffix: '/settings/user/activity/template/create',
            payload: params
        });
    }
    // public async updateActivityViewTemplate(params:ApiType.UpdateActivityViewTemplateParams)
    // {
    //     return this.sendRequest<ApiType.UpdateActivityViewTemplateResponse>(
    //     {
    //         method:'POST',
    //         uriSuffix: '/settings/user/activity/template/update',
    //         payload: params
    //     });
    // }
    public async deleteActivityViewTemplate(params:ApiType.DeleteActivityViewTemplateParams)
    {
        return this.sendRequest<void>(
        {
            method:'POST',
            uriSuffix: '/settings/user/activity/template/delete',
            payload: params
        });
    }
    
    public async getAccessPhoneNumbers()
    {
        return this.sendRequest<ApiType.GetAccessPhoneNumbersResponse>(
        {
            method:'GET',
            uriSuffix: '/settings/accessPhoneNumbers',
            payload: null,
        });     
    }
    public async searchPhoneNumbers(params: ApiType.searchPhoneNumbersOptions)
    {
        //return this.sendRequest<ApiType.SearchPhoneNumbers>(
        return this.sendRequest<ApiType.availableNumber[]>(
        {
            method:'POST',
            uriSuffix: '/twilio/searchPhoneNumbers',
            payload: params,
        });     
    } 
    public async addPhoneNumber(params: ApiType.addPhoneNumberOptions)
    {
        //return this.sendRequest<ApiType.SearchPhoneNumbers>(
        return this.sendRequest<ApiType.availableNumber[]>(
        {
            method:'POST',
            uriSuffix: '/twilio/selectPhoneNumber',
            payload: params,
        });     
    }
    public async releasePhoneNumber(params: ApiType.addPhoneNumberOptions)
    {
        //return this.sendRequest<ApiType.SearchPhoneNumbers>(
        return this.sendRequest<ApiType.availableNumber[]>(
        {
            method:'POST',
            uriSuffix: '/twilio/releasePhoneNumber',
            payload: params,
        });     
    }
    public async updatePhoneNumberLanguage(params:ApiType.AccessPhoneNumber)
    {
        return this.sendRequest<ApiType.AccessPhoneNumber>(
        {
            method:'POST',
            uriSuffix: '/settings/accessPhoneNumbers',
            payload: params,
        });     
    }
    public async getReportPreview(params:ApiType.ReportPreviewRequest)
    {
        return this.sendRequest<ApiType.ReportPreviewResponse>(
        {
            method:'POST',
            uriSuffix: '/reports/generatePreview',
            payload: params
        });
    }
    public async getReportHTMLPreview(params:ApiType.ReportPreviewRequest)
    {
        const formattedParams = {...params, exportType: 'html'};
        return this.sendRequest<ApiType.ReportPreviewResponse>(
        {
            method:'POST',
            uriSuffix: '/reports/generateReport',
            payload: formattedParams
        });
    }
    public async getReportDownload(params:ApiType.ReportPreviewRequest)
    {
        const formattedParams = {...params};
        return this.sendRequest<ApiType.ReportPreviewResponse>(
        {
            method:'POST',
            uriSuffix: '/reports/generateReport',
            payload: formattedParams
        });
    }
    public async getAllReportTemplates()
    {
        return this.sendRequest<ApiType.ReportTemplateQueryResponse>(
        {
            method:'POST',
            uriSuffix: '/reports/templates/query',
            payload: {templateName:undefined}
        });
    }
    public async deleteReportTemplate(tempateGuid:string)
    {
        return this.sendRequest<ApiType.ReportTemplateQueryResponse>(
        {
            method:'DELETE',
            uriSuffix: '/reports/template/'+tempateGuid,
            payload: {templateName:undefined}
        });
    }
    public async createReportTemplate(reportParams:ApiType.ReportParams)
    {
        return this.sendRequest<ApiType.ReportTemplateQueryResponse>(
        {
            method:'POST',
            uriSuffix: '/reports/template',
            payload: {...reportParams}
        });
    }
    public async updateReportTemplate(reportParams:ApiType.ReportParams, tempateGuid:string)
    {
        return this.sendRequest<ApiType.ReportTemplateQueryResponse>(
        {
            method:'PUT',
            uriSuffix: '/reports/template/'+tempateGuid,
            payload: {...reportParams}
        });
    }
////////////////////////////////////////////////////////////////////////////////////////
//  kiosk commands (maybe extend kiosk api instead of put here?)
////////////////////////////////////////////////////////////////////////////////////////

    public async checkKioskApproval(params: ApiType.KioskCheckApprovalRequest)
    {
        return this.sendRequest<ApiType.KioskApprovalResponse>(
        {
            method:'POST',
            uriSuffix: '/kiosks/checkApproval',
            payload: params,
        });
    }

    public async getKioskOrgcon(params:{modifiedSinceDate:string|null})
    {
        return this.sendRequest<ApiType.KioskDeltaOrgCon>(
        {
            method:'POST',
            uriSuffix: '/company/kioskOrgCon',
            payload: params,
        });
    }

    public async getKioskEmployeeStatus(params:ApiType.KioskEmployeesStatusRequest)
    {
        return this.sendRequest<ApiType.KioskEmployeesStatusResponse>(
        {
            method:'POST',
            uriSuffix: '/kiosks/employeeStatus',
            payload: params,
        });
    }
    public async getKioskTimeWorkedSummary(params:ApiType.KioskTimeWorkedRequest)
    {
        return this.sendRequest<ApiType.KioskTimeWorkedResponse>(
        {
            method:'POST',
            uriSuffix: '/kiosks/timeWorkedSummary',
            payload: params,
        });
    }
    public async getWorkerAppOrgCon(params:ApiType.GetWorkerAppDeltaOrgConRequestParams)
    {
        return this.sendRequest<ApiType.WorkerAppDeltaOrgCon>(
        {
            method:'POST',
            uriSuffix: '/company/workerAppOrgCon',
            payload: params,
        });
    }

    public async getEmployeeClockStatus(params:ApiType.EmployeesStatusRequest)
    {
        return this.sendRequest<ApiType.EmployeesStatusResponse>(
        {
            method:'POST',
            uriSuffix: '/employee/currentStatus',
            payload: params,
        });
    }
    public async createClockEventList(params:CreateClockEventListParams)
    {
        return this.sendRequest<void>(
            {
                method:'POST',
                uriSuffix: '/timesheet/createlist',
                payload: params
            });
    }

    public async queryTimeSheetEntries(params:ApiTypes.QueryTimeSheetEntriesRequest)
    {
        return this.sendRequest<ApiTypes.QueryTimeSheetEntriesResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/entries/query',
            payload: params
        });
    }
    public async getTimeSheetEntry(params:ApiTypes.GetTimeSheetEntryRequest)
    {
        return this.sendRequest<ApiTypes.GetTimeSheetEntryResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/entry/get',
            payload: params,
        });
    }

    public async createTimeSheetEntry(params:ApiTypes.CreateTimeSheetEntryRequest)
    {
        return this.sendRequest<ApiTypes.CreateTimeSheetEntryResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/entry/create',
            payload: params,
        });
    }
    public async updateTimeSheetEntry(params:ApiTypes.UpdateTimeSheetEntryRequest)
    {
        return this.sendRequest<ApiTypes.UpdateTimeSheetEntryResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/entry/update',
            payload: params,
        });
    }
    public async createUpdateTimeSheetEntries(params:ApiTypes.CreateUpdateTimeSheetEntriesRequest)
    {   
        return this.sendRequest<ApiTypes.CreateUpdateTimeSheetEntriesResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/entry/createUpdateList',
            payload: params,
        });
    }

    public async sendFeedback(params:{employeeGuid?: ApiGuid, message:string, logs?:any[]})
    {
        return this.sendRequest<void>(
        {
            method:'POST',
            uriSuffix: '/app/logs/upload',
            payload: params,
        });
    }


    public async syncKioskEvents(params:KioskEventSyncRequest, idempotencyOptions:IdempotencyOptions)
    {
        return this.sendRequest<KioskEventSyncResponse>(
        {
            method:'POST',
            uriSuffix: '/kiosks/eventSync',
            payload: params,
            idempotencyOptions
        });
    }
    public async syncWorkerAppEvents(params:ApiType.WorkerAppEventSyncRequest, idempotencyOptions:IdempotencyOptions)
    {
        return this.sendRequest<ApiType.WorkerAppEventSyncResponse>(
        {
            method:'POST',
            uriSuffix: '/workerApp/eventSync',
            payload: params,
            idempotencyOptions
        });
    }

    public async queryActivityView(params:ActivityQueryParams)
    {
        return this.sendRequest<ActivityQueryResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/query/activity',
            payload: params
        });
    }

    public async queryDetailedActivityView(params:DetailedActivityQueryParams)
    {
        return this.sendRequest<DetailedActivityResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/query/detailedActivity',
            payload: params
        });
    }

    public async queryHistory(params:{guid:ApiGuid})
    {
        return this.sendRequest<HistoryResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/query/history',
            payload: params
        });
    }

    public async clearTimesheetFlag(params:ClearTimesheetFlagParams)
    {
        return this.sendRequest<ClearTimesheetFlagResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/clearFlag',
            payload: params
        });
    }

    public async changeClockEventListStatus(params:ChangeClockEventListStatusParams)
    {
        return this.sendRequest<ChangeClockEventListStatusResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/list/status',
            payload: params
        });
    }

    public async deleteClockEventList(params:DeleteClockEventListParams)
    {
        return this.sendRequest<DeleteClockEventListResponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/list/delete',
            payload: params
        });
    }

    public async getClockAccessRules(type: 'ipAddress')
            : Promise<ApiResponse<GetClockAccessIpAddressRulesResponse>>;
    public async getClockAccessRules(type: 'callerID')
            : Promise<ApiResponse<GetClockAccessCallerIDRulesResponse>>;
    public async getClockAccessRules(type: unknown)
            : Promise<ApiResponse<GetClockAccessIpAddressRulesResponse |
                GetClockAccessCallerIDRulesResponse>>
     {
        return this.sendRequest<GetClockAccessIpAddressRulesResponse |
            GetClockAccessCallerIDRulesResponse>({
            method: 'GET',
            uriSuffix: `/settings/accessControl/${encodeURIComponent(type as string)}/`,
            payload: null
        })
    }

    public async postClockAccessRule(
        type: string,
        jobGuid: string | null | undefined,
        permission: 'allow' | 'deny' | null,
        flagOnly: boolean,
        property: string,
        value: string,
        notes: string
      ) {
        let uriSuffix = `/settings/accessControl/${encodeURIComponent(type)}`;

        if (jobGuid !== null && jobGuid !== undefined && jobGuid !== '') {
          uriSuffix += `/${encodeURIComponent(jobGuid)}`;
        } else {
          uriSuffix += '/global';
        }

        return this.sendRequest<unknown>({
          method: 'POST',
          uriSuffix,
          payload: {
            permission,
            flagOnly,
            ...(property === 'phoneNumber'
              ? { phoneNumber: value }
              : property === 'ipAddress'
              ? { ipAddress: value }
              : {}),
            notes
          }
        });
      }


    public async postClockAccessRuleNewJob(type: string,
        jobGuid: string, permission: 'allow' | 'deny' | null,
        flagOnly: boolean, property: string) {
        return this.postClockAccessRule(type,
            jobGuid, permission, flagOnly, property, '', '');
    }

    public async deleteClockAccessRule(type: string,
        identifier: string, jobGuid?: string)
    : Promise<ApiResponse<unknown>> {
        const payload: { [type: string]: string } = {};

        // Use * if they gave empty string, * deletes a job
        const key = identifier || '*';

        if (type === "ipAddress") {
            payload.ipAddress = key;
        } else if (type === "callerID") {
            payload.phoneNumber = key;
        }

        let jobParam = jobGuid || 'global';

        return this.sendRequest<unknown>({
            method: 'DELETE',
            uriSuffix: `/settings/accessControl` +
                `/${encodeURIComponent(type)}/${encodeURIComponent(jobParam)}`,
            payload,
        });
    }

    public async getGpsPoints(params:GetPointsRequest)
    {
        return this.sendRequest<GetPointsResponse>(
            {
                method:'POST',
                uriSuffix: '/gps/getPoints',
                payload: params
            });
    }

    public async queryActivityNotes(params:ApiType.ActivityNotesSearchQueryParams)
    {
        return this.sendRequest<ApiType.ActivityNotesQueryReponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/activityNotes/query',
            payload: params
        });
    }
    public async queryActivityNotesForClockEvent(params:ApiType.ActivityNotesForClockEventRequestParams)
    {
        return this.sendRequest<ApiType.ActivityNotesQueryReponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/activityNotes/clockEvent/query',
            payload: params
        });
    }
    
    public async deleteActivityNotes(params:ApiType.ActivityNotesDeleteRequestParams)
    {
        return this.sendRequest<ApiType.ActivityNotesDeleteReponse>(
        {
            method:'DELETE',
            uriSuffix: '/timesheet/activityNotes/delete',
            payload: params
        });
    }
    public async sendActivityNote(params:ApiType.ActivityNoteSendRequest)
    {
        return this.sendRequest<ApiType.ActivityNoteSendReponse>(
        {
            method:'POST',
            uriSuffix: '/timesheet/activityNotes/send',
            payload: params
        });
    }
    public async queryAppTelemetry(params:ApiType.QueryAppTelemetryRequest)
    {
        return this.sendRequest<ApiType.QueryAppTelemetryResponse>(
        {
            method:'POST',
            uriSuffix: '/app/queryTelemetry',
            payload: params
        });
    }

    public async getWorkerIdBadgeContext(params: ApiType.GetWorkerIdBadgeContextRequest)
    {
        return this.sendRequest<ApiType.GetWorkerIdBadgeContextResponse>(
        {
            method:'GET',
            uriSuffix: '/workerIdBadge/context',
            payload: params
        });
    }
    public async getBulkWorkerIdBadgeContextsForPrinting(params: ApiType.GetBulkWorkerIdBadgeContextsForPrintingRequest)
    {
        return this.sendRequest<ApiType.GetBulkWorkerIdBadgeContextsForPrintingResponse>(
        {
            method:'POST',
            uriSuffix: '/workerIdBadge/bulkContext',
            payload: params
        });
    }
    

    public async validateWorkerIdBadge(params:ApiType.ValidateWorkerIdBadgeRequest)
    {
        return this.sendRequest<ApiType.ValidateWorkerIdBadgeResponse>(
        {
            method:'POST',
            uriSuffix: '/workerIdBadge/validate',
            payload: params
        });
    }

    public async queryWorkerIdBadgeLogs(params:ApiType.QueryWorkerIdBadgeLogsRequest)
    {
        return this.sendRequest<ApiType.QueryWorkerIdBadgeLogsResponse>(
        {
            method:'POST',
            uriSuffix: '/workerIdBadge/logs/query',
            payload: params
        });
    }

    public async getSystemLogs<T=ApiType.SystemLogsResponse>(type: ApiType.SystemLogsReportType, fromDate: ApiType.DateString, toDate: ApiType.DateString):Promise<ApiResponse<T>>
    {
        return this.sendRequest<T>(
        {
            method:'GET',
            uriSuffix: '/customer/system/logs',
            payload: { type, fromDate, toDate }
        });
    }

    public async getSystemLog<T=ApiType.SystemLogReportPtoAccrualResponse>(type: 'PTOAccrual', guid: ApiGuid):Promise<ApiResponse<T>>;
    public async getSystemLog<T=ApiType.SystemLogReportPtoBalanceImportResponse>(type: 'PTOBalancesImport', guid: ApiGuid,):Promise<ApiResponse<T>>;
    public async getSystemLog<T=ApiType.SystemLogReportPtoAccrualResponse|ApiType.SystemLogReportPtoBalanceImportResponse>(type: ApiType.SystemLogsReportType, guid: ApiGuid):Promise<ApiResponse<T>>
    {
        return this.sendRequest<T>(
        {
            method:'GET',
            uriSuffix: '/customer/system/logs',
            payload: { type, guid}
        });
    }

    public async getDeviceDetails(params:ApiType.DeviceDetailsRequestParams)
    {
        return this.sendRequest<ApiType.DeviceDetailsResponse>(
        {
            method:'POST',
            uriSuffix: '/device/query',
            payload: params
        });
    }
    public async updateDeviceDetails(params:ApiType.UpdateDeviceDetailsRequestParams)
    {
        return this.sendRequest<ApiType.UpdateDeviceDetailsResponse>(
        {
            method:'POST',
            uriSuffix: '/device/update',
            payload: params
        });
    }
    
    public async getCompanyFiles(params:ApiType.ListCompanyFilesRequestParams)
    {
        return this.sendRequest<ApiType.ListCompanyFilesResponse>(
        {
            method:'POST',
            uriSuffix: '/companyFiles/list',
            payload: params
        });
    }

    public async commitCompanyFiles(params:ApiType.CommitCompanyFileRequestParams)
    {
        return this.sendRequest<ApiType.ListCompanyFilesResponse>(
        {
            method:'POST',
            uriSuffix: '/companyFiles/commit',
            payload: params
        });
    }

    //204 - no response
    public async deleteCompanyFiles(params:ApiType.DeleteCompanyFileRequestParams)
    {
        return this.sendRequest<void>(
        {
            method:'DELETE',
            uriSuffix: '/companyFiles/delete',
            payload: params
        });
    }
//companyFile/photoFacialRecognitionStatus
    public async changePhotoFacialRecognitionStatus(params:ApiType.ChangePhotoFacialRecognitionStatusRequest)
    {
        return this.sendRequest<ApiType.ChangePhotoFacialRecognitionStatusResponse>(
        {
            method:'POST',
            uriSuffix: '/companyFile/photoFacialRecognitionStatus',
            payload: params
        });
    }

    //work in progress - xhr or other that gives progress would be nice - fetch DOES have download progress tracking!
    // helper to LOAD an image into a dataUrl
    //  can get proper error responses
    // public async loadRemoveImageIntoDataUrl(url:string)
    // {
    //     const res = await fetch(url);
    //     const blob = await res.blob();
    //     const imageDataUrl = URL.createObjectURL(blob);
    //     return imageDataUrl;
    // }

    public async getPresignedCustomerFileUploadUrl(params:ApiType.CompanyFileForCreate)
    {
        return this.sendRequest<ApiType.CompanyFileCreateRespone>(
        {
            method:'POST',
            uriSuffix: '/companyFiles/getUploadUrl',
            payload: params
        });
    }

    async _uploadClockPhotoUnsafe( formData:FormData )
    {
        // const formData = new FormData();
        // const blob = new Blob([fileContents], { type: mimeType });
        // formData.append('file', blob, fileName);

        return this.sendRequest<PtoBalanceImportResponse>(
            {
                method: 'POST',
                uriSuffix: '/files/clockevent/upload',
                formDataForFilePost:formData,
                payload: null
            });
    }

    async createCompanyAccount(params:ApiType.CreateCompanyRequestParams, idempotencyOptions:IdempotencyOptions)
    {
        return this.sendRequest<ApiType.CreateCompanyResponse>(
        {
            method:'POST',
            uriSuffix: '/company/create',
            payload: params,
            idempotencyOptions
        });
    }
    async createCompanyAccountSSO(params:ApiType.CreateCompanyRequestParams, idempotencyOptions:IdempotencyOptions)
    {
        return this.sendRequest<ApiType.CreateCompanyResponse>(
        {
            method:'POST',
            uriSuffix: '/company/create/sso',
            payload: params,
            idempotencyOptions
        });
    }
    async getAccountCreationSetupInfo(params: QueryIntuitSsoSetupSession )
    {
        return this.sendRequest<IntuitSsoSetupSession>(
        {
            method:'GET',
            uriSuffix: '/intuit/sso/setupSession',
            payload: params
        });
    }
    

    public getTermsOfService()
    {
        return this.sendRequest<ApiType.GetTermsOfServiceResponse>(
        {
            method:'GET',
            uriSuffix: '/termsOfService',
            payload: null
        });
    }

    // private _ws:WebSocket|null = null;
    // public async websocketize()
    // :Promise<void>
    // // :Promise<{
    // //     sender: (msg:string) => void
    // // }>
    // {
    //     const socketUrl = 'wss://starks.l.vericlock.dev/api/1.0/socketCity';
    //     return new Promise((resolve,reject) => {
    //         const w =  new WebSocket(socketUrl);
    //         this._ws = w;

    //         w.onerror = (ev:Event) => {
    //             const msg = '[From client.ts] Socket Error: ' + ev;
    //             console.warn(msg);
    //             this._wsSubs.forEach(cb => cb(msg))
    //         }
    //         w.onopen = (ev) => {
    //             const msg = '[From client.ts] WS Opened';
    //             this._wsSubs.forEach(cb => cb(msg))
    //         };
    //         w.onmessage = (ev:MessageEvent) => {
    //             const msg = 'WS: Msg: ' + ev.data
    //             console.log(msg);
    //             this._wsSubs.forEach(cb => cb(ev.data))
    //         }
    //         w.onclose = (ev) => {
    //             const msg = '[From client.ts] Closed';
    //             console.log(msg);
    //             this._wsSubs.forEach(cb => cb(msg))
    //         }
    //         resolve();
    //     });
    // }

    // private _wsSubs:SubCb[] = [];
    // public sub(cb:(msg:string)=>void):() => void
    // {
    //     if(!this._ws)
    //     {
    //         setTimeout(async () => {
    //             await this.websocketize()
    //         },0);
    //     }

    //     this._wsSubs.push(cb);
    //     return () => { //return the unsub func
    //         let idx = this._wsSubs.indexOf(cb);
    //         if(idx != -1)
    //             this._wsSubs.splice(idx, 1); //remove callback
    //     }
    // }

    // public sendMsg(msg:string)
    // {
    //     if(this._ws)
    //         this._ws.send(msg);
    // }
}


// type SubCb = (msg:string) => void;


/////////browser client creation with website specific plugin - move
/////////browser client creation with website specific plugin - move

//todo - move this to main project
//dedupe/change the plugin
//duped in v.ts

/////////browser client creation with website specific plugin - move
/////////browser client creation with website specific plugin - move

async function sleepAsync(ms:number):Promise<void>
{
    return new Promise( (resolve) => {
        setTimeout(resolve, ms);
    });
}

