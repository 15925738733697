export type DayOfWeek = 'sun'|'mon'|'tue'|'wed'|'thu'|'fri'|'sat';
export type TimezoneString = string;
export const DaysOfWeek:DayOfWeek[] = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export type PayrollPeriod = 'none'|'weekly'|'biweekly'|'semimonthly'|'4weeks'|'monthly';
export type TimeOfDay = string; //HH:MM  /\D?\D:\D\D/  - allowing 6:00 or 06:00 - not sure if we can create a better regex, or even bother and instead just parse
export type DateString = string; //YYYY-MM-DD
export type TimeOfDayRange = [TimeOfDay, TimeOfDay]; // [HH:MM,HH:MM] - 24hr clock, can overlap and treated as next day same shift

export type PayrollSettings = PayrollSettingsNone | PayrollSettingsWeekAligned | PayrollSettingsSemiMonthly | PayrollSettingsMonthly;
export type PayrollSettingsBase = 
{
    payrollPeriod: PayrollPeriod
    weekStart: DayOfWeek, //day of week number 0 - 6
    recentPayrollDate: string //YYYY-MM-DD format
    semiMonthlyFirstHalf: number
    semiMonthlyLastHalf: number
    monthlyDay:number
}

//soon this won't be possible (old accounts will be dead that have it)
export interface PayrollSettingsNone extends Omit<PayrollSettingsBase,'recentPayrollDate'|'semiMonthlyFirstHalf'|'semiMonthlyLastHalf'|'monthlyDay'>
{
    payrollPeriod: 'none'
}

interface PayrollSettingsWeekAligned extends Omit<PayrollSettingsBase, 'semiMonthlyFirstHalf'|'semiMonthlyLastHalf'|'monthlyDay'>
{
    payrollPeriod: 'weekly'|'biweekly'|'4weeks',
    // weekStart: DayOfWeek, //day of week number 0 - 6 //not needed as it is part of the base
    recentPayrollDate: string //YYYY-MM-DD format
}

export interface PayrollSettingsSemiMonthly extends Omit<PayrollSettingsBase,'recentPayrollDate'|'monthlyDay'>
{
    payrollPeriod: 'semimonthly',
}
export interface PayrollSettingsMonthly extends Omit<PayrollSettingsBase,'recentPayrollDate'|'semiMonthlyFirstHalf'|'semiMonthlyLastHalf'>
{
    payrollPeriod: 'monthly',
}
export const DayOfWeekToMomentNumericDay:{ [key:string]: number } =
{
    sun: 0,
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6
};
export function getNumericDayOfWeek(d:DayOfWeek): number
{
    return DayOfWeekToMomentNumericDay[d]; 
}
export function getDayOfWeekString(day:number): DayOfWeek
{
    return DaysOfWeek[day];
}

export function getDayOfWeekFullString(day:number): string
{
    const dayStrings = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return dayStrings[day];
}
export function getDayOfWeekFullStringFromAbbreviation(day:DayOfWeek): string
{
    return getDayOfWeekFullString(getNumericDayOfWeek(day));
}