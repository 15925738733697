
import { DayOfWeek, TimezoneString } from './Datetime';
import { ApiGuid } from './ApiGuid';
import { PtoRecord } from './Pto';
import { DeepPartial } from './TypescriptHelpers';
import { AuditTrailUniqueArrayInObjectForm, AuditTrailUniqueArrayOfObjects } from './AuditTrail';
import { AlertBase, CommonAuditAlert } from './Alerts';

// export type ScheduledShiftRepeatFrequency = 'daily'|'weekly'|'monthly'|'yearly';

const ScheduledShiftRepeatFrequencyHack = ['daily','weekly','monthly','yearly'] as const;
type ScheduledShiftRepeatFrequencyTuple = typeof ScheduledShiftRepeatFrequencyHack; //'onScheduleChange'|'beforeShift'|'noShow';
export type ScheduledShiftRepeatFrequency = ScheduledShiftRepeatFrequencyTuple[number];
export const ScheduledShiftRepeatFrequencyList = ScheduledShiftRepeatFrequencyHack.concat(); //make the useful list for yup's oneOf


export type SchedulingRepeat = SchedulingRepeatDaily|SchedulingRepeatWeekly|SchedulingRepeatMonthly|SchedulingRepeatYearly;

type SchedulingRepeatCommon = {
    freq: ScheduledShiftRepeatFrequency
    interval: number
    until?: string //YYYY-MM-DD iso date
    count?: number,
    excludeDates?: string[] //(YYYY-MM-DD)[]
}
export type SchedulingRepeatDaily = SchedulingRepeatCommon & {
    freq: 'daily'
}
export type SchedulingRepeatWeekly = SchedulingRepeatCommon & {
    freq: 'weekly',
    weekdays: DayOfWeek[]
}
export type SchedulingRepeatMonthly = SchedulingRepeatMonthlyByDayOfWeek|SchedulingRepeatMonthlyByDayOfMonth;

export type SchedulingRepeatMonthlyByDayOfWeek = SchedulingRepeatCommon & {
    freq: 'monthly',
    weekday: DayOfWeek,
    n: number,              //1,2,3,4th of the month, or -1 last of the month
}
export type SchedulingRepeatMonthlyByDayOfMonth = SchedulingRepeatCommon & {
    freq: 'monthly',
    bymonthday: number,
}
export type SchedulingRepeatYearly = SchedulingRepeatCommon & {
    freq: 'yearly',
}
export function isDailyRRule(r:SchedulingRepeat):r is SchedulingRepeatDaily
{
    return r.freq === 'daily';
}
export function isWeeklyRRule(r:SchedulingRepeat):r is SchedulingRepeatWeekly
{
    return r.freq === 'weekly';
}
export function isMonthlyRRule(r:SchedulingRepeat):r is SchedulingRepeatMonthly
{
    return r.freq === 'monthly';
}
export function isMonthlyRRuleByWeekday(r:SchedulingRepeat):r is SchedulingRepeatMonthlyByDayOfWeek
{
    return r.freq === 'monthly' && (r as SchedulingRepeatMonthlyByDayOfWeek).weekday !== undefined;
}
export function isMonthlyRRuleByMonthDay(r:SchedulingRepeat):r is SchedulingRepeatMonthlyByDayOfMonth
{
    return r.freq === 'monthly' && (r as SchedulingRepeatMonthlyByDayOfMonth).bymonthday !== undefined;
}
export function isYearlyRRule(r:SchedulingRepeat):r is SchedulingRepeatYearly
{
    return r.freq === 'yearly';
}

// export interface ScheduledShiftRepeatLogic
// {
//     freq: ScheduledShiftRepeatFrequency
//     // interval: number
//     until?: Date|null 
//     count?: number,

//     daysOfWeek?: DayOfWeek[]|null,
//     // d: { dayOfWeek: DayOfWeek, nth: -1|1|2|3|4 }
// } 

export enum ShiftConflictResolution {
    Ignore='ignore',    //conflicts will be reported, but shifts will still be created
    NoCreate='noCreate'       //conflicts will result no shifts being created
    //Replace='replace' //future - could replace conflicting events (deleting the existing event(s))
}
export enum RepeatEditType 
{
    AllShifts='allShifts',          //all events in series
    ThisShift='thisShift', //just one event instance (requires mechanism to identify the instance - date on which it recurs)
    ThisAndAllFollowingShifts='thisAndAllFollowingShifts', //rquires mechanism to identify the this shift
}
export interface ShiftAssignment
{
    employeeGuids: ApiGuid[]
    groupGuids: ApiGuid[]
}

export interface JobAssignment
{
    jobGuids: ApiGuid[]     //specific list of jobs
    // allJobs?: boolean        //all jobs apply
    // eachJob?: boolean        //each and every job will apply individually - kind of same as all? just depends on the definition
    // anyJob?: boolean         //any job will apply
    // noneJob?: boolean        //specifically when NON is selected
}
export interface ServiceItemAssignment
{
    serviceItemGuids: ApiGuid[],
    // allServiceItems: boolean
}

export type ApiScheduledShift =
{
    guid: ApiGuid,
    templateGuid?: ApiGuid|null,
    readonly redacted?:boolean, //indicates that the job was redacte when read out - employee viewing does not have access to the job on this shift
    title: string,
    description: string,
    color: string,     
    start: string,                  //ISO format datetime
    end: string,         
    durationMinutes: number,
    timezone: TimezoneString,
    autoClockOut: boolean,
    allDayShift: boolean,
    published: boolean,
    updateDate: string,
    alerts?: (SchedulingAlertBeforeShift|SchedulingAlertNoShow)[] 
    jobAssignment?: JobAssignment
    serviceItemAssignment?: ServiceItemAssignment
        
    assignment: ShiftAssignment //require explicity non-assignment
    repeat: SchedulingRepeat|null,
}
//instanced shift from a repeater with start / end set for the instance, and an instanceId which is the guid + instance date
export type ApiScheduledShiftInstance = ApiScheduledShift & {
    instanceId: string, //{shift.guid}_{instance date YYYY-MM-DD} or just shift.guid
    originalShift: ApiScheduledShift, //the shift with the original start date
}


export type AuditSchedulingAlert = Omit<SchedulingAlert,'who'|'how'|'ccUsers'> & CommonAuditAlert;
export type AuditSchedulingAlertBeforeShift = Omit<SchedulingAlertBeforeShift,'who'|'how'|'ccUsers'> & CommonAuditAlert;
export type AuditSchedulingAlertNoShow = Omit<SchedulingAlertNoShow,'who'|'how'|'ccUsers'> & CommonAuditAlert;
export type AuditSchedulingAlertEndOfShift = Omit<SchedulingAlertEndOfShift,'who'|'how'|'ccUsers'> & CommonAuditAlert;

export type AuditScheduleSettings = Omit<SchedulingSettings,
    'updateDate'|'updateEmployeeGuid'|'syncId'|'alerts'> &
{
    alerts?: AuditTrailUniqueArrayOfObjects<AuditSchedulingAlert>
}

export type ApiScheduledShiftForCreate = Omit<ApiScheduledShift,'end'|'guid'|'updateDate'>; //create doesn't use some params
export type ApiScheduledShiftForEdit = Omit<ApiScheduledShift,'end'>;    
export type ApiScheduledShiftForForm = DeepPartial<ApiScheduledShiftForCreate|ApiScheduledShiftForEdit> & Pick<ApiScheduledShiftForEdit,'repeat'>

export type AuditApiScheduledShift = DeepPartial<Omit<ApiScheduledShift, 'autoClockOut'|'assignment'|'jobAssignment'|'serviceItemAssignment'|'repeat'|'updateDate'|'allDayShift'|'alerts'> & {
    assignment?: {
        employeeGuids?: AuditTrailUniqueArrayInObjectForm, 
        groupGuids?: AuditTrailUniqueArrayInObjectForm, 
    },
    jobAssignment?: {
        jobGuids?: AuditTrailUniqueArrayInObjectForm, 
    },
    serviceItemAssignment?: {
        serviceItemGuids?: AuditTrailUniqueArrayInObjectForm
    }
    alerts?: AuditTrailUniqueArrayOfObjects<AuditSchedulingAlert>
}>;

export const MaxScheduledShiftDescriptionLength = 1000;
export const MaxSchedulingTemplateNameLength = 100;
export function isExistingScheduledShift(shift:any):shift is ApiScheduledShift
{
    return(!!shift.guid); //has a guid
}
export interface ApiParamsCreateShifts
{
    shifts: ApiScheduledShiftForCreate[]
    conflictResolution: ShiftConflictResolution,
    dryRun?: boolean,   //do not do the create, just conflict detection
    // exaNDGROUP
    // EXPAND EMPLOEES INDIVIDUAL SVGElementInstanceList
    // EXPAND GROUP INSTANNACEAS

    // 3 CASES
}
// console.log('Do expand flags abovr!');
export interface ApiParamsEditShifts
{
    shifts: ApiScheduledShiftForEdit[], //edit doesn't use some params
    conflictResolution: ShiftConflictResolution,
    createShifts?: ApiScheduledShiftForCreate[] //used for creating shifts atomically alongs side edit - primary use case is editing recurring events and adding independent events
}
export function isTemplateEditParams(editParams:any):editParams is ApiParamsEditTemplateShifts
{
    return typeof(editParams.conflictResolution) === 'undefined';
}
export interface ApiCreateShiftsResponse
{
    shifts: ApiScheduledShift[],
    conflicts: ShiftConflict[]
}
//edit response is the same as create - if different eventually, make it different here
export type ApiEditShiftsResponse = ApiCreateShiftsResponse & {
    // removedShifts: ApiScheduledShift[], //edits can result in shifts being removd ultimately
    deletedShifts: ApiScheduledShift[],
    createdShifts: ApiScheduledShift[],
};
export function isApiCreateShiftsResponse(resp:any):resp is ApiCreateShiftsResponse
{
    return typeof(resp.conflicts) !== 'undefined';
}
export function isApiEdShiftsResponse(resp:any):resp is ApiCreateShiftsResponse
{
    return typeof(resp.conflicts) !== 'undefined';
}
export type ApiParamsShiftQuery =
{
    searchPeriod: {
        start: Date   //passed in as utc datetime in a parsable format. queried as >=
        end: Date     //queried as <
    },
    employeeGuids?: ApiGuid[],
    groupGuids?: ApiGuid[],
    jobGuids?: ApiGuid[],
    serviceItemGuids?:ApiGuid[],
    teamView?: boolean              //view entire teams schedule, requires feature/permission enabled
    type?: 'all'|'published'|'unpublished'
}

export type ApiParamsShiftQueryByGuids =
{
    shiftGuids:ApiGuid[]
}
export type ApiParamsShiftQueryComposite = ApiParamsShiftQuery|ApiParamsShiftQueryByGuids;
export function isShiftQueryByGuid(params:ApiParamsShiftQueryComposite):params is ApiParamsShiftQueryByGuids
{
    return typeof((params as ApiParamsShiftQueryByGuids).shiftGuids) != "undefined";
}
//delete shift is an edit shift requiring 'guid' and 'updateDate'
//  requiring 'start' when dealing with a repeat shift/of a type other than all
// export type LimitedDeleteShift = Pick<ApiScheduledShift,'guid'|'updateDate'> | Pick<Partial<ApiScheduledShift>,'start'>;
export type LimitedDeleteShift =
{
    instanceId: string, //ApiGuid | ApiGuid_YYYY-MM-DD (for original repeating events)
    guid: ApiGuid,
    updateDate: string,
}
export type ShiftEditOutOfSync = 
{
    guid: ApiGuid,          //shift guid
    updateDate: string,       //date sent in
    latestUpdateDate: string  //date of last update according to server
}|
{
    guid: ApiGuid,          //shift guid
    updateDate: string,       //date sent in
    notFound: true
};

export type ApiParamsDeleteShifts =
{
    repeatEditType?: RepeatEditType, //defaults to all
    shifts: LimitedDeleteShift[] //can send a full shift, we will ignore everything but the guid + if needed ,start date
}
export type ApiDeleteShiftsResponse = {
    updatedShifts?: {
        editedShifts: ApiScheduledShift[], //api assists with recurring logic, so a delete of a specific shift, can result in an edit of that shift in actuality. if shift is missing from this in the response, it was actually removed, vs just edited with an exclusion date
        conflicts: ShiftConflict[]
    }
}

// export interface ApiDeleteShiftResponse
// {
//     result: 'success'|'outOfSync'
//     outOfSyncShifts?:ShiftEditOutOfSync
// }

export type ShiftConflict =
{
    guid?: ApiGuid, //if present, it is an ACTUAL shift conflict record (absent, it would be possible conflict seen during create/edit)
    shift: ApiScheduledShiftForCreate | ApiScheduledShift, //shift being created (todo: or edited)
    conflictingShift?: ApiScheduledShiftForCreate | ApiScheduledShift | null, //either existing shift, or one being created as well
    conflictingPto?: PtoRecord | null,
    dismissedDate?: Date|null,              //missing for non-db entries, null when in db but not dimissed yet
    dismissedByEmployeeGuid?:ApiGuid|null   //missing for non-db entries, null when in db but not dimissed yet
}

//either searching a range, or specify specific shift guids to query for conflicts in the conflict table
export type ApiParamsScheduleConflictQuery = ApiParamsShiftQuery & {
    includeDismissed?: boolean //default will be false
};
export type ApiParamsScheduleConflictDismissQuery =
{
    shiftConflictGuids: ApiGuid[]
}
export type ApiErrorEditShiftOutOfSync =
{
    code: 'ShiftOutOfSync',
    outOfSyncShifts: ShiftEditOutOfSync[]
}

export function isShiftOutOfSyncError(err:any):err is ApiErrorEditShiftOutOfSync
{
    return(err.code ===  'ShiftOutOfSync' && typeof(err.outOfSyncShifts) != 'undefined');
}

export type SchedulingPermissionError =
{
    code: 'SchedulingPermissionError',
    message: string,
    extraData?: {
        groupGuids?:ApiGuid[] //groupGuids relevent to the error
        employeeGuids?:ApiGuid[]//employeeGuids relevent to the permission error
    }
}
export function isSchedulingPermissionError(err:any):err is SchedulingPermissionError
{
    return(err.code === 'SchedulingPermissionError');
}

export type ApiParamsScheduleTemplateQuery =
{
    //are there any params to query?  Possibly restricting who 'owns/can view' a template
    scheduleTemplateGuids?:ApiGuid[] //list of specific templates to query
    includeShifts:boolean, //if true, the full shift lists are returned - otherwise, its just the meta data about the templates
}
export type ApiParamsDeleteScheduleTemplate = //will ignore if the items are missing, error if there is a permission problem in the list (will delete atomically)
{
    scheduleTemplates:ApiSchedulTemplateForDelete[] //list of specific templates to delete
}

//this makes templateGuid REQUIRED, but is optional in the regular ApiScheduledShift
type ApiScheduleTemplateShiftExtra = {
    templateGuid: ApiGuid
}
export type ApiScheduledTemplateShiftForCreate = ApiScheduledShiftForCreate;// & ApiScheduleTempalteShiftExtra;
export type ApiScheduledTemplateShiftForEdit = ApiScheduledShiftForEdit & ApiScheduleTemplateShiftExtra;
export type ApiScheduledTemplateShift = ApiScheduledShift & ApiScheduleTemplateShiftExtra;

export function isScheduleTemplateShiftForEdit(shift:any):shift is ApiScheduledTemplateShiftForEdit
{
    return(typeof(shift.templateGuid) !== 'undefined');
}

//IS_CREATE - replaces shift list with normal shifts... as they do not contain templateGuid
export type ScheduleTemplate<IS_CREATE extends boolean=false> =
{
    guid:ApiGuid
    name:string,
    description?:string,
    createDate: string,
    updateDate: string
    createEmployeeGuid: ApiGuid,
    updateEmployeeGuid: ApiGuid,
    shifts?: IS_CREATE extends true ? ApiScheduledTemplateShiftForCreate[] : ApiScheduledTemplateShift[]   //use normal shifts (see type) - exact dates are ignored, and the day of week is extracted
                                    //Gotcha - shifts from a DST week could come back with DST affected durations
                                    //rendering UI should be aware
}
export type ScheduleTemplateForCreate = Omit<ScheduleTemplate<true>,'guid'|'createDate'|'updateDate'|'createEmployeeGuid'|'updateEmployeeGuid'>;
export type ScheduleTemplateForEdit = Omit<ScheduleTemplate,'createDate'|'createEmployeeGuid'|'updateEmployeeGuid'|'shifts'>; //not shifts on edit at the moment
export type ApiSchedulTemplateForDelete = { //meant to take a full template object, and the rest be ignored - api layer could optionally filter based in reflection
    guid:ApiGuid,
    updateDate: string  
};
export type AuditScheduleTemplate = DeepPartial<Omit<ScheduleTemplate<false>, 'guid'|'createDate'|'updateDate'|'createEmployeeGuid'|'updateEmployeeGuid'|'shifts'>>
export interface LimitedDeleteTemplateShift
{
    guid: ApiGuid,
    templateGuid:ApiGuid,
    updateDate: string,
}
//roughly the same as non-tempkate shift create/edit - but 
export type ApiParamsCreateTemplateShifts =
{
    templateGuid:ApiGuid,       //can only create shifts for a single template in one request
    shifts: ApiScheduledTemplateShiftForCreate[]
}
// console.log('Do expand flags abovr!');
export type ApiParamsEditTemplateShifts =
{
    shifts: ApiScheduledTemplateShiftForEdit[]
}
export type ApiCreateTemplateShiftsResponse =
{
    shifts: ApiScheduledTemplateShift[]
}
export type ApiParamsDeleteTemplateShifts =
{
    shifts: LimitedDeleteTemplateShift[] //can send a full shift, we will ignore everything but the guid + if needed ,start date
}
//edit response is the same as create - if different eventually, make it different here
export type ApiEditTemplateShiftsResponse = ApiCreateTemplateShiftsResponse;

// export function isScheduledTemplateShift(s:ApiScheduledTemplateShift|)

//copy settings object that is global, and use same code/stuff in per-shift 
// export type AlertSendInfo = {
//     // admins: boolean,    //all admins
//     // managers: boolean,  //relevent managers
//     // users: boolean,     //relevent users
//     // employeeGuids: ApiGuid[],

//     // sms: AlertSendInfo
//     // email: AlertSendInfo
//     // push: AlertSendInfo

//     // exclude?: Omit<AlertSendInfo, "exclude">
// }

// export function getAlertsThatAreOn(sendInfo: AlertSendInfo)
// {
//     const sendToManagers = alertIsOn(sendInfo.managers);
//     const sendToAdmins = alertIsOn(sendInfo.admins);
//     const sendToAffectedEmployees = alertIsOn(sendInfo.affectedUsers);
//     let sendToRandomEmployees = false;
//     if(sendInfo.users.length > 0)
//     {
//         for(let i=0; i < sendInfo.users.length; i++)
//         {
//             if(alertIsOn(sendInfo.users[i].alerts))
//             {
//                 sendToRandomEmployees = true;
//                 break;
//             }
//         }
//     } 

//     return { sendToManagers, sendToAdmins, sendToAffectedEmployees, sendToRandomEmployees };
// }

// export type AlertInfo = {
//     enabled: boolean,
//     note: string,
//     sendTo: AlertSendInfo
// }
// export type AlertBeforeShift = {
//     minBefore: number
//     alertInfo: AlertInfo
// }
// export type AlertNoShow = {
//     minAfter: number
//     alertInfo: AlertInfo
// }

export const SchedulingAlertTypeListHack = ['onScheduleChange','beforeShift','noShow','endOfShift'] as const;
type SchedulingAlertTuple = typeof SchedulingAlertTypeListHack; 
export type SchedulingAlertType = SchedulingAlertTuple[number];
export const SchedulingAlertTypeList = SchedulingAlertTypeListHack.concat(); //make the useful list for yup's oneOf

export type SchedulingAlertBase = AlertBase<SchedulingAlertType>;

export type SchedulingAlertOnScheduleChange = SchedulingAlertBase &
{
    type: 'onScheduleChange'
}
export type SchedulingAlertBeforeShift = SchedulingAlertBase &
{
    type: 'beforeShift',
    minBefore: number
}

export const SchedulingAlertNoShowTypeListHack = ['startOfShift','duringShift'] as const;
type SchedulingAlertNoShowTuple = typeof SchedulingAlertNoShowTypeListHack; //'onScheduleChange'|'beforeShift'|'noShow';
export type SchedulingAlertNoShowType = SchedulingAlertNoShowTuple[number];
export const SchedulingAlertNoShowTypeList = SchedulingAlertNoShowTypeListHack.concat(); //make the useful list for yup's oneOf

export type SchedulingAlertExtraSettingsNoShow = {
    noShowType: SchedulingAlertNoShowType
    clockInCountNeeded: 0|1 //1 == at least 1, 0== all, -1 maybe all but 1? <- retain flexibility to check for other values
}
export type SchedulingAlertNoShow = SchedulingAlertBase &
{
    type: 'noShow',
    minAfter: number //could expand this...or just ignore // not used for during types
} & SchedulingAlertExtraSettingsNoShow;

export type SchedulingAlertExtraSettingsEndOfShift = {
    autoClockOut: boolean,
    timeguard: boolean
}
export type SchedulingAlertEndOfShift = SchedulingAlertBase &
{
    type: 'endOfShift',
    minAfter: number,
} & SchedulingAlertExtraSettingsEndOfShift;


export type SchedulingAlert = SchedulingAlertOnScheduleChange|SchedulingAlertBeforeShift|SchedulingAlertNoShow|SchedulingAlertEndOfShift;

export function isBeforeShiftAlert(alert:SchedulingAlert):alert is SchedulingAlertBeforeShift
{
    return alert.type === 'beforeShift';
}
export function isNoShowAlert(alert:SchedulingAlert):alert is SchedulingAlertNoShow
{
    return alert.type === 'noShow';
}
export function isEndOfShiftAlert(alert:SchedulingAlert):alert is SchedulingAlertEndOfShift
{
    return alert.type === 'endOfShift';
}

export function isAlertForScheduledShift(alert:SchedulingAlert):alert is NonNullable<ApiScheduledShift['alerts']>[number]
{
    if(alert.type === 'beforeShift' || alert.type === 'noShow' || alert.type === 'endOfShift')
        return true;
    return false;
}

export function isNoShowStartOfShiftAlert(alert:SchedulingAlert):boolean
{
    if(isNoShowAlert(alert))
    {
        return alert.noShowType === 'startOfShift';
    }
    return false;
}
export function isNoShowDuringShiftAlert(alert:SchedulingAlert)
{
    if(isNoShowAlert(alert))
    {
        return alert.noShowType === 'duringShift';
    }
    return false;
}
export type SchedulingSettings =
{
    enabled: boolean,
    teamView: 'disabled'|'all'|'group',
    alerts:SchedulingAlert[]
    // _old_alerts: {
    //     onScheduleChange: SchedulingAlertOnScheduleChange,
    //     // beforeShift: AlertWrap<SchedulingAlertBeforeShift>[]
    //     // noShow: AlertWrap<SchedulingAlertNoShow>[]
    //     beforeShift: SchedulingAlertBeforeShift[]
    //     noShow: SchedulingAlertNoShow[]
    // }
    defaultShiftStartTime?: string,
    defaultShiftEndTime?: string,
    syncId: number,
    updateDate: string,
    updateEmployeeGuid: ApiGuid|null
}
export type SchedulingSettingsForUpdate = Omit<SchedulingSettings,'updateDate'>;

export const defaultShiftColors = [
    'E70000', 'FD0061', '733DBE', '795BFF',
    'FF4500', 'FFB000', '00D1F7', '2A00FF',
    'C9DE00','41D100', '009988', '59818D'
]
export function isDefaultShiftColor(color:string)
{
    return defaultShiftColors.indexOf(color.toUpperCase()) >= 0;
}
export enum ColorPaletteKey {
    SchedulingShifts='SchedulingShifts'
}
export const ColorPaletteKeyList:string[] = ['SchedulingShifts'];

export type CustomColorPaletteQueryParams = {
    paletteKey: ColorPaletteKey
}
export type CustomColorPaletteResponse = {
    colors: string[]
}
export type ColorPickerLegendEntry = {
    color: string,
    name: string
}
export type ColorPickerCustomization = {
    legend: ColorPickerLegendEntry[]
}



export type SchedulingRoundingBlockingRuleAssignment = {
    employeeGuid: ApiGuid|'any'
}

// Rounding / Blocking Rules

export type RoundingBlockingRule = {
    guid: ApiGuid,
    name: string,
}
export type RoundingBlockingRuleForCreate = Omit<RoundingBlockingRule,'guid'>;
export type RoundingBlockingRuleForUpdate = RoundingBlockingRule;

export type RoundingBlockingRuleAssignment = {
    employee: 'any'|ApiGuid,
    group : 'any'|ApiGuid,
    job: 'any'|ApiGuid,
    serviceItem: 'any'|ApiGuid,

    moppleburger: string,
    createDate: string|null
}
export type RoundingBlockingRuleAssignmentForCreate = Omit<RoundingBlockingRuleAssignment,'createDate'>;

export type QueryRoundingBlockingRuleAssignmentsResponse = {
    rules: RoundingBlockingRuleAssignment[]
}

export type CreateRoundingBlockingRuleAssignmentResponse = {
    rules: RoundingBlockingRuleAssignment[]
}