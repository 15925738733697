export type ApiGuid = string;

const guidRegex = /^[a-f0-9A-F]{8}(?:-?[a-f0-9A-F]{4}){3}-?[a-f0-9A-F]{12}$/;
export function isApiGuid(str:any):str is ApiGuid
{
    return(typeof(str) === 'string' && guidRegex.test(str));
}

export function hexStringToApiGuid(hexStr:string):ApiGuid
{
    if(hexStr.length != 32)
        throw new Error('Expecting 32 hex characters');

    return hexStr.slice(0,8) + '-' 
        + hexStr.slice(8,12) + '-'
        + hexStr.slice(12,16) + '-'
        + hexStr.slice(16,20) + '-'
        + hexStr.slice(20,32);
}

export function apiGuidToHexString(g:ApiGuid):string
{
    let out = g.replace(/-/g,'');
    return out;
}

//browser compatible function to convert a guid to a Uint8Array of the hex values
export function ApiGuidToUInt8Array(g:ApiGuid):Uint8Array
{
    let hexStr = apiGuidToHexString(g);
    let out = new Uint8Array(16);
    for(let i = 0; i < 16; i++)
    {
        out[i] = parseInt(hexStr.slice(i*2,i*2+2),16);
    }
    return out;
}

export function ApiGuidFromUInt8Array(g:Uint8Array):ApiGuid
{
    let hexStr = '';
    for(let i = 0; i < 16; i++)
    {
        hexStr += g[i].toString(16).padStart(2,'0');
    }
    return hexStringToApiGuid(hexStr);
}