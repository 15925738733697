// eslint-disable-next-line @typescript-eslint/ban-types
export type DeepPartial<T> = T extends Function ? T : (T extends object ? { [P in keyof T]?: DeepPartial<T[P]>; } : T);
// export type DeepPartial<T> = T extends Function ? T : 
//     T extends any[] ? T : (T extends object ? { [P in keyof T]?: DeepPartial<T[P]>; } : T);
// export type DeepPartial<T> = {
//     [P in keyof T]?: DeepPartial<T[P]>;
// };

//turn all types into string, string[], { [key]: string }
export type DeepString<T> = DeepTypeReplace<T,string>
    
 
export type DeepUnknown<T> = DeepTypeReplace<T,unknown>
type DeepTypeReplace<T,NEW_TYPE> = T extends (string|number|null|undefined) ? NEW_TYPE : 
// eslint-disable-next-line @typescript-eslint/ban-types
        T extends object ? { [P in keyof T]: DeepTypeReplace<T[P],NEW_TYPE> }: never;


export function arrayFilterNotEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    if (value === null || value === undefined) return false;
        return true;
}      
export function arrayFilterNotNull<TValue>(value: TValue | null ): value is TValue {
    if (value === null ) return false;
        return true;
}      
       
//adds null to the allowed types of a complex object's leaf nodes only (that aren't objects themselves)
export type LeafNullable<T> = { [K in keyof T]: T[K] extends Record<string,unknown> ? LeafNullable<T[K]> : T[K] | null };


//helper type that turns:
// type X = A | B | C 
// to
// type X = A & B & C
export type UnionToIntersection<U> = 
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never


//make a specific prop required, like Pick
// export type MakeRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }
export type MakeRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;


//Helpers to type check string json paths of an object
type IsTuple<T extends readonly any[]> = number extends T["length"] ? false : true
type TupleKeys<T extends readonly any[]> = keyof T extends keyof any[] ? never : keyof T
type ArrayKey = number;
type BrowserNativeObject = Date | File //| FileList
type Primitive = string | number | bigint | boolean | symbol | null | undefined

type PathImpl<K extends string | number, V> = V extends Primitive | BrowserNativeObject ? `${K}` : `${K}` | `${K}.${Path<V>}`;

type Path<T> = T extends ReadonlyArray<infer V> ? IsTuple<T> extends true ? {
    [K in TupleKeys<T>]-?: PathImpl<K & string, T[K]>;
}[TupleKeys<T>] : PathImpl<ArrayKey, V> : {
    [K in keyof T]-?: PathImpl<K & string, T[K]>;
}[keyof T];

export type JsonPathString<T extends Record<string, any>> = Path<T>;


type ArrayPathImpl<K extends string | number, V> = V extends Primitive | BrowserNativeObject ? never : V extends ReadonlyArray<infer U> ? U extends Primitive | BrowserNativeObject ? never : `${K}` | `${K}.${ArrayPath<V>}` : `${K}.${ArrayPath<V>}`;

export type ArrayPath<T> = T extends ReadonlyArray<infer V> ? IsTuple<T> extends true ? {
    [K in TupleKeys<T>]-?: ArrayPathImpl<K & string, T[K]>;
}[TupleKeys<T>] : ArrayPathImpl<ArrayKey, V> : {
    [K in keyof T]-?: ArrayPathImpl<K & string, T[K]>;
}[keyof T];
type PathValue<T, P extends Path<T> | ArrayPath<T>> = T extends any ? P extends `${infer K}.${infer R}` ? K extends keyof T ? R extends Path<T[K]> ? PathValue<T[K], R> : never : K extends `${ArrayKey}` ? T extends ReadonlyArray<infer V> ? PathValue<V, R & Path<V>> : never : never : P extends keyof T ? T[P] : P extends `${ArrayKey}` ? T extends ReadonlyArray<infer V> ? V : never : never : never;
export type JsonPathValue<T extends Record<string, any>, P extends JsonPathString<T>> = PathValue<T, P>;

export function isDefined<A>(a: A | undefined): a is A {
    return a !== undefined;
}
export function isNotNull<A>(a: A | null): a is A {
    return a !== null;
}

export type DeepNullable<T> = {
    [K in keyof T]: DeepNullable<T[K]> | null;
  };

//makes each prop in T also nullable
export type NullableProperies<T extends Record<string,unknown>> = {
    [P in keyof T]: T[P] | null
} 