import { useState, useEffect } from 'react'

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

// export function useDebouncedState<T>(initialValue: T, delay?: number): [T, (value:T) => void] {
//     const [internalValue, setInternalValue] = useState(initialValue);
//     const value = useDebounce(internalValue, delay);

//     return [value, setInternalValue];
// }

export default useDebounce