import * as React from 'react';

//basic component to display the blue swirl, or any future change to loading indicator
export const VLoadingIndicator:React.FC<{
    useIcon?:boolean
    style?:React.CSSProperties,
    slowLoadingThresholdMs?:number
}> = ({useIcon=false, style={}, slowLoadingThresholdMs=2000}) =>
{
    const [showLoadingIndicatorText, setShowLoadingIndicatorText] = React.useState(false);
    React.useEffect(() => {
        let timerId = setTimeout(() => {
            setShowLoadingIndicatorText(true);
        },slowLoadingThresholdMs); //2s slow load
        
        return () => {
            clearTimeout(timerId);
        }
    },[slowLoadingThresholdMs]);

    if(useIcon)
    {
        const iconStyle = {
            display:"inline-block",
            width:"16px", 
            height:"16px",
            ...style //add overriding / extra styles
        };
        return <span className="vloading" style={iconStyle}></span>
    }
    if(!showLoadingIndicatorText)
        return null;

    return <div>Loading...</div>;    
}