import { ApiGuid } from "./ApiGuid";
import { DayOfWeek } from "./Datetime";
import { ApiScheduledShift, SchedulingAlertNoShow } from "./Schedule";

const AlertReportHack = ['noShowGlobal','noShowScheduling','timeguard'] as const;
type AlertReportHackTuple = typeof AlertReportHack; //'onScheduleChange'|'beforeShift'|'noShow';
export type AlertReportType = AlertReportHackTuple[number];
export const AlertReportTypeList = AlertReportHack.concat(); //make the useful list for yup's oneOf

// export type AlertReportType = 'noShowGlobal'|'noShowScheduling'|'timeguard';

export type LegacyTimeguardAlertReportEntry = {
    affectedEmployee: number,
    alert: {
        name: string,
        allEmployees: boolean,
        employees: ApiGuid[], 
        groups: ApiGuid[],
    }

    //    TimeOfDay: 0,
    // NumberOfHours: 1
    timeGuardType: 0|1,
    timeGuardData: any // = alertItem.alertTimeGuard.data;
    clockOutTime:string //datetime = alertItem.clockEvent.end;
}
export type LegacyNoShowAlertReportEntry = {
    daysOfWeek:DayOfWeek[],
    timeOfDay:string,
    numberEmployees:number,
    noShowType:number,
    clockedInEmployees:string[],
    noShowTimeType:number,
    daysOfWeek2:DayOfWeek[],
    timeOfDay2:string,
    noShowJobType: number,
    numberJobs:number

    alert: {
        name: string,
        allEmployees: boolean,
        employees: ApiGuid[], 
        groups: ApiGuid[],
        allJobs: boolean,
        jobs: ApiGuid[]
    }
}

export type LegacyAlertDataRowTimeGuard = {
    // id: number,
    // customer_id: number
    // type: number
    triggeredDate: string
    data: LegacyTimeguardAlertReportEntry  
}
export type LegacyAlertDataRowNoShow = {
    // id: number,
    // customer_id: number
    // type: number
    triggeredDate: string
    data: LegacyNoShowAlertReportEntry  
}


export type AlertNoShowSchedulingData = { 
    rows: []
};

export type AlertReportQueryRequest = {
    alertReportType: AlertReportType,
    startDate: string,
    endDate: string
}

export type AlertLogNoShowSchedulingEntry = {
    triggerDate: string,
    employeesNotClockedIn: {
        guid: ApiGuid,
        groupGuid: ApiGuid|null //at the time of triggering
    }[]

    alertInfo: Pick<SchedulingAlertNoShow,'clockInCountNeeded'|'minAfter'|'noShowType'|'note'>,
    shiftInfo: Pick<ApiScheduledShift, 'guid'|'start'|'durationMinutes'|'timezone'|'jobAssignment'>    
}

export type AlertReportQueryResponse = {    
    alertType: AlertReportType,
    generationDate: string, //iso date string
} & ({
    alertType: 'timeguard',
    reportDataRows: LegacyAlertDataRowTimeGuard[]
}|{
    alertType: 'noShowScheduling',
    reportDataRows: AlertLogNoShowSchedulingEntry[]
}|{
    alertType: 'noShowGlobal',
    reportDataRows: LegacyAlertDataRowNoShow[]
})