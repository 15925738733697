import { ApiGuid } from "./ApiGuid"
import { ClockEventMethod } from "./ClockEvent";
import { CodeRuleRequiredOptions } from "./Company";

export type ClockMethod = 'phone'|'web'|'app'|'sms'|'kiosk'; //todo - expand kiosk to everywhere relevent (rules page, db storage/load)
export function convertActualClockMethodToJobRuleClockMethod(actualMethod:ClockEventMethod):ClockMethod
{
    switch(actualMethod)
    {
        case 'phone':
        case 'web':
        case 'sms':
        case 'kiosk':
            return actualMethod;
        case 'manualEntry':
        case 'edit':
        case 'machine':
        case 'mobileWeb':
            return 'web';
        case 'api':
            return 'app';        
        default:
            throw new Error('Unknown clockEventMethod: ' + actualMethod);
    }
}
export type RuleClockInMethod = 'any'|ClockMethod;

export function isClockMethod(method:string):method is ClockMethod
{
    switch(method.toLowerCase())
    {
        case 'phone':
        case 'web':
        case 'sms':
        case 'app':
        case 'kiosk':
            return true;
    }
    return false;
}
export type JobRule = {
    employee: 'any'|ApiGuid,
    group : 'any'|ApiGuid,  
    clockMethod: 'any'|ClockMethod
    callerID: string,
    job : 'none'|'optional'|'required'|ApiGuid
    createDate: string
}

export type JobRuleEngineLookupFunctionParams = {
    employeeGuid:ApiGuid|null,
    orderedGroupGuids:ApiGuid[],
    clockMethod: RuleClockInMethod,
    callerId: string
    companySetting: CodeRuleRequiredOptions
}
export type JobRuleEngineLookupFunction = (options:JobRuleEngineLookupFunctionParams) => ApiGuid | 'none'|'optional'|'required';

export type JobRuleForCreate = Omit<JobRule,'createDate'>;

//employee + group + clockMethod + callerID => Job
//employee + group + job => Service item
//timeType + Employee + Group + Job + Service Item => Job
export type ServiceItemRule = {
    employee: 'any'|ApiGuid,
    group : 'any'|ApiGuid,
    job: 'any'|ApiGuid,
    serviceItem: 'none'|'optional'|'required'|ApiGuid
    createDate: string|null
}
export type ServiceItemRuleOriginal = {
    // employee: ApiGuid | null,
    // group : ApiGuid | null,
    // job: ApiGuid | null,
    // serviceItem: 'none'|'optional'|'required'|ApiGuid
    // createDate: string

    serviceItemGuid: ApiGuid | null,
    // serviceItemName: this.serviceItemName,
    serviceItemType: 'optional'|'required'|'specified',
    groupGuid: ApiGuid | null,
    // groupName: this.groupName,
    jobGuid: ApiGuid | null,
    // jobName: this.jobName,
    employeeGuid: ApiGuid | null,
    // employeeName: this.employeeName
    createDate: string
}

export type ServiceItemRuleForCreate = Omit<ServiceItemRule,'createDate'>

export type ServiceItemRuleEngineLookupFunctionParams = {
    companySetting: CodeRuleRequiredOptions
    employeeGuid:ApiGuid|null,
    orderedGroupGuids:ApiGuid[],
    jobGuid:ApiGuid|null
}
export type ServiceItemRateRuleEngineLookupFunctionParams = {
    serviceItem: ApiGuid|null,
    employeeGuid:ApiGuid|null,       //employees are always set for a time record
    orderedGroupGuids:ApiGuid[],
    jobGuid:ApiGuid|null
}
export type ServiceItemRateRuleEngineLookupFunction = (options:ServiceItemRateRuleEngineLookupFunctionParams) => ServiceItemRateRule|null;

export type ServiceItemRuleEngineLookupFunction = (options:ServiceItemRuleEngineLookupFunctionParams) => ApiGuid | 'none'|'optional'|'required';

export type ServiceItemRateRule = {
    employee: 'any'|ApiGuid,
    group : 'any'|ApiGuid,
    job: 'any'|ApiGuid,
    serviceItem: ApiGuid
    rate: string,
    ratePennies: number,
    createDate: string|null
}
export type ServiceItemRateRuleForCreate = Omit<ServiceItemRateRule,'createDate'>;

export type QueryServiceItemRateRuleResponse = {
    rules: ServiceItemRateRule[]
}
export type CreateServiceItemRateRulesParams = {
    rules: ServiceItemRateRuleForCreate[]
}
export type CreateServiceItemRateRuleParams = {
    rule: ServiceItemRateRuleForCreate
}

export type CreateServiceItemRateRulesResponse = {
    rules: ServiceItemRateRule[]
}
export type CreateServiceItemRateRuleResponse = {
    rule: ServiceItemRateRule
}

export type DeleteServiceItemRateRulesParams = CreateServiceItemRateRulesParams;