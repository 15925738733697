import type { ColorPickerLegendEntry, PersonalSettings, TimeManipulationSettings } from '.';
import type { Employee } from './Employee';
import type { Group } from './Group';
import type { ApiGuid } from './ApiGuid';
import type { PayrollSettings, TimezoneString } from './Datetime';
import type { PermissionSettings, ScheduledDeduction, TimeSettings, TpaSignatureSettings } from './Settings';
import type { CustomerCustomConfig } from '../../../lib/src/customConfig/CustomerCustomConfig';
import type { WorkerIdBadgeSettings } from './WorkerIdBadge';
import type { FacialRecognitionSettings } from './FacialRecognition';
import { VisualCustomizations } from './VisualCustomizations';
import { SupportedLanguage } from './Localization';

export enum GpsSettingStatus
{
    Enabled='enabled',
    Disabled='disabled',
    UseEmployeeSettings='employee'
}

export enum JobCodeOnClockInSetting
{
    Required='required',
    Optional='optional',
    CustomRules='custom',
    Disabled='disabled'
}
enum CommonCompanyEmployeeSetting 
{
    Required='required',
    Optional='optional',
    UseEmployeeSettings='employee',
    Disabled='disabled'
}
export enum AutoLogoutSetting
{
    Off='off',
    NonAdmins='non-admins',
    All='all',
}
export type ReportOnClockSetting = CommonCompanyEmployeeSetting;
export type CodeRuleRequiredOptionsBase ='disabled'|'optional'|'required';
export type CodeRuleRequiredOptions = CodeRuleRequiredOptionsBase|'custom';
export const JobServiceItemCodeTypesAllowed:CodeRuleRequiredOptions[] = ['disabled','optional','required','custom'];
export function isRuleCodeSetting(val:string):val is CodeRuleRequiredOptions
{
    switch(val)
    {
        case 'disabled':
        case 'optional':
        case 'required':
        case 'custom':
            return true;
    }
    return false;
}


//intersect with CompanySettings! safer?
export type Company =
{
    guid:ApiGuid,
    companyName: string,
    // phoneID: number,
    countryCode: string|null,        //CA/US/etc.... b
    createdDate: string,         //utc ISO datetime
    settings: CompanySettings
}

export type CompanyPrefs = {
    _prefs: {
        isDevelopment:boolean,
        schedulingTeamViewEnabled:boolean,
        auditTrailEnabled:boolean,
        schedulingRepeatEvents: boolean,
        browserExtensionWedgeOn: boolean,
        branding?: {
            schedulingPrintLogo?: string,
            poweredByVeriClock?: string
        },
        basket_o_features: boolean,
        colorPickerCustomizations?: {            
            legend: ColorPickerLegendEntry[]
        },
        serviceItemRateRulesOn: boolean
        serviceItemRuleOptionalRequiredEnabled: boolean
        shiftMinimumsEnabled: boolean,
        multiGroupEnabled: boolean,
        activityViewExtensionsEnabled: boolean,
        hideScheduleWeeklyHourSums?: boolean,
        geofenceMinimumSizeOverride?: number
        isActivityNotesUiDisabled?: boolean,
        customFieldServiceItemAssignmentsEnabled?: boolean,
        maxReportFormulas?: number,
        clockReportMaxSize?: number,
        specialLegendHiding?: boolean
    }
}
export type DataPlanType = {
    name: string,
    spaceLimit?: number,
    bucketSize?: number,
    billText: string,
}

export type CompanyBrandingSettings = {
    brandColor: string,
}

export type AccountingIntegration = 'none'|'qbo'|'qbd'|'sage50ca'|'sage50us';
type IntegrationInfo = {
    accountingIntegration: AccountingIntegration
    qbRealmId: string|null
}
//these must be put in CompanySchema.ts for validation
export type CompanySettings = 
{
    language?: SupportedLanguage|null, 
    timezone: TimezoneString,
    // gpsStatus: GpsSettingStatus,
    // jobCode: JobCodeOnClockInSetting,
    // clockInReport: ReportOnClockSetting,
    // clockOutReport: ReportOnClockSetting,
    webInReportRequired: 'optional'|'required'|'disabled'|'employee',
    webOutReportRequired: 'optional'|'required'|'disabled'|'employee',
    autoLogout: AutoLogoutSetting,
    ivrOn:boolean,
    smsOn:boolean,
    // phone report settings don't exist on node yet.  Using the same naming convention as the web report settings.
    phoneInReportRequired: 'optional'|'required'|'disabled'|'employee',
    phoneOutReportRequired: 'optional'|'required'|'disabled'|'employee',
    smsReportRequired: 'required'|'optional'|'disabled',
    phoneShowClockDuration: boolean,
    maxPhoneNumbersAllowed: number,
    clockOver: boolean,

    activityNotesSetting: 'disabled'|'textOnly'|'textAndFiles'|'employee',
    fileDataPlan: 0|1,
    fileDataPlanInfo: DataPlanType,                 // read-only info on file data plan.
    gpsTaggingType:'disabled'|'enabled'|'employee',
    browserGpsLimitAccuracy:number,
    browserGpsLimitTime:number,
    mobileAppGpsAccuracy:number,
    mobileAppGpsTime:number,
    mobileAppGpsType:'disabled'|'enabled'|'employee',
    mobileAppGpsContinuous:'disabled'|'enabled',
    offlineClock: 'enabled'|'disabled'|'employee' //on/off or controlled by employee settings individually

    permissions: PermissionSettings,

    workerIdBadgeSettings?: WorkerIdBadgeSettings,
    facialRecognitionSettings?: FacialRecognitionSettings,
    payrollSettings: PayrollSettings,
    timeSettings: TimeSettings,
    tpaSettings: TpaSignatureSettings,
    timeManipulationSetting: TimeManipulationSettings,
    scheduledDeductions: ScheduledDeduction[],

    // companySettings: CompanySettings
    jobCodeRequired: CodeRuleRequiredOptions,
    serviceItemRequired: CodeRuleRequiredOptions,

    visualCustomizations: VisualCustomizations,
    multiGroupEnabled: boolean,
    jobBasedReadactionEnabled: boolean,
    companyBrandingSettings: CompanyBrandingSettings,
    customConfig: CustomerCustomConfig,
    integrationInfo?: IntegrationInfo
} & CompanyPrefs;

// type RoleCategories = 'employee'|'group'|'groupManager'
type RoleBasedSettings = {
    timeSettings?: {
        // preventWebClock?: TimeSettings['preventWebClock'] | null,                  //v1.1 - in use in accounts hiding web clock entirely (php)
        preventAppClock?: TimeSettings['preventAppClock'] | null,                  //v1 - in use in orgCon, port customization to this form + remove
        requireClockInPhoto?: TimeSettings['requireClockInPhoto'] | null,          
        requireClockOutPhoto?: TimeSettings['requireClockOutPhoto'] | null,
        //faceRecOnClockIn?: boolean,
        //faceRecOnClockOut?: boolean,
        // requireClockInPhotoOnKiosk?: boolean,
        // requireClockOutPhotoOnKiosk?: boolean,    
    },
    workerIdBadgeSettings?: Partial<WorkerIdBadgeSettings>, //make enable optional - though, maybe we are better off targeting this entire sub-key
    facialRecognitionSettings?: FacialRecognitionSettings | null,
}

//cherry picked required versions of RoleBasedSettings
export type CompanyCustomSettingsDefaults = {
    timeSettings: {
        preventAppClock: boolean,
        requireClockInPhoto: boolean,
        requireClockOutPhoto: boolean,
    },
    workerIdBadgeSettings: WorkerIdBadgeSettings,
    facialRecognitionSettings: Required<FacialRecognitionSettings>
}

//employee+group might be the same ultimately, or group adds a few items only relevent to group?
export type EmployeeCustomSettings = RoleBasedSettings; 
export type GroupCustomSettings = RoleBasedSettings;
export type CompanyCustomSettings = RoleBasedSettings;
// export type GroupManagerCustomerSettings = RoleBasedSettings<"groupManager">; //relevent to managers only, and sometimes in context of a specific group

//the render might have a value of 'default' sometimes...need to see and accomodate
//null indicates no setting ( in employee, maybe not in company - always has a default provided by server or does device decide default?)

// type Nullable<T> = { [K in keyof T]: T[K] | null };
// type NV = Nullable<VisualCustomizations>;

export type CompanyForCreate = Partial<Omit<Company, 'guid'|'phoneID'|'fileDataPlanInfo'>> & Required<Pick<Company,'companyName'>>
export type CompanyForUpdate = Partial<Omit<Company, 'phoneID'|'fileDataPlanInfo'>> & Required<Pick<Company,'guid'>>
// let x:CompanyForCreate = {}
// let y:CompanyForUpdate = {}


// export type CompanySettings = 
// // Pick<Company,
// //     'jobCodeRequired'|
// //     'serviceItemRequired'
// // >
// {
//     jobCodeRequired: CodeRuleRequiredOptions,
//     serviceItemRequired: CodeRuleRequiredOptions,

//     // webInReportRequired: 'optional'|'required'|'disabled'|'employee',
//     // webOutReportRequired: 'optional'|'required'|'disabled'|'employee',
//     // ivrOn:boolean,
//     // // phone report settings don't exist on node yet.  Using the same naming convention as the web report settings.
//     // phoneInReportRequired: 'optional'|'required'|'disabled'|'employee',
//     // phoneOutReportRequired: 'optional'|'required'|'disabled'|'employee',
//     // activityNotesSetting: 'disabled'|'textOnly'|'textAndFiles'|'employee',
//     // gpsTaggingType:'disabled'|'enabled'|'employee',
//     // mobileAppGpsType:'disabled'|'enabled'|'employee',
//     // mobileAppGpsContinuous:'disabled'|'enabled',
//     // offlineClock: 'enabled'|'disabled'|'employee' //o


//     timeSettings: {
// //         // signature: SignatureSettings,
// //         // gpsSettings: GpsSettings,
// //         managersCanUseGroupClock: boolean,
// // 		managersCanEditTimesheets: boolean,
// //         preventEditingOfApprovedTime: boolean,
// 		inToOutTime: boolean,
// 		inToOutTimeThreshold:number,
// // 		// pto: PtoSettings,
// // 		popoverShiftEditor: boolean,
// // 		preventWebClock: boolean,
// // 		preventAppClock: boolean,
// // 		requireClockInPhoto: boolean,
// // 		requireClockOutPhoto: boolean,
// // 		createEventDurationMode: 'allowBoth'|'onlyDuration'|'onlyTimeStamp'
//     }
// }

export type CompanySettingsForUpdate = Omit<Partial<CompanySettings>,'timeSettings'> & { timeSettings?: Partial<TimeSettings> }


export type ApiSettings = {
    publicKey: ApiGuid,
    privateKey: ApiGuid,
    requiresSignature: boolean,
    clockEventWebhookUrl: string|null
}
export type ApiSettingsForUpdate = Omit<ApiSettings,'privateKey'>;

export type GetApiSettingsResponse = {
    apiSettings: ApiSettings[]  //null == api not enabled
}
export type UpdateApiSettingsResponse = {
    apiSettings: ApiSettings
}
//update specific set api key
export type UpdateApiSettingsQuery = {
    apiSettings: ApiSettingsForUpdate
}

export type SsoSamlSettings = {
    samlEnabled: boolean,
    entryPoint: string, 
    strictMode:boolean,
    strictModeAdminToo:boolean
    redirectMode: boolean, 
    cert: string,
    //original ui had the followin in an 'advanced json blob', but were ultimately merged into the main struct
    profileKey?:string, //if defined, then profileKeyType is defined, if not, then looks for emails, email,nameID in the profile that is passed
    profileKeyType?:'email'|'employeeID',

    serviceProviderMetaDataUrl: string,
    signingCertUrl: string,
    encryptionPublicKeyUrl: string,
    initialRelayState: string
}
export type SsoSamlSettingsForUpdate = Omit<SsoSamlSettings,"serviceProviderMetaDataUrl"|"signingCertUrl"|"encryptionPublicKeyUrl"|"initialRelayState">;

export type SsoGoogleSettings = {
    enabled: boolean
}
export type SsoGoogleSettingsForUpdate = SsoGoogleSettings;

export type LoginAlertsSettings = {
    enabled: boolean,
    ipRanges: {         //db: ipRanges: string[], but form lib's array ideally takes an { ... }[] object array - and this structure will allow for better expandibility in the future with per range config
        cidr: string
    }[]
}

export type MiscServerInfo = {
    clientIpAddress?:string|null         //ipv4/ipv6 - probably sometimes incorrectly a comma separated list (null - indetermined for some reason)
}

export type SearchPhoneNumbers = {
    numbers: {
        id: number,
        customerID: number
        phoneNumber: string,
        capabilities: number
    }[]
};

export type CustomReportDetails = {
    reportIdentifier: string, //actually identifier: CustomReportsIdentifier, but not in client code
    name: string,
    description: string
}
export type UserCustomReportDetails = CustomReportDetails & {
    customMessage:string|null
};

export type CustomReportsEnabled = {
    reportsAvailable:UserCustomReportDetails[]
}

export type CreateCompanyRequestParams = {
    legacy?:boolean,
    companyName: string,
    subdomain?: string,
    timezone: TimezoneString,
    countryCode: string,
    stateCode: string,
    postalCode?: string, //optional 

    firstName: string,
    lastName?: string,
    emailAddress: string,
    phoneNumber: string,
    password: string,
    ssoSetup?: string,
}

export type CreateCompanyResponse = {
    company: Company,
    loginUrl: string,
    subdomain:string
}
export type CreateCompanyBadPasswordLegacyResponse = {
    password: string
}

export type QueryCoreDataResponse = {
    company: Company,
    user: Employee,
    personalSettings: PersonalSettings,
    userGroups: Group[]
    isAdminMode?:boolean
}

export type GetTermsOfServiceResponse = {
    html: string
}