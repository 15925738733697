export enum EmailTypes {
    Marketing='marketing',
    ProductAnnouncements='productAnnouncements',
    DurationAlerts='durationAlerts',
    NoShowAlerts='noShowAlerts',
    JobCostAlerts= 'jobCostAlerts',
    ClockAlerts= 'clockAlerts',
    TimeGuardAlerts= 'timeGuardAlerts',
    SchedulingAlerts='schedulingAlerts',
    ScheduledReports= 'scheduledReports',
    PTO= 'pto',
    Billing= 'billing',
    Broadcast= 'broadcast',
    InternalTechInfo= 'internalTechInfo',
    InternalTechError= 'internalTechError',
    InternalCustomerSupport= 'internalCustomerSupport',
    ForgotPassword= 'forgotPassword',
    InviteEmployee= 'inviteEmployee',
    SecurityAlerts= 'securityAlerts',
    Signatures= 'signatures',
    FacialRecognition= 'facialRecognition',
}

export const EmailTypeToEnglish:Record<EmailTypes,string> = {
    [EmailTypes.Marketing]: "Marketing",
    [EmailTypes.ProductAnnouncements]:"Product Announcements",
    [EmailTypes.DurationAlerts]:"Duration Alerts",
    [EmailTypes.NoShowAlerts]:"No Show Alerts",
    [EmailTypes.JobCostAlerts]:"Job Cost Alerts",
    [EmailTypes.ClockAlerts]:"Clock In/Out Alerts",
    [EmailTypes.TimeGuardAlerts]:"Time Guard Alerts",
    [EmailTypes.SchedulingAlerts]:"Scheduling Alerts",
    [EmailTypes.ScheduledReports]:"Scheduled Reports",
    [EmailTypes.PTO]:"PTO",
    [EmailTypes.Billing]:"Billing",
    [EmailTypes.Broadcast]:"Broadcast",
    [EmailTypes.InternalTechInfo]:"[Internal Tech]",
    [EmailTypes.InternalTechError]:"[Internal Err]",
    [EmailTypes.InternalCustomerSupport]:"[Internal CS]",
    [EmailTypes.ForgotPassword]:"Forgot Password",
    [EmailTypes.InviteEmployee]:"User Invitations",
    [EmailTypes.SecurityAlerts]:"Security Alerts",
    [EmailTypes.Signatures]:"Timesheet Signature/TPA",
    [EmailTypes.FacialRecognition]:"Facial Recognition",
}

export enum EmailStatus {
    pending='pending',
    delivered='delivered',
    bounce='bounce',                       // These are permanent bounces.  I would rename the enum if it was easy
    bounce_bad_email='bounce_bad_email',
    bounce_transient='bounce_transient',   // Formally the out of office, this is transient-general bounces which include out of office.
    bounce_unknown='bounce_unknown',
    out_of_office='out_of_office',         // deprecating this status, we can't tell it out of office, only that it's a transient bounce of type "general"
    mailbox_full='mailbox_full',
    message_too_large='message_too_large',
    content_rejected='content_rejected',
    attachment_rejected='attachment_rejected',
    complaint='complaint',
    suppressed='suppressed',
    error='error',
    unknown='unknown'
}
// Pending{/eq}
// {@eq value="delivered"}{/eq}
// {@eq value="bounce"}{/eq}
// {@eq value="bounce_bad_email"}{/eq}
// {@eq value="out_of_office"}{/eq}
// {@eq value="bounce_transient"}Bounced - Temporarily Unavailable{/eq}
// {@eq value="bounce_unknown"}{/eq}
// {@eq value="mailbox_full"}{/eq}
// {@eq value="message_too_large"}{/eq}
// {@eq value="content_rejected"}{/eq}
// {@eq value="attachment_rejected"}{/eq}
// {@eq value="complaint"}{/eq}
// {@eq value="suppressed"}<a><span class="glyphicon glyphicon-exclamation-sign infoEmailButton" data-index="{$idx}"></span></a>{/eq}
// {@eq value="error"}
//     {@select key="{error}"}
//         {@eq value="employeeNotActive"}Not Sent{^isBlocked} <a><span class="glyphicon glyphicon-exclamation-sign infoEmailButton" data-index="{$idx}"></span></a>{/isBlocked}{/eq}
//         {@eq value="customerCancelled"}Not Sent{^isBlocked} <a><span class="glyphicon glyphicon-exclamation-sign infoEmailButton" data-index="{$idx}"></span></a>{/isBlocked}{/eq}
//         {@eq value="accountNotValidated"}Not Sent{^isBlocked} <a><span class="glyphicon glyphicon-exclamation-sign infoEmailButton" data-index="{$idx}"></span></a>{/isBlocked}{/eq}
//         {@eq value="emailThrottled"}Not Sent{^isBlocked} <a><span class="glyphicon glyphicon-exclamation-sign infoEmailButton" data-index="{$idx}"></span></a>{/isBlocked}{/eq}
//         {@eq value="userUnsubscribed"}Not Sent{^isBlocked} <a><span class="glyphicon glyphicon-exclamation-sign infoEmailButton" data-index="{$idx}"></span></a>{/isBlocked}{/eq}
//         {@eq value="spamBlocked"}Not Sent{^isBlocked} <a><span class="glyphicon glyphicon-exclamation-sign infoEmailButton" data-index="{$idx}"></span></a>{/isBlocked}{/eq}
//         {@eq value="bounceBlocked"}Not Sent{^isBlocked} <a><span class="glyphicon glyphicon-exclamation-sign infoEmailButton" data-index="{$idx}"></span></a>{/isBlocked}{/eq}
//         {@eq value="suppressedBlocked"}Not Sent{^isBlocked} <a><span class="glyphicon glyphicon-exclamation-sign infoEmailButton" data-index="{$idx}"></span></a>{/isBlocked}{/eq}
//         {@none}Error{/none}
//     {/select}
// {/eq}
// {@eq value="unknown"}Unknown{/eq}
export const EmailStatusToEnglish:Record<EmailStatus,string> = {
    [EmailStatus.pending]:"Pending",
    [EmailStatus.delivered]:"Delivered",
    [EmailStatus.bounce]:"Bounced",
    [EmailStatus.bounce_bad_email]:"Bounced - Invalid Email Address",
    [EmailStatus.bounce_transient]:"Bounced - Temporarily Unavailable",
    [EmailStatus.bounce_unknown]:"Bounced - Unknown Reasons",
    [EmailStatus.out_of_office]:"",
    [EmailStatus.mailbox_full]:"Bounced - Mailbox Full",
    [EmailStatus.message_too_large]:"Bounced - Message too Large",
    [EmailStatus.content_rejected]:"Bounced - Content Rejected",
    [EmailStatus.attachment_rejected]:"Bounced - Attachment Rejected",
    [EmailStatus.complaint]:"User Marked Spam",
    [EmailStatus.suppressed]:"Not Sent", //** need more */
    [EmailStatus.error]:"Error",    //** needs more */
    [EmailStatus.unknown]:"Unknown",
}

export enum errorType {
    employeeNotActive='employeeNotActive',
    customerCancelled='customerCancelled',
    userUnsubscribed='userUnsubscribed',
    spamBlocked='spamBlocked',
    bounceBlocked='bounceBlocked',
    suppressedBlocked='suppressedBlocked',
    accountNotValidated='accountNotValidated',
    emailThrottled='emailThrottled'
}

export type EmailLogsRequest = {
    fromDate: string,
    toDate: string,
    offset: number,
    limit: number
    emailType?: string //enum but private
}

export type EmailLogEntry = {
    to: string,
    cc: string|null,
    bcc: string|null,
    subject: string,
    emailType: EmailTypes, 
    error: errorType|null,
    errorMessage: string|null,
    status: EmailStatus,
    createDate: string,
    isBlocked: boolean,
    activeEmployeeNames: string[],
    inactiveEmployeeNames: string[],
    deletedEmployeeNames: string[],
}

export type EmailLogsResponse = {
    logs: EmailLogEntry[],
    totalCount: number,
}

export type EmailUnblockRequest = {
    emailAddress: string
}