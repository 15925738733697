import { ApiGuid } from './ApiGuid';
import { GeofenceMap } from './ClockEvent';
import { EmployeeCustomSettings } from './Company';
import { FileRef } from './CompanyFile';
// import { SupportedLanguage } from './Localization';
import { EditEmployeePayrollInfoOptions } from './Payroll';
import { ThingLists, ThingStatus } from './Thing';
import { DeepPartial } from './TypescriptHelpers';
import { VisualCustomizations } from './VisualCustomizations';


// export enum EmployeeStatus {
//     Active = 'active',
//     Inactive = 'inactive',
//     Deleted = 'deleted',
// }

export type EmployeeStatus = ThingStatus;
export type EmployeeQueryStatusType = EmployeeStatus|"activeInactive"|EmployeeStatus[];
export function isEmployeeStatus(status:any):status is EmployeeStatus
{
    if(status === 'active' || status === 'inactive' || status === 'deleted')
        return true;
    return false;
}
export interface EmployeeQueryOptions
{
    //each group of parameters can query the api, but params from groups cannot be combined (ie status + email address)

    //if empty - all active employees are returned

    //queried independently or together
    status?:EmployeeQueryStatusType, //default //next iteration, make it string or array of the status types, get rid of activeInactive
    searchText?:string,

    //queried by itself
    emailAddress?:string

    //queried by itself
    phoneID?:string,

    //queried by itself - filters for employees in specific group
    groupGuid?:ApiGuid

    //queried by itself
    employeeGuids?:ApiGuid[]

    teamView?: boolean      //if true, requesting access to list of employees for team view (reduced fields, or none-depending on settings)
    includeAdminNotes?: boolean     //set true if you want to retrieve employee private notes as well.
}

// export interface EditEmployeeOptions extends 

type EmployeePasswordParameters = {
    password?:string,
    passconf?:string,
    oldpassword?:string,
}
 
type AccountingIntegrationInfo = {
    clientType?: number,
    syncId?: string
    syncName?: string 
    aiCustomData?: any    // JSON structure.
    vendorType?: 'Employee'|'Vendor'|'Other'
}

type EmployeeWelcomeEmailInfo = {
    _sendWelcomeEmail?: boolean,
    _sendPasswordResetEmail?: boolean
};


type EmployeeAllOptional =  DeepPartial<Omit<Employee,"clockState"|"clockInRootId"|"passwordNotSet"|"fullName"|"settings">>
 & ( { 
    settings?: DeepPartial<Omit<Pick<Employee,"settings">['settings'],"customSettings">> & { customSettings?: Employee['settings']['customSettings'] }
}) //)
 & EmployeePasswordParameters & AccountingIntegrationInfo;
export type EmployeeCreateUpdatePlusPasswordAllOptional = EmployeeAllOptional & Required<Pick<Employee,'firstName'>>;
//optional payrollItems member for employees on create/edit (not on output)
//allowing editing of these items during a create/edit api request
export type EmployeePayrollItem = Omit<EditEmployeePayrollInfoOptions,"employeeGuid">  //no coercion on form
type EmployeePayrollInfoforCreateUpdate = {
    payrollItems?: EmployeePayrollItem[]
}

export type EmployeeForCreate = Omit<EmployeeAllOptional & Required<Pick<Employee,"firstName">>,'guid'|'updateSyncId'> & EmployeePayrollInfoforCreateUpdate & Partial<EmployeeManagedGroupInfo>;
export type EmployeeForUpdate = EmployeeAllOptional & Required<Pick<Employee,"guid">> & EmployeePayrollInfoforCreateUpdate & Partial<EmployeeManagedGroupInfo>;
export type EmployeeForCreateOrUpdate = EmployeeForCreate|EmployeeForUpdate
export type EmployeeForCreateOrUpdateAllOptional = EmployeeAllOptional & EmployeePayrollInfoforCreateUpdate & EmployeeWelcomeEmailInfo & Partial<EmployeeManagedGroupInfo>;

export function employeeIsEmployeeForCreate(e:Partial<EmployeeCreateUpdatePlusPasswordAllOptional>):e is EmployeeForCreate
{
    return !employeeIsEmployeeForUpdate(e); //not for update? then for create
}
export function employeeIsEmployeeForUpdate(e:Partial<EmployeeCreateUpdatePlusPasswordAllOptional>):e is EmployeeForUpdate
{
    return typeof(e.guid) !== 'undefined';
}

export function deepCopyEmployee<T extends EmployeeAllOptional>(employee:T):T
{
    return JSON.parse(JSON.stringify(employee)); //keep an eye on this...Date objects return as strings, for example
}


/////////////////////////////////////////////////////////////////////////////////////////////
// NOTE!! if this is added to, add to Employee.php:employeeApiTypescriptCompatibleOutput()
//   also, think twice before adding any of the internal variables we have previously used
//   instead, lets discuss
/////////////////////////////////////////////////////////////////////////////////////////////

export type EmployeeType = 'admin'|'normal';
export interface Employee
{
    guid: ApiGuid,
    status: EmployeeStatus,
    firstName: string,
    middleName: string|null,
    lastName: string|null,
    fullName: string,
    emailAddress: string|null,
    groupGuid: ApiGuid|null,
    additionalGroupGuids: ApiGuid[],
    phoneID: string,            //a string of numbers with prefix 0's possible
    clockState: "clockedIn"|"clockedOut",
    type:EmployeeType,      //basic account type currently
    clockInRootId?:number,      //should be removed...need to scour code

    personalDetails:{
        addressLine1: string|null,
        addressLine2: string|null,
        countryCode: string|null,
        stateCode: string|null,
        city: string|null,
        postalCode: string|null,
    
        phoneHome: string|null,
        phoneMobile: string|null,
        phoneOther: string|null,
    
        birthday: string|null,
        driversLicense: string|null,
        taxNumber: string|null,

        iDataCode1: string,
        iDataCode2: string,
        iDataCode3: string,
        iDataCode4: string,
        iDataCode5: string,

        privateNotes?: string,
    },

    employmentDetails: {
        startDate: string|null,
        endDate: string|null,
        jobTitle: string|null,
    },

    dailyBudgetMins: {
        mon: number|null,
        tue: number|null,
        wed: number|null,
        thu: number|null,
        fri: number|null,
        sat: number|null,
        sun: number|null,
    },

    settings:{
        phone: {
            clockInReport: "disabled"|"optional"|"required",
            clockOutReport: "disabled"|"optional"|"required",
            pinType: "none"|"custom",
            pin?: string,
        },
        web: {
            clockInReport: "disabled"|"optional"|"required",
            clockOutReport: "disabled"|"optional"|"required",
        },
        location: {
            web: {
                tagInOut: "disabled"|"enabled",
            },
            app: {
                tagInOut: "disabled"|"enabled",
                continuousTracking: "disabled"|"enabled",
            }
        },
        permissions:{
            canViewOwnTimeSheet: boolean,
            canEditOwnTimeSheet: boolean,
            canViewJobDetails: boolean,
            canViewServiceItemDetails: boolean,
            offlineClock: boolean,
            activityNotes: "disabled"|"textOnly"|"textAndFiles",
        },
        visualCustomizations: VisualCustomizations,
        idBadge?: {
            byobBarCodeBadgeName1?: string,
            byobBarCodeBadgeData1?: string,
            byobBarCodeBadgeName2?: string,
            byobBarCodeBadgeData2?: string,
        },
        idBadgePhotoFileRef?: FileRef 
        customSettings?: EmployeeCustomSettings     
        // maybe move here
        // language?: SupportedLanguage //not updatable via employee edit yet
    },
    createdDate: string,    //datetime record was created, UTC
    updateDate: string,
    passwordNotSet: boolean,
    pinNotSet: boolean,
    
    updateSyncId: number,   //incremented each time employee is updated

    //legacy params, will be removed
    _legacyActivityParameters: ActivityParameters|null   
}

export type EmployeeIdBadgeSettings = {
    byobBarCodeBadgeName1?: string,
    byobBarCodeBadgeData1?: string,
    byobBarCodeBadgeName2?: string,
    byobBarCodeBadgeData2?: string,
}
//Legacy struct showing which columns are selected and their order
export type ActivityParameters = {
    selectedFields: string[]; //array of column names
    searchPeriod: 'thisweek'|'last24'|'today'|undefined|null,
    sortOrder: 'intime'|'outtime'|'jobcode'|'serviceitemcode'|'firstname'|'group'|'duration'|
        'intimereverse'|'outtimereverse'|'jobcodereverse'|'serviceitemcodereverse'|'firstnamereverse'|'groupreverse'|'durationreverse';

}

// export type EmployeeEditFormType = EmployeeCreateUpdatePlusPasswordAllOptional & {
//     payroll?: {
//         payrollValue: number
//     }[]
// } & {
//     _sendWelcomeEmail?: boolean,
//     _sendPasswordResetEmail?: boolean
// };
type EmployeeManagedGroupInfo = {
    managedGroupGuids: ApiGuid[]
}
export type EmployeeEditFormType = EmployeeCreateUpdatePlusPasswordAllOptional & EmployeeWelcomeEmailInfo & Partial<EmployeeManagedGroupInfo>;


export type EmployeeForBatchImportExport = Employee & EmployeeManagedGroupInfo;

// Currently only used in Group type.  The list of included fields seems too long to me.  I would like to just
// include guid and names but I don't know how it's used.
export type EmployeeLimited = Pick<Employee,"guid"|"firstName"|"middleName"|"lastName"|"emailAddress"|"phoneID"|"clockState"|"status"|"settings"|"clockInRootId">;
export type EmployeeForKioskActive = Pick<Employee,
    "guid"|"firstName"|"middleName"|"lastName"|"fullName"|"emailAddress"|"groupGuid"|"additionalGroupGuids"|"phoneID"
    |"clockState"|"type"|"clockInRootId"|"settings"|"createdDate"> & { status: 'active'};
export type EmployeeForKioskInactiveDeleted = {
    guid: ApiGuid,
    status: 'inactive'|'deleted',
};
export type EmployeeForKiosk = EmployeeForKioskActive | EmployeeForKioskInactiveDeleted;
export function isEmployeeForKisokActive(employee:EmployeeForKiosk):employee is EmployeeForKioskActive
{
    return employee.status === 'active';
}

//helper type to get rid of the pin in settings we don't want to send to devices
type EmployeeSettingsForWorkerApp = Omit<Employee['settings'],'phone'> & {
    phone: Omit<Employee['settings']['phone'],'pin'>;
}

export type EmployeeForWorkerAppActive = Pick<Employee,
    "guid"|"firstName"|"middleName"|"lastName"|"fullName"|"emailAddress"|"groupGuid"|"additionalGroupGuids"|"phoneID"
    |"clockState"|"type"|"clockInRootId"|"createdDate"> & { status: 'active'} & {
        //settings contribution
        settings: EmployeeSettingsForWorkerApp;
    }

export type EmployeeForWorkerAppInactiveDeleted = {
    guid: ApiGuid,
    status: 'inactive'|'deleted',
};
export type EmployeeForWorkerApp = EmployeeForWorkerAppActive | EmployeeForWorkerAppInactiveDeleted;
export function isEmployeeForWorkerAppActive(employee:EmployeeForWorkerApp):employee is EmployeeForWorkerAppActive
{
    return employee.status === 'active';
}


export interface DeviceStatusResponse {
    deviceClockedIn:boolean,
    gpsAuthorized?:"unknown"|"authorized"|"denied"|"restricted",
    notificationsAllowed?:"yes"|"no"|"unknown",
    geofences?: GeofenceMap,
}

export interface RequestLocationResponse {
    gpsId:number,       // TODO: This should be a guid.
}

//modified type reflecting the type seen in the before/after values of the audit trail 
export type AuditEmployee = DeepPartial<Employee> & {
    _passwordChanged?:boolean,  //indicates a password change occurred
    _auditPayrollWages?: {      //payrollItemGuid => wage
        [key:ApiGuid]: number
    }
}

export type EmployeesStatusRequest = {
    employeeGuids?:ApiGuid[]
}

export type EmployeeStatusClockedIn = {
    status: 'clockedIn'
    employeeGuid: ApiGuid,
    clockInRootGuid: ApiGuid;
    clockInTimestamp: number; //unix ts
    jobGuid: ApiGuid | null;
    serviceItemGuid: ApiGuid | null;
}
export type EmployeeStatusClockedOut = {
    status: 'clockedOut',
    employeeGuid: ApiGuid,
    // clockInRootGuid: null;
    // clockInTimestamp: null;
    // jobGuid: null;
    // serviceItemGuid: null;
    // jobGuid: ApiGuid|null,
    // serviceItemGuid: ApiGuid|null,
    // clockInRootGuid: ApiGuid,
    // clockInTimestamp: number
}
export type EmployeeClockStatus = EmployeeStatusClockedIn | EmployeeStatusClockedOut;
export type EmployeeGuidToClockStatus = Record<ApiGuid, EmployeeClockStatus> //employee Guid => status;
export type EmployeesStatusResponse = {
    employeeStatus:EmployeeGuidToClockStatus
}

export type EmployeeListsQueryTypes = EmployeeQueryStatusType;
export type EmployeeLists = ThingLists<Employee>;

export type EmployeeDeleteWarningsCheckRequestParams = {
    employeeGuids: ApiGuid[]
}
export type EmployeeDeleteWarningsCheckResponse = {
    //if present, there will be some
    warnings?: {
        //map of employeeGuid => delete info - no entry == no issues
        [key:ApiGuid]: {
            files?: {
                idPhotos: number, 
                deleteDays: number
            }            
        }
    }
}