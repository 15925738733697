import { ApiGuid } from '../types';
import { isDefined } from '../types/TypescriptHelpers';
export interface VeriClockValidationYupError 
{
    name: string;
    message: string;
    // value: any;
    path?: string;
    type?: string;
    errors: string[];
    // params?: Params;
    inner: VeriClockValidationYupError[];
}
export interface VeriClockValidationAjvError
{
    path: string
    message: string,
    type: string,
    _ajv: any //raw ajv error that led to this error - probably rwill remove
}
export interface VeriClockValidationError
{
    path: string
    message: string,
    type: string,
}

export function isErrorWithCode(err:any):err is {code:string}
{
    return isDefined(err.code);
}
export type ApiValidationErrorResponse = 
{
    requestId?: ApiGuid, 
    code: "ValidationError",
    message?:string
} & 
({
    isYup: true,  
    // yup: yup.ValidationError, //yup's own format
    yup: VeriClockValidationYupError, //yup's own format
} |
{
    isYup?: false,
    errors: VeriClockValidationAjvError[] //migrate all errors to this type
} | 
{
    isYup?: false,
    errors: VeriClockValidationError[] //migrate all errors to this type - for real this time
})

export function makeValidationError(data: {
    path: string,
    message: string,
    type: string
}):VeriClockValidationError
{
    return {
        path: data.path,
        message: data.message,
        type: data.type
    }
}
export function makeValidationErrorResponse(errors:VeriClockValidationError[]):ApiValidationErrorResponse
{
    return {
        code: "ValidationError",
        errors: errors
    }
}

type OldValidationError = 
{
    parameter: string,
    error: string,
    fullProperty: string,
    humanFriendlyError?: string    
}
type OldError = {
    requestId: ApiGuid, 
    code: "ParameterValidationFailure",
    message: string,
    validationErrors?: OldValidationError[]
}
export type OldValidationErrorResponse = OldError;

export function isValidationError(err:any):err is ApiValidationErrorResponse
{
    return err.code == "ValidationError";
}

function isOldError(err: any):err is ApiValidationErrorResponse
{
    return err.code == "ParameterValidationFailure";
}

export function isOldApiValidationError(err:any):err is OldError
{
    return err.code == "ParameterValidationFailure";
}
export function isApiValidationError(err:any):boolean //<-- try to unify under this one
{
    if(err && typeof(err) === 'object')
    {
        return isValidationError(err) || isOldError(err);
    }
    return false;
}
export function isAjvValidationError(err:any):err is VeriClockValidationAjvError
{
    if(isValidationError(err)) 
    {
        if(err.isYup)
            return false;
        else 
            return true;                    
    }
    return false;
}

//returns null if not a validation error otherwise returns array of validation errors (for the ajv type)
export function getValidationErrors(err:any)
{
    if(isValidationError(err) && isAjvValidationError(err))
    {
        if(!err.isYup)
            return err.errors;
    }
    return null;
}
export function makeFriendlyErrorForValidationError(err:ApiValidationErrorResponse|OldError):string
{
    
    if(isValidationError(err)) 
    {
        if(err.isYup)
            return `Validation Error: 
${err.yup.errors.join("\n")}`; //todo - make more useful
        else 
            return `Validation Error:
${err.errors.map( (e:VeriClockValidationAjvError|VeriClockValidationError)=> e.message).join("\n")}`; //map together the ajv errors - last resort this entire function
    }
    else if(isOldError(err))
        return "Validation Error"; //todo - make more useful

    return "Unknown error type";
}

export class ApiValidationError extends Error
{
    _type = 'ValidationError'
    validationErrors:VeriClockValidationError[]
    constructor(message:string, validationErrors:VeriClockValidationError[])
    {
        super(message);
        this.validationErrors = validationErrors;
    }
}

export function isVeriClockValidationError(err:any):err is ApiValidationError
{
    return (err instanceof ApiValidationError)
}

//helper that stacks the parent/wrapper error
// so an array of something with an error would stack the error paths:
// jobs => jobs.500 => jobs.500.parentCode <- not found, for example vs
//   parentCode <- not found in a single job operation api call
export function makeApiValidationError(mainMessage: string, err: VeriClockValidationError & { parentErrorPath?: string }):ApiValidationError
{
    const { parentErrorPath="", ...errRest } = err;
    
    return new ApiValidationError(mainMessage, [{
        path: parentErrorPath ? parentErrorPath + '.' + errRest.path : errRest.path,
        message: errRest.message,
        type: errRest.type
    }]);
}

export function combineApiErrorsIntoSingleError(mainMessage:string, errors:VeriClockValidationError[])
{
    return { 
        code: "ValidationError",
        message:mainMessage,
        isYup: false,
        errors: errors
    }
}