import React, { useMemo } from 'react';

export const VGrid:React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({children, className, ...restProps}) => {
    const cname = className ? className + " container-fluid" : "container-fluid";
    return <div className={cname} {...restProps}>{children}</div>
}
export const VRow:React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({children, className, ...restProps}) => {
    const cname = className ? className + " row" : "row";
    return <div className={cname} {...restProps}>{children}</div>
}
export const VCol:React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    xs?:number
    md?:number
    sm?:number
    lg?:number
}> = ({xs,md,sm,lg, className, children, ...restProps}) => {
    const allClassNames = useMemo(() => {
        let cols = [];
        if(xs)
            cols.push(`col-xs-${xs}`);
        if(md)
            cols.push(`col-md-${md}`);
        if(sm)
            cols.push(`col-sm-${sm}`);
        if(lg)
            cols.push(`col-lg-${lg}`);
        if(className)
            cols.push(className);
        return cols.join(' ');
    },[xs,md,sm,lg,className]);

    return <div className={allClassNames} {...restProps}>{children}</div>
}