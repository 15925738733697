import { isApiGuid } from ".";
import { ApiGuid } from "./ApiGuid";

export type GeofenceLocationType = 'locationAtClockIn'|'locationSpecific';
export type VLatLng = {
    latitude: number,
    longitude: number
}
export type GeofencePoint = {
    latitude: number|null,
    longitude: number|null,
    radius: number,
};
export type GeofenceClockInBehavior = 'nothing'|'warn'|'block';
export type GeofenceWhileClockedInBehavior = 'nothing'|'warn'|'autoClockOut';
export type GeofenceClockOutBehavior = 'nothing'|'warn'|'block';

type BorkedFenceJob = {
    guid: ApiGuid|'all',
    name?: string,
    code?: number,
}

export type BorkedFenceData = {           //list of other geofences, by name, that have conflicting jobs with this geofence + jobs conflicting with each geofence
    guid: ApiGuid,
    name: string,
    jobs: BorkedFenceJob[]
};


type JobMapHelper = 
{
    guid: ApiGuid,
    name: string,
    code: number
}
export type Geofence = //public interface
{
    guid: ApiGuid,
    status: 'active'|'inactive'|'deleted',
    name: string,
    address: string,
    locationType: GeofenceLocationType,
    locations: GeofencePoint[],
    appliesToAllJobs: boolean,
    jobs: ApiGuid[],
    createdDate: string,    //Date stringified - iso form, datetime
    updateDate: string,    //Date stringified - iso form, datetime
    behaviorOnClockIn: GeofenceClockInBehavior,
    behaviorWhileClockedIn: GeofenceWhileClockedInBehavior,
    behaviorOnClockOut: GeofenceClockOutBehavior,

    warnMessageOnClockIn: string,
    blockMessageOnClockIn: string,

    warnMessageWhileClockedIn: string,
    whileClockedInNotify: boolean,
    warnMessageToNotificationListWhenExitWhileClockedIn: string,

    warnMessageOnClockOut: string,
    blockMessageOnClockOut: string
    jobMap?: JobMapHelper[],
    deleted?:boolean

    borked?:BorkedFenceData[]
}

export type GeofenceForDelete = Pick<Geofence,'guid'|'updateDate'>;
export type GeofenceForUpdate = Partial<Omit<Geofence,'deleted'|'borked'|'updateDate'|'createdDate'>> & Required<Pick<Geofence,'guid'>>
export type GeofenceForCreate = Omit<GeofenceForUpdate, 'guid'> & Required<Pick<GeofenceForUpdate,'name'|'appliesToAllJobs'|'jobs'|'locationType'|'locations'>>;
export type GeofenceForCreateUpdate = GeofenceForUpdate|GeofenceForCreate;
export function isGeofenceForUpdate(fence:Partial<Geofence>):fence is GeofenceForUpdate
{
    return isApiGuid(fence.guid);
}

export type GeofenceMessageKey = 'warn_msg_on_clock_in'|'block_msg_on_clock_in'|'warn_msg_while_in'|'warn_msg_on_clock_out'|'block_msg_on_clock_out';
export type ApiGeofenceListResponse = {
    defaultMessages: Record<GeofenceMessageKey, string>
    geofences:Geofence[]
}

export type ForwardGeocodingPosition =
{
    displayName: string
} & Partial<VLatLng>;

//https://locationiq.com/docs
export type LocationIqResult = {
    boundingbox: [number,number,number,number] //[min lat, max lat, min lon, max long]
    lat: number,
    lon: number,
    display_name: string,
    importance: number, //0-1 - '
    // address?: {                 //addressdetails=1 in req
    //     house_number?: string
    //     road?: string
    //     neighbourhood?: string
    //     hamlet?:string
    //     village?: string
    //     town?: string
    //     city_district?: string
    //     city?: string
    //     region?: string
    //     county?: string
    //     state_district?:string
    //     state_code?: string
    //     postcode?: string
    //     country?: string
    //     country_code?:string
    //     name?: string        
    // }
    address?: {                 //addressdetails=1&normalizeaddress=1
        name?: string        
        house_number?: string
        road?: string
        neighbourhood?: string
        suburb?:string
        island?: string
        city?: string
        county?: string
        state?:string
        state_code?: string
        postcode?: string
        country?: string
        country_code?:string
    }

}


export type ForwardGeocoderRequestParams = {
    searchTerm: string,
    biasSearchPosition?: VLatLng,
    geocoder?: number
    autoComplete?:boolean //true if for auto complete...no lat lng expected...(use optimized index)
}
export type ApiGeocoderResults = {
    results: ForwardGeocodingPosition[]
}