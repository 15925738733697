import React from 'react';

//bolds the first occurance of a substring
export function BoldFirstMatch(props: {
    source:string,
    subString:string
})
{
    const regex = new RegExp(props.subString, 'i');
    let match:RegExpExecArray | null;
    if((match = regex.exec(props.source)) !== null)
    {
        let before = props.source.substring(0, match.index);
        let after = props.source.substring(match.index + match[0].length);
        return <>{before}<b>{match[0]}</b>{after}</>
    }
    return <>{props.source}</>
}

export function BoldSubstring(props:{
    source:string,
    subString: string,
    label?:string
})
{
    const regex = new RegExp(props.subString, 'ig');
    let match:RegExpExecArray | null;
    let cursor = 0;
    const jsx:JSX.Element[] = [];
    
    let matchIdex = 0;
    while ((match = regex.exec(props.source)) !== null) {
        // console.log(`Found ${match[0]} start=${match.index} end=${regex.lastIndex}.`);
        //0 length will be 0 length "" 
        let before = props.source.substring(cursor, match.index);
        jsx.push(<React.Fragment key={matchIdex}>{before}<b>{match[0]}</b></React.Fragment>);
        cursor = regex.lastIndex;
        matchIdex++;
    }

    if(cursor < props.source.length)
    {
        let after = props.source.substring(cursor);
        jsx.push(<React.Fragment key={matchIdex}>{after}</React.Fragment>);
    }
    if(props.label)
        return <>{props.label}: {jsx}</>
    return <>{jsx}</>
}

export function BoldFirstMatchWithCount(props:{
    source:string,
    subString: string,
    label:string
})
{
    const regex = new RegExp(props.subString, 'ig');
    let match:RegExpExecArray | null;
    const BEFORE_CHARS = 25;
    const AFTER_CHARS = 25;
    if((match = regex.exec(props.source)) !== null)
    {
        //we have a match
        const before = props.source.substring(match.index-BEFORE_CHARS, match.index);
        const prefix = (match.index-BEFORE_CHARS < 0) ? '' : '...';
        const afterIndex = match.index + match[0].length;
        const after = props.source.substring(afterIndex, afterIndex + AFTER_CHARS);
        const suffix = (afterIndex + AFTER_CHARS) >= props.source.length ? "" : "...";
        const matchedPart = match[0];
        let additionalMatches = 0;
        while((match = regex.exec(props.source)) !== null) {
            additionalMatches++;
        }
        const additionalMatchesJsx = additionalMatches ? <>&nbsp;[{additionalMatches} other match{additionalMatches === 1 ? '' : 'es'}]</> : null;
        return <><b>{props.label}</b>: &nbsp;<i>{prefix}{before}<b>{matchedPart}</b>{after}{suffix}</i>{additionalMatchesJsx}</>
    }
  
    return null;
}