import { ApiGuid } from './ApiGuid';
import * as ApiType from "./";
import { DayOfWeek } from './Datetime';
import { ApprovalFilterType } from './ClockEvent';
export type ReportFormula =
    {
        name: string
        formulaString: string
        runWhen?: 'perTimeEntry' | 'perAggregateRow'
    }

type ScheduledReportId = ApiGuid;

type CommonFilterType = 'includeList' | 'excludeList';
type WithChildrenFilterType = 'includeListWithChildren' | 'excludeListWithChildren';
export type EmployeeFilterType = CommonFilterType;
export type JobFilterType = CommonFilterType | WithChildrenFilterType
export type GroupFilterType = CommonFilterType;
export type ServiceItemFilterType = CommonFilterType;
export function isEmployeeFilter(f: null | CommonFilterType | WithChildrenFilterType): f is EmployeeFilterType {
    return f != 'includeListWithChildren' && f != 'excludeListWithChildren';
}
export function isServiceItemFilter(f: null | CommonFilterType | WithChildrenFilterType): f is EmployeeFilterType {
    return f != 'includeListWithChildren' && f != 'excludeListWithChildren';
}
export function isGroupFilter(f: null | CommonFilterType | WithChildrenFilterType): f is EmployeeFilterType {
    return f != 'includeListWithChildren' && f != 'excludeListWithChildren';
}
//weak typeguard for typescript apeasement, vs actual definitive detection (though, only one with the childfilter currently)
export function isJobFilter(f: null | CommonFilterType | WithChildrenFilterType): f is EmployeeFilterType {
    return f === null ||
        f == 'includeList' ||
        f == 'excludeList' ||
        f == 'includeListWithChildren' ||
        f == 'excludeListWithChildren';
}

//not jobCost is not used at the moment - b
export type ScheduledReportCoverage = 'currentDay' | 'previousDay' | 'currentWeek' | 'previousWeek' | 'currentPayPeriod' | 'previousTwoWeeks' | 'previousPayPeriod' | 'jobCost';
export const ReportCoverageList: ScheduledReportCoverage[] = [
    'currentDay', 'previousDay',
    'currentWeek', 'previousWeek',
    'currentPayPeriod', 'previousTwoWeeks',
    'previousPayPeriod',//'jobCost'
]
export interface ScheduledReportsCalculateReportRunDate {
    reportNumericId: number,
    referenceDate: Date,
    customerGuid: ApiGuid
}

export function reportCoverageNeedsPayrollDateSet(reportCoverage: ScheduledReportCoverage): boolean {
    return (reportCoverage === 'previousPayPeriod' ||
        reportCoverage === 'currentPayPeriod')
}
export function reportCoverageNeedsDaysAfter(reportCoverage: ScheduledReportCoverage): boolean {
    if (reportCoverage == 'previousPayPeriod')
        return true;
    return false;
}
export function reportCoverageNeedsDayOfWeek(reportCoverage: ScheduledReportCoverage): boolean {
    switch (reportCoverage) {
        case 'currentDay': //daily reports don't need day of week
        case 'previousDay':
            return false;
        case 'currentWeek':
        case 'previousTwoWeeks':
        case 'previousWeek':
        case 'currentPayPeriod':
            return true;
    }
    return false;
}

export type ReportFileFormatTypes = 'csv' | 'xls' | 'pdf' | 'xlsx';
type TimeOfDay = string; //19:30 - 24 hour clock prob! - move to datetime

export type ScheduledReportMetadata =
    {
        employeeFilterType: ApiType.EmployeeFilterType | null,
        employeeFilterList: ApiGuid[], //needs all/inactive/etc
        jobFilterType: ApiType.JobFilterType | null,//null => no filter
        jobFilterList: ApiGuid[],
        groupFilterType: ApiType.GroupFilterType | null,//null => no filter
        groupFilterList: ApiGuid[],
        serviceItemFilterType: ApiType.ServiceItemFilterType | null, //null => no filter
        serviceItemFilterList: ApiGuid[],
        sendToList: ApiGuid[],
        doNotSendIfZeroHours?: boolean, //new - can be absent
    }

export type ScheduledReport = {
    id: ScheduledReportId
    name: string | null //null/undefined == defaults to template name like legacy behavior
    templateName: string,
    coverage: ScheduledReportCoverage,
    outputFormat: ReportFileFormatTypes,
    sendTimeOfDay: TimeOfDay,           //on edit, these will be used to update sendDatetime
    sendDayOfWeek: DayOfWeek,           //on edit, these will be used to update sendDatetime
    sendDelayDays: number | null,         //on edit, these will be used to update sendDatetime
    scheduledSendDate: Date,
    processingDateTime: Date | null,
    errorCode: number | null,
    errorMessage: string | null,
    metadata: ScheduledReportMetadata,
    // notes: string
    syncId: number,
    updateDate: Date
}



//only include fields we can change OR need to identify the record (id + syncId)
//todo: templateName needs validity checking before allowing insert
export type ScheduledReportForEdit = Omit<ScheduledReport, 'templateName' | 'updateDate' | 'errorCode' | 'errorMessage' | 'processingDateTime' | 'scheduledSendDate'>;
export type ScheduledReportForCreate = Omit<ScheduledReport, 'id' | 'updateDate' | 'errorCode' | 'errorMessage' | 'processingDateTime' | 'scheduledSendDate' | 'syncId'>

export type ScheduledReportForDeleteReset = Pick<ScheduledReport, 'id' | 'syncId'>;

export type GetScheduledReportsParams = {
    reportIds?: ScheduledReportId[]
}
export type GetScheduledReportsResponse = {
    scheduledReports: ScheduledReport[]
}
export type DeleteScheduledReportsParams = {
    reportToUpdate: ScheduledReportForDeleteReset
}
export type DeleteScheduledReportsResponse = {
    //no report to reply with - it is gone
}

export type CreateScheduledReportsParams = {
    reportToCreate: ScheduledReportForCreate
}
export type UpdateScheduledReportsParams = {
    reportToUpdate: ScheduledReportForEdit
}
export type CreateScheduledReportsResponse = {
    createdReport: ScheduledReport
}
export type UpdateScheduledReportsResponse = {
    updatedReport: ScheduledReport
}

export type ResetScheduledReportsParams = {
    reportToUpdate: ScheduledReportForDeleteReset
}
export type ResetScheduledReportsResponse = {
    updatedReport: ScheduledReport
}




type VDBDateTimeString = string; //YYYY-MM-DD HH:MM[:SS] -- optional seconds, 24hr clock

export type ReportParams = {
//    version: 3,  //3 = only current version - not checked / used
    templateName: string,
    fromTime: VDBDateTimeString, //customer TZ start datetime range
    // fromTimeObj: null,           //? h
    toTime: VDBDateTimeString,  //inclusive time range
    // toTimeObj: null,             //?
    templatePreviewMode?: boolean, //true - use sample data, ignore date range - filters irrelevent but can run - also NO filter on output fields - UI expects them all
    groupBy: string[]  //[ 'employee' ], //used to sort the report - items are ordered in the array by groups in tiered order
    selectedFields: Array<string>        //can use to optimize output + query in theory
    //         'employee_name',
    //         'group',
    //         'job_code',
    //         'date',
    //         'in_time',
    //         'out_time',
    //         'hours',
    //         'total_hours'
    //       ],
    horizontalFields: string[],
    employeeFilterEnabled: boolean,
    employeeFilterType: 'includeList' | 'excludeList',
    employeeFilter: string|ApiGuid[],

    jobFilterEnabled: boolean,                //usefor for filtering output - cannot filter query if OT is used without clever pre-filtering
    jobFilterType: 'includeList' | 'excludeList' | 'includeListWithChildren' | 'excludeListWithChildren',
    jobFilter: string|ApiGuid[],

    serviceItemFilterEnabled: boolean,         //usefor for filtering output - cannot filter query if OT is used without clever pre-filtering
    serviceItemFilterType: 'includeList' | 'excludeList',
    serviceItemFilter: string|ApiGuid[],

    groupFilterEnabled: boolean,              //useful for filtering output AND query params usually
    groupFilterType: 'includeList' | 'excludeList',
    groupFilter: string|ApiGuid[],

    // customFilter: 'none',                //not sure what this is
    sortOrder: 'jobcode' | 'jobname' | 'firstname' | 'lastname' | 'employeeid',
    language: 'english' | 'french',                 //not relevent at this layer - report generation level
    showShifts: boolean,                    //not relevent until this generates aggregate summmation data for the report, probably never

    topGroupByWithPageBreak: boolean,      //not relevent at this layer - report generation level
    includeAllEmployees: boolean,          //not relevent at this layer - report generation level - report level can add these back in
    includeAllEmployeesOncePerDay: boolean,
    includeSignatureLine: boolean,         //not relevent at this layer - report generation level
    extraText?: { footer?:string },                       //not relevent at this layer - report generation level
    timeDisplayFormat: 'decimal'| 'hhmm' | 'HHhMMm',        //not relevent at this layer - report generation level



    approvedTime: ApprovalFilterType | 'not_approved',                 //useful for filtering returned values, but not for the time query itself!
    applyServiceItemRulesOnExport: boolean, //needed for time query to re-apply service item rules if we want to keep supporting this
    expandTimeEntryToMultipleRowsPerTimeType?: boolean, //if true, each row will be expanded into multipel rows per time type

    deductionData: TimeAdjustmentScheduledDeductionSettings[]|null, //scheduled deduction data
    columnWidths: number[],                  //not relevent at this layer - report generation level


    //roudning override?
    useGlobalRoundingSettings: boolean,   //decide whether rounding override is used
    roundClockInTime: TimeAdjustmentRoundingStep,
    roundClockOutTime: TimeAdjustmentRoundingStep,
    roundClockInTimeDirection: TimeAdjustmentRoundingDirection,
    roundClockOutTimeDirection: TimeAdjustmentRoundingDirection,

    useGlobalShiftHourSettings: boolean,  //decide whether shift deduction override is used
    //basic deductions override ? 
    addEnabled: boolean,
    addSign: TimeAdjustmentShiftDeductionAddSign,
    addHours: number,
    perHours: number,

    useGlobalScheduledDeductions: boolean, //decide whether scheduled deduction override is used

    formulas?: { [key: string]: ReportFormula },              //array, could be missing, of ReportFormula
    // id: 0,                               //not sure this is used anymore
    // reportType: 'custom',                //not sure this is used anymore - all are custom? Is used, but not sure its relevent

    //search range - this route may not care, passed in is the date range in customer zone
    //could read the other values to generate instead
    //   searchPeriod: 'custom',
    //   fromUTC: '2018-12-01 08:00:00',
    //   toUTC: '2019-02-01 07:59:59',
    //   numberPeriods: '1',
    //   now: '2019-06-23 07:43:45',
    //   nowUTC: '2019-06-23 14:43:45',
    //   exportType: '',                    //not relevent at this layer - report generation level

    //below, probably not relevent at this layer - report generation level
    // templateName: 'Payroll (Default)',
    // templateReadOnly: false,
    dateDisplayFormat: 'mmm d, yyyy' | 'ddd mmm d, yyyy' | 'yyyy-mm-dd' | 'yy-mm-dd' | 'dd/mm/yy' | 'mm/dd/yy' | 'mm-dd-yy' | 'd-mmm-yy' | 'd-mmm' | 'dddd',
    timeOfDayDisplayFormat: 'h:mm a' | 'h:mm A' | 'hh:mm a' | 'hh:mm A' | 'H:mm' | 'HH:mm' | 'HHmm',
    flatGHeader: boolean,
    fontSize: string,
    string_override: {[key:string]:string} | null,
    skipFooter: boolean,
    skipHeader: boolean,
    hideColumnHeaders: boolean,
    // _cacheIncludeAllEmployeesOn: false,
    // _cacheIncludeActivityReports: false
    // }
    showEmployeeCounts: boolean,
    fetchNewerEventsLimitOne?: VDBDateTimeString
    wrapText?: boolean,
    templateReadOnly?: boolean,
}

export type ReportTemplate = Omit<ReportParams,'templatePreviewMode'|'fetchNewerEventsLimitOne'|'toTime'|'fromTime'> & {
    guid?: ApiGuid,
    version: number,
    templateReadOnly?: boolean,
}

export type ReportTemplateForCreate = Partial<Omit<ReportTemplate,'templateName'|'templateReadOnly'|'version'>> & Pick<ReportTemplate,'templateName'>;

export const enum TimeAdjustmentRoundingStep {
    Disabled = -1,
    DoNotRound = 1, //rounding to nearest minute is not rounding given everything is rounded to the nearest minute by default
    Five = 5,
    Six = 6,
    Ten = 10,
    Fifteen = 15,
    Thirty = 30,
    Hour = 60
}
export const enum TimeAdjustmentRoundingDirection {
    Up = 'up',
    Nearest = 'nearest',
    Down = 'down'
}

export interface TimeAdjustmentRoundingSettings //match 
{
    roundClockInTime: TimeAdjustmentRoundingStep,
    roundClockOutTime: TimeAdjustmentRoundingStep,
    roundClockInTimeDirection: TimeAdjustmentRoundingDirection,
    roundClockOutTimeDirection: TimeAdjustmentRoundingDirection,
    roundClockInFirstShiftOnly?: boolean, //optional since report template do not have, absent = false, the default
    roundClockOutLastShiftOnly?: boolean,
}
export const enum TimeAdjustmentShiftDeductionAddSign {
    Add = 1,
    Subtract = -1
}
type LegacySelectedEmployeeForDeductions = {
    employeeID: string
}
type LegacySelectedGroupForDeductions = {
    groupID: string
}

export type TimeAdjustmentScheduledDeductionSettings = TimeAdjustmentScheduledDeductionSettingsOld|TimeAdjustmentScheduledDeductionSettingsNew;
export type TimeAdjustmentScheduledDeductionSettingsOld = {
    deductHours: number, //"0.5"
    deductHoursPer: number, //
    deductStart: string, //"1:00 PM"
    id: number, //probably the index of the item in the list of deduction objects
    selectedAllEmployees: boolean
    selectedEmployeeList?: LegacySelectedEmployeeForDeductions[]
    selectedGroupList?: LegacySelectedGroupForDeductions[]
}
export type TimeAdjustmentScheduledDeductionSettingsNew = {
    deductHours: number, //"0.5"
    deductHoursPer: number, //
    deductStart: string, //"1:00 PM"
    id: number, //probably the index of the item in the list of deduction objects
    selectedAllEmployees: boolean
    selectedEmployeeList?: ApiGuid[]
    selectedGroupList?: ApiGuid[]
}
export function isTimeAdjustmentScheduledDeductionSettingsNew(d: TimeAdjustmentScheduledDeductionSettings): d is TimeAdjustmentScheduledDeductionSettingsNew 
{
    return(isNewSelectedEmployeeForDeductionsList(d.selectedEmployeeList) || 
        isNewSelectedGroupForDeductionsList(d.selectedGroupList));
}

export function isNewSelectedEmployeeForDeductionsList(list?:LegacySelectedEmployeeForDeductions[]|ApiGuid[]): list is ApiGuid[] {
    if(Array.isArray(list) && list.length > 0 && typeof (list)[0] === 'string')
        return true;
    return false;
}
export function isNewSelectedGroupForDeductionsList(list?:LegacySelectedGroupForDeductions[]|ApiGuid[]): list is ApiGuid[] {
    if(Array.isArray(list) && list.length > 0 && typeof (list)[0] === 'string')
        return true;
    return false;
}

export interface TimeAdjustmentShiftDeductionSettings {
    addEnabled: boolean,
    addSign: TimeAdjustmentShiftDeductionAddSign,
    addHours: number,
    perHours: number,
}

export type SystemLogsReportType = 'PTOAccrual' | 'PTOBalancesImport'; //change to correct/desired values
export type SystemLogReportPtoAccrualResponse = {
    logs: {
        data: {
            version: number,
            durationAccrualSecs: number,
            employeesProcessed: number,
            employeesUniqueProcessed: number,
            ptoTypesProcessed: number,
            balanceEntries: SystemLogReportBalanceEntry[],
            ptoRules:{
                guid: string,
                name: string,
                ruleTemplateGuid: string,
                settingsJSON: JSON | null
            },
            payrollSettings: {
                weekStart: string,
                payrollPeriod: string,
                recentPayrollDate: string,
                semiMonthlyFirstHalf: number,
                semiMonthlyLastHalf: number,
                monthlyDay: number
            },
            ptoTypes: SystemLogReportPtoBalanceImportPTOType[]
        },
        dateCreated: string,
        errorCount:number,
        guid:string,
        type: SystemLogsReportType
    }[]
}
export type SystemLogReportPtoBalanceImportResponse = {
    logs: {
        data: {
            version: number,
            durationImportSecs: number,
            employeesProcessed: number,
            employeesUniqueProcessed: number,
            ptoTypesProcessed: number,
            balanceEntries: SystemLogReportBalanceEntry[],
            ptoTypes: SystemLogReportPtoBalanceImportPTOType[]
        },
        dateCreated: string,
        errorCount:number,
        guid:string,
        type: SystemLogsReportType
    }[]
}
export type SystemLogReportBalanceEntry = {
    eGuid: string,
    eFName: string,
    eMName: string,
    eLName: string,
    typeGuid: string,
    ptoBalance: {
        balance: number,
        delta: number,
        comment: string | null
    }
}
export type SystemLogReportPtoBalanceImportPTOType = {
    guid: string,
    name: string,
    description: string,
    status: string,
    dateCreated: Date,
    dateUpdated: Date,
    employeeCanRequest: boolean,
    employeeCanView: boolean,
    useDays: boolean
}
export type SystemLogsResponse = {
    logs: SystemLogsEntry[],
    totalLogsCount: number
}
export type SystemLogsEntry = {
    guid: string,
    type: SystemLogsReportType,
    dateCreated: Date,
    errorCount: number
}
export type SystemLog = {
    guid: string,
    type: SystemLogsReportType,
    dateCreated: Date,
    errorCount: number
}

export type ReportPreviewRequest = {
    exportType?: 'html' | 'csv' | 'xls' | 'pdf' | 'xlsx',
    reportParams: ReportParams
}

export type ReportPreviewResponse = {
    stuff: any
}
export type ReportResponse = {
    stuff: any
}

export type ReportTemplateQueryParams = {
    suppressNewTableConversion?: boolean,
    templateName?:string,
}

export type ReportTemplateQueryResponse = {
    reports:ReportTemplate[],
}