import React, {useRef,useEffect} from 'react';
import {useUID} from 'react-uid';

interface VCheckboxProps extends React.HTMLProps<HTMLInputElement>
{
    id?:string,
    inputRef?: any,
    checked?:boolean,
    toggleChecked?: (checked:boolean, toggleData?:any) => void,
    children?:any,
    childClassName?:string,
    busy?: boolean,
    toggleData?: any,
    indeterminate?: boolean,
    useBootstrapWrapperClass?: boolean
}

export function VCheckbox(props:VCheckboxProps)
{
    let {inputRef, checked, toggleChecked, children, childClassName, busy, toggleData, indeterminate, useBootstrapWrapperClass=true, ...inputProps} = props;
    const uid = useUID();
    const internalRef = useRef();
    const inputsActualRef = inputRef ? inputRef : internalRef;

    //control the state of the indeterminate rendering
    useEffect(() => {
        if(inputsActualRef.current)
        {
            if(indeterminate !== undefined && inputsActualRef.current.indeterminate !== undefined)
            {
                inputsActualRef.current.indeterminate = indeterminate;
            }
        }
    },[inputsActualRef, indeterminate])
    

    const checkId = props.id ? props.id : uid;
    const chkJsx = 
    <div className={useBootstrapWrapperClass ? "checkbox" : ""}>
        <label className={childClassName}>
            <input 
                id={checkId} 
                type="checkbox" 
                ref={inputsActualRef} 
                checked={checked} 
                onChange={(e)=> {if (toggleChecked) toggleChecked(e.target.checked, toggleData)}} 
                {...inputProps}/>
            {props.children && <>{' '}{props.children}</>}        
        </label>
    </div>
    // <>
    //     <input 
    //         id={checkId} 
    //         type="checkbox" 
    //         ref={inputsActualRef} 
    //         checked={checked} 
    //         onChange={(e)=> {if (toggleChecked) toggleChecked(e.target.checked, toggleData)}} 
    //         {...inputProps}/>
    //     {props.children && <>&nbsp;
    //     <label htmlFor={checkId} className={childClassName}>{props.children}</label>
    //     </>}
    // </>;
    
    if(busy)
    {
        return(<span className="vloading">{chkJsx}</span>);
    }
    return chkJsx; //normal check
}