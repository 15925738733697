import { ApiGuid } from "./ApiGuid";

export const CompanyFileResourceTypeList = [
    'customer',
    'employee',
    'job'
] as const;
export type CompanyFileResourceType = (typeof CompanyFileResourceTypeList)[number];

export const CompanyFileCategoryTypeList = [
    'employeePhotoId',
    'companyLogo',
] as const;
export type CompanyFileCategoryType = (typeof CompanyFileCategoryTypeList)[number];

type BaseMetaData = {
    mimeType?: string
}
type PhotoIdMetaData = BaseMetaData & {
    aws?: {
        companyId?: boolean|null     //true, if in employee id photo in company id pool
    }
}
export type CompanyFileMetaData<T extends CompanyFileCategoryType> = 
    T extends 'employeePhotoId' ? PhotoIdMetaData : BaseMetaData
export function isPhotoIdFile(file:CompanyFile):file is CompanyFile<'employeePhotoId'>
{
    return file.category === 'employeePhotoId';
}

export const CompanyFileStatusEnum = [
    'pending',
    'assigned',
    'deleted',
] as const;
export type CompanyFileStatus = (typeof CompanyFileStatusEnum)[number];

export type FileRef = {
    guid: ApiGuid
    key?: string
    url?: string            //read only - not settable directly from api
}

export type CompanyFile<T extends CompanyFileCategoryType=CompanyFileCategoryType>  = {
    guid: ApiGuid,
    resourceType: CompanyFileResourceType
    category: T,
    resourceGuid: ApiGuid|null,              //employee/job/etc guid or null if not assigned yet
    displayName: string,
    sizeBytes: number,
    fileLocation: string,
    downloadUrl?: string,                   //present if requested + allowed
    metaData: CompanyFileMetaData<T>,
    status: CompanyFileStatus,
    createDate: string,
    createEmployeeGuid: ApiGuid|null,
    updateDate: string,
    updateEmployeeGuid: ApiGuid|null,
}

export type CompanyFileForCreate =  Partial<Omit<CompanyFile,'guid'|'fileLocation'|'status'|'createDate'|'createdEmployeeGuid'|'updateDate'|'updateEmployeeGuid'>> 
    & Required<Pick<CompanyFile,'resourceType'|'category'|'displayName'|'sizeBytes'|'metaData'>>

export type CompanyFileForUpdate = Partial<Pick<CompanyFile,'displayName'|'metaData'>> 
& Required<Pick<CompanyFile,'guid'>>

export type CompanyFileCreateRespone = {
    file: CompanyFile,
    uploadUrl: string,
    expires: number,
}

export const CompanyFileCategoryEmployeeLists = ['employeePhotoId'] as const;
export const CompanyFileCategoryCustomerLists:ReadonlyArray<Extract<CompanyFileCategoryType, 'companyLogo'>> = ['companyLogo'] as const;

//this will type narrow accordingly
export type ResourceTypeCatergoryCombo = {
    resourceType: CompanyFileResourceType,
    category: CompanyFileCategoryType,
} 
export type ListCompanyFilesRequestParams = 
{
    signUrls?: boolean,             //if true, api will sign urls for downloading
    resourceGuid: ApiGuid,
}  & ResourceTypeCatergoryCombo;

export type ListCompanyFilesResponse = {
    files: CompanyFile[]
}
export type UpdateCompanyFileRequestParams = {
    files: CompanyFileForUpdate[]
}
export type CommitCompanyFileRequestParams = {
    fileGuids: ApiGuid[]
}
export type DeleteCompanyFileRequestParams = {
    fileGuids: ApiGuid[]
}



export type FaceBoundingBox = {
    height: number,
    width: number,
    top: number,
    left: number
}
export type FaceInfo = {
    good: true,
} | {
    good: false,
    rejectReason: string
}

export type VFace = {
    boundingBox: FaceBoundingBox,
} & FaceInfo

export type ChangePhotoFacialRecognitionStatusRequest = {
    employeeGuid: ApiGuid,
    fileGuid: ApiGuid,
    status: "on"|"off" //on - used for identification at clock in/out
}
export type ChangePhotoFacialRecognitionStatusResponse = {
    success: true
} | ({
        success: false
        errorMessage: string
    } & ({
        code: 'tooManyFaces',
        faces: VFace[]
    } | {
        code: 'missingFace'|'notEnabled',
    } | {
        code: 'insufficientQuality'
        faces: VFace[]
    } | {
        code: 'failedToAssociateFace'|'userMatchConfidenceTooLow'
        confidence: number,
        reasons: string[]
    } | {
        code: 'unknown'|'notInitialized'       
    } | {
        code: 'faceInUseInOtherUser',
        others: {
            similarity: number
            employeeGuid: ApiGuid
        }[]
    })
)

