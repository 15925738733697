import { ApiGuid } from './ApiGuid';
import { ClockEvent } from './ClockEvent';
import { PayrollSettings, TimezoneString } from './Datetime';
import { Employee } from './Employee';
import { ThingStatus } from './Thing';
import { ClockEventForCreate } from './Timesheet';
import type moment from 'moment-timezone';
import { Job } from './Job';

export enum PtoRecordStatus
{
    Pending='pending',
    Approved='approved',
    Declined='declined',
    Deleted='deleted'
}
export const PtoRecordStatusValues:PtoRecordStatus[] = Object.values(PtoRecordStatus);
export enum PtoRecordSortBy
{
    DateCreated='dateCreated',
    DateUpdated='dateUpdated',
    DateStart='dateStart',
    DateEnd='dateEnd'
}

enum PtoRecordSortDirection
{
    Ascending='ascending',
    Descending='descending'
}
export interface ApiParamsPtoQuery
{
    guids?: ApiGuid[],
    status?: PtoRecordStatus[]|null
    employeeGuids?: ApiGuid[],
    groupGuids?: ApiGuid[],
    ptoTypeGuids?: ApiGuid[],
    searchPeriod?: {                    //customer TZ?
        start: string|null,
        end: string|null,
    }
    offset?: number,
    limit?: number,
    sortBy?: PtoRecordSortBy
    sortDirection?: PtoRecordSortDirection
    includeAllPendingRecords?: boolean,
    includeSelf?: boolean,
    teamView?:boolean
}

export interface ApiPtoQueryResponse
{
    records: PtoRecord[],
    totalRecordsCount: number
}

export type PtoRecordDayInfo =
{
    recordDate: string,             //"2019-10-01T00:00:00.000Z"    //Todo: this looks in customerTZ?
    timeRequestedSeconds: number,   //28800 - lenght of PTO to be utilized in this range
    flags?: number,                  //0                     
    createDate?: string,             //"2019-12-29T15:17:20.000Z" todo: is this UTC?
}

export const PTO_CONVERT_HOURS = 3600;
export const PTO_CONVERT_DAYS = 28800;
export const getPtoConversionRate = (useDays:boolean|undefined)=>useDays?PTO_CONVERT_DAYS:PTO_CONVERT_HOURS;
export const getPtoUnitLabel = (useDays:boolean|undefined)=>useDays?'days':'hours';
export const getPtoConvertedTime = (useDays:boolean|undefined, time:number) => {
    return Math.round(time*100/(getPtoConversionRate(useDays)))/100;
}
export const getPtoTimeWithUnits = (useDays:boolean|undefined, time:number) => {
    const value = getPtoConvertedTime(useDays,time);
    return value + ' ' + (useDays?'day':'hour') + (value==1 ? '':'s');
}

export type PtoRecord =
{
    guid: ApiGuid,                  //"e270bce8-585d-4b92-aba0-fb3233948d7b"
    ptoTypeGuid: ApiGuid,           //"866ce2b8-6ce6-4881-b287-54f64e237560"
    ptoTypeName: string,            //"Vacation" - user defined types
    useDays: boolean,               // PTO Type is in days, not hours.
    employeeGuid: ApiGuid,              //"95cfc3d1-684c-4048-89d6-4b7c1de572a2"
    firstName: string,              //employee first name - helper string "Eddard"
    lastName: string,               //employee last name - helper string"Stark"
    /** @deprecated - Use dateRange instead */
    startDate: string,
    /** @deprecated - Use dateRange instead */
    endDate: string,
    dateRange: { start: string, end: string },  
    status: PtoRecordStatus,        //"approved" 
    message: string,                //msg from ee when creating request - "Trip to kings landing"
    createDate: string,               //UTC? "2019-12-29T15:17:20.000Z"
    updateDate: string,               //UTC? "2019-12-29T15:17:27.000Z"
    processedByEmployeeFirstName: string|null,   //"Eddard" - is there a guid for this user?
    processedByEmployeeLastName: string|null,    //"Stark"
    days: PtoRecordDayInfo[]        //each day in the range's info/hours
    expired: boolean,               // True if the record has been approved and is in the past.
}

export type PtoRecordForCreate = Pick<PtoRecord, "ptoTypeGuid"|"employeeGuid"|"dateRange"|"days"> & Partial<Pick<PtoRecord,"message">>
export type PtoRecordForUpdate = Pick<PtoRecord, "guid"> & Partial<Pick<PtoRecord,"status"|"message"|"dateRange"|"days">>
export type PtoRecordForCreateOrUpdate = PtoRecordForCreate | PtoRecordForUpdate;

export type PtoEmailSettingsOption = 'disabled'|'managersOnly'|'adminsOnly'|'managersAndAdmins';
export const PtoEmailSettingsOptions:PtoEmailSettingsOption[] = ['disabled','managersOnly','adminsOnly','managersAndAdmins'];

export type PtoSettings = {
    enabled: boolean,
    emailNotification: PtoEmailSettingsOption,
    notifyWithEmail: boolean,
    notifyWithPush: boolean,
    calendarColor: string,
}

export type PtoTypeJsonSettings = {
    createShifts?:boolean,
    forceShiftCreateDelete?:boolean,
    preApproveShifts?:boolean,
    defaultJobGuid?:ApiGuid|null,
    forceDefaultJob?:boolean,
    defaultServiceItemGuid?:ApiGuid|null,
    forceDefaultServiceItem?:boolean,
    requireComments?: boolean,
}

export type PtoType = {
    guid: ApiGuid,
    name: string,
    description: string|null,
    status: ThingStatus,
    dateCreated: string,
    dateUpdated: string,
    employeeCanRequest: boolean,
    employeeCanView: boolean,
    useDays: boolean,
    jsonSettings: PtoTypeJsonSettings|null,
}

type PtoTypeAllOptional = Partial<Omit< PtoType, 'dateCreated'|'dateUpdated'>>;
export type PtoTypeForCreate = Omit<PtoTypeAllOptional & Required<Pick< PtoType, 'name'>>, 'guid'>;
export type PtoTypeForUpdate = PtoTypeAllOptional & Required<Pick< PtoType, 'guid'>>;
export type PtoTypeForCreateOrUpdate = PtoTypeForCreate|PtoTypeForUpdate;
export function ptoTypeIsForUpdate(ptoType:PtoTypeAllOptional):ptoType is PtoTypeForUpdate
{
    return typeof(ptoType.guid) !== 'undefined';
}
export function ptoTypeIsForCreate(ptoType:PtoTypeAllOptional):ptoType is PtoTypeForCreate
{
    return !ptoTypeIsForUpdate(ptoType);
}


// This type doesn't exist except in the audit logs.  Ideally it would make a better API interface
// if the /pto/accrual/assignment route ever gets rewritten.
export type PtoTypeAccrualRuleAssignment = {
    default: ApiGuid|"manual"|"n/a",
    groups: Record<ApiGuid,ApiGuid>
    employees: Record<ApiGuid,ApiGuid>
}

export interface ApiPtoTypeResponse
{
    types: PtoType[],
}

export type PtoBalance = PtoType & {
    balance: number,
    pendingBalance: number,
    futureApprovedBalance: number,
}

export type PtoBalanceQuery = {
    employeeGuid?: ApiGuid,
    ptoTypeGuid?: ApiGuid,
    showNonApplicableBalances?: boolean,
    status: ThingStatus[],
}

export type PtoEmployeeBalance =
{
    employee: {
        fullName: string,
        firstName: string,
        middleName: string|null,
        lastName: string|null,
        guid: ApiGuid,
        status: ThingStatus,
    },
    ptoTypes: PtoBalance[],
}
export interface ApiPtoBalanceResponse
{
    balances:PtoEmployeeBalance[]
}

export type PtoBalanceHistoryQuery = {
    employeeGuid?: ApiGuid,
    ptoTypeGuid?: ApiGuid,
    sortDirection?: 'ascending'|'descending'
}

export type PtoBalanceHistory = {
    modifiedByEmployee: {
        guid: ApiGuid,
        fullName: string,
    }|null,
    ptoRecordGuid: ApiGuid|null,
    balance: number,
    delta: number,
    comment: string|null,
    dateCreated: string,
}

export interface ApiPtoBalanceHistoryResponse
{
    history:PtoBalanceHistory[]
}

export type PtoRecordCancelRequest =
{
    ptoRecordGuid: ApiGuid, 
    deleteEvents: boolean,
    forceDeleteOfApprovedEvents?: boolean,
    comment?: string,
}

export type PtoRecordSetStatusRequest =
{
    ptoRecordGuid: ApiGuid,
    status?: PtoRecordStatus,
    clockEvents?: ClockEventForCreate[],
    deleteEvents?: boolean,
    forceDeleteOfApprovedEvents?: boolean,
    comment?: string,
}

export type PtoBalanceEditRequest = {
    currentBalance: number,
    finalBalance: number,
    comment: string,
};

export type PtoBalanceImportResponse = {
    durationImportSecs: number,
    employeesProcessed: number,
    employeesUniqueProcessed: number,
    ptoTypesProcessed: number,
}

export type AccrualTypeStrings = 'none'|'annual'|'pay_period'|'hours_worked';

export enum AccrualTypeOptionEnum {
    Year = 0,
    PayPeriod = 1,
}

export type PtoSeniorityTimeUnits = 'days'|'months'|'years';

export type PtoSettingsAccrualRuleSeniority = {
    timespan: number,
    timeunits: PtoSeniorityTimeUnits,
    accrualAmountSecs: number,
    // In an attempt to undo past mistakes.  accrualPeriodTypeOption is split into accrualPeriodTypeOption and accrualPeriodSecs 
    // so the variable isn't being used for multiple things.
    accrualPeriodTypeOption: AccrualTypeOptionEnum,     // Treat accrualAmountSecs as either annual or payperiod when type is per pay period.
    accrualPeriodSecs: number,                          // Number of seconds to divide the accrualAmountSecs when type is hourly.
    jobExceptions: PtoSettingsAccrualRuleHoursJobException[],
    timeTypeAdjustments: PtoSettingsAccrualRuleHoursTimeTypeAdjustment[],

    resetBalanceOnAnniversary: boolean,
    maxCarryOverOnAnniversarySecs: number,
    maxBalanceSecs: number|null,
}

export type PtoSettingsJSON = {
    // job and timeType values are now stored in seniorty array

    // jobExceptions: PtoSettingsAccrualRuleHoursJobException[],
    // timeTypeAdjustments: PtoSettingsAccrualRuleHoursTimeTypeAdjustment[],
    seniority: PtoSettingsAccrualRuleSeniority[],
    prorateAlgorithm: 'daily'|'weekdayOnly'|'none',
}

export type PTOAccrualRule ={
    guid: ApiGuid,
    name: string,
    description: string|null,
    settingsJSON: PtoSettingsJSON,
    ptoTypeGuid: ApiGuid,
    ruleTemplateGuid: ApiGuid,
    // accrualAmountSecs: number|null,                                  // Moved into PtoSettingsAccrualRuleSeniority
    accrualPeriodType: AccrualTypeStrings|null,
    // accrualPeriodTypeOption: AccrualTypeOptionEnum|number|null,      // Moved into PtoSettingsAccrualRuleSeniority and split into accrualPeriodTypeOption and accrualPeriodSecs
    accrualDelayDays: number,
    accrualAnniversaryDate: string|null,                                // can be NULL, YYYY-MM-DD or "hire", use by annual accrual and also to control the reset balance date.
    //resetBalanceOnAnniversary: boolean,                               // Moved into PtoSettingsAccrualRuleSeniority
    //maxCarryOverOnAnniversarySecs: number,                            // Moved into PtoSettingsAccrualRuleSeniority
    //maxBalanceSecs: number|null,                                      // Moved into PtoSettingsAccrualRuleSeniority
    allowNegativeBalance: boolean,
    dateCreated: string,
    dateUpdated: string,
}

export type QueryPtoTypeRulesResponse = {
    rules:PTOAccrualRule[],
}

type PtoAccrualRuleAllOptional = Partial<Omit< PTOAccrualRule, 'dateCreated'|'dateUpdated'>>;
export type PtoAccrualRuleForCreate = Omit<PtoAccrualRuleAllOptional & Required<Pick< PTOAccrualRule, 'name'|'ptoTypeGuid'|'ruleTemplateGuid'>>, 'guid'>;
export type PtoAccrualRuleForUpdate = PtoAccrualRuleAllOptional & Required<Pick< PTOAccrualRule, 'guid'>>;
export type PtoAccrualRuleForCreateOrUpdate = PtoAccrualRuleForCreate|PtoAccrualRuleForUpdate;
export function ptoAccrualRuleIsForUpdate(ptoAccrualRule:PtoAccrualRuleAllOptional):ptoAccrualRule is PtoAccrualRuleForUpdate
{
    return typeof(ptoAccrualRule.guid) !== 'undefined';
}
export function ptoAccrualRuleIsForCreate(ptoAccrualRule:PtoAccrualRuleAllOptional):ptoAccrualRule is PtoAccrualRuleForCreate
{
    return !ptoAccrualRuleIsForUpdate(ptoAccrualRule);
}



export type PtoAccrualAssignmentParams = 
{
    employeeGuid: ApiGuid|null,
    groupGuid: ApiGuid|null,
    ptoTypeGuid: ApiGuid,
    accrualRuleGuid: ApiGuid|null,
}

export const NOT_APPLICABLE_ACCRUAL_RULE = '00000000-0000-0000-0000-000000000000';
export const PTO_ACCRUAL_ANNUAL_RULE = '22dc259d-3b1a-481f-9c91-60f969112671';
export const PTO_ACCRUAL_PER_PAY_PERIOD_RULE = 'db6749bb-c2df-4620-935d-8635a66a798e';
export const PTO_ACCRUAL_HOURLY_RULE = '1324b8b4-c77d-46ba-8b4e-e72024ae5578';
export type PTOAccrualRuleAssignment = {
    employee:{
        guid:ApiGuid|null,
        fullName:string,
    }|null,
    group:{
        guid:ApiGuid|null,
        name:string,
    }|null
    ptoTypeGuid:ApiGuid,
    ptoAccrualRuleGuid:ApiGuid|null,
    dateCreated:string,
    dateUpdated:string,
}

export type QueryPtoTypeRuleAssignmentResponse = {
    assignments: PTOAccrualRuleAssignment[],
    totalCount: number,
}

export type SandBoxCommands = 'getClockEventsSearchPeriod'|'getNextTriggerDate'|'calculateAccrual';

export interface SandBoxDataParam {
    accrualDate:moment.Moment,
    accrualState?:{
        isTriggeredAnniversary: boolean,
        isTriggeredPayPeriod: boolean,
    },
    currentBalance?: number,
    timeZone: TimezoneString,
    payrollSettings: PayrollSettings,
    clockEvents?: ClockEvent[],
/*
    ptoType: ptoType,*/
    jobHash?: Record<string,Job>,
}

export type SearchPeriodResponse = {
    period:{start:Date,end:Date}|null,
    errors:number[],
}
export type TriggerDateResponse = {
    nextTrigger: moment.Moment|null,
    state:
    {
        isTriggeredPayPeriod: boolean,
        isTriggeredAnniversary: boolean,
    },
    errors: number[]
}

export type BalanceEntry = {
    secondsAccrued: number;
    comment: string;
}

export type AccrualResponse = {
    balanceEntries: BalanceEntry[],
    nextAccrualDate: Date|null,
    state: TriggerDateResponse['state']|null,
    errors: TriggerDateResponse['errors'],
}

export interface SandBox {
    employeeParam: Employee,
    ptoType: PtoType,
    ptoAccrualRule: PTOAccrualRule,
    dataParam: SandBoxDataParam,
    searchPeriod?: SearchPeriodResponse,
    triggerDate?: TriggerDateResponse,
    accrual?: AccrualResponse,
    command: SandBoxCommands,
}

export type PtoSettingsAccrualRuleHoursJobException = {
    hours: number,
    perHours: number,
    jobGuids: ApiGuid[],
}

export type PtoSettingsAccrualRuleHoursTimeTypeAdjustment = {
    timeTypeId: number, 
    adjustmentRate: number,
} 