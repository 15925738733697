import { ApiGuid } from "./ApiGuid";
import { PaginationRequest, PaginationResponse } from './Pagination';
import { JSONType } from "./utility/jsonTypes";

export const AuditTrailCategoryList = [
    'employee','employeeIntegrationMapping',
    'group','groupIntegrationMapping',
    'job','jobIntegrationMapping', 'jobRule',
    'serviceItem','serviceItemIntegrationMapping', 'serviceItemRule',
    'payrollItem', 'payrollItemIntegrationMapping', 'payrollItemRule',
    'scheduling',
    'customField',
    'settings',
    'geofence',
    'kiosk',
    'ptoType',
    'ptoAccrualRule',
    'ptoAccrualRuleAssignments',
    'company',
    'devices',
    'reportTemplates'
] as const;

type AuditCategoryTuple = typeof AuditTrailCategoryList;
export type AuditTrailCategory = AuditCategoryTuple[number];
export function isAuditTrailCategory(category:any):category is AuditTrailCategory
{
    return AuditTrailCategoryList.indexOf(category) >= 0;
}

const AuditTrailEmployeeSubCategoryList = ['create','update', 'fileCreate', 'fileDelete', 'fileUpdate'] as const;
const AuditTrailEmployeeIntegrationMappingSubCategoryList = ['create','update','delete'] as const;
const AuditTrailJobSubCategoryList = ['create','update'] as const;
const AuditTrailJobIntegrationMappingSubCategoryList = ['create','update','delete'] as const;
const AuditTrailJobRuleSubCategoryList = ['create','update','delete'] as const;
const AuditTrailGroupSubCategoryList = ['create','update', 'delete'] as const;
const AuditTrailGroupIntegrationMappingSubCategoryList = ['create','update','delete'] as const;
const AuditTrailServiceItemSubCategoryList = ['create','update'] as const;
const AuditTrailServiceItemIntegrationMappingSubCategoryList = ['create','update','delete'] as const;
const AuditTrailServiceItemRuleSubCategoryList = ['create','update','delete'] as const;
const AuditTrailPayrollItemSubCategoryList = ['create','update'] as const;
const AuditTrailPayrollItemIntegrationMappingSubCategoryList = ['create','update','delete'] as const;
const AuditTrailPayrollItemRuleSubCategoryList = ['create','update','delete'] as const;
const AuditTrailSchedulingCategoryList = [
    'shiftCreate','shiftUpdate','shiftDelete', 
    'templateCreate','templateUpdate','templateDelete',
    'templateShiftCreate','templateShiftUpdate','templateShiftDelete', 
] as const;
const AuditTrailCustomFieldSubCategoriesList = [ 'create','update','delete', 'move' ] as const;
const AuditTrailSettingsSubCategoriesList = ['scheduling','companySettings', 'permissionSettings', 'payrollSettings', 'timeSettings', 'timeManipulationSetting', 'scheduledDeductions', 'loginAlerts','samlSettings','apiSettings','ssoGoogleSettings','tpaSettings','facialRecognitionSettings'] as const;
const AuditTrailGeofenceRuleSubCategoryList = ['create','update','delete'] as const;
const AuditTrailKioskSubCategoryList = ['register','update', 'delete'] as const;
const AuditTrailPtoTypeSubCategoryList = ['create','update'] as const;
const AuditTrailPtoAcrrualRuleSubCategoryList = ['create','update','delete'] as const;
const AuditTrailPtoAcrrualRuleAssignmentsSubCategoryList = ['create','update','delete'] as const;
const AuditTrailCompanySubCategoryList = ['fileCreate', 'fileDelete', 'fileUpdate'] as const;
const AuditTrailDeviceSubCategoryList = ['update'] as const;
const AuditTrailReportTemplatesSubCategoryList = ['create','update','delete'] as const;
export const AuditTrailSubCategoryLists:Record<AuditTrailCategory,readonly string[]> = {
    "employee": AuditTrailEmployeeSubCategoryList,
    "employeeIntegrationMapping" : AuditTrailEmployeeIntegrationMappingSubCategoryList,
    "job": AuditTrailJobSubCategoryList, 
    "jobIntegrationMapping": AuditTrailJobIntegrationMappingSubCategoryList,
    "jobRule": AuditTrailJobRuleSubCategoryList,
    "group": AuditTrailGroupSubCategoryList,
    "groupIntegrationMapping": AuditTrailGroupIntegrationMappingSubCategoryList,
    "serviceItem": AuditTrailServiceItemSubCategoryList,
    "serviceItemIntegrationMapping": AuditTrailServiceItemIntegrationMappingSubCategoryList,
    "serviceItemRule": AuditTrailServiceItemRuleSubCategoryList,
    "payrollItem": AuditTrailPayrollItemSubCategoryList,
    "payrollItemIntegrationMapping": AuditTrailPayrollItemIntegrationMappingSubCategoryList,
    "payrollItemRule": AuditTrailPayrollItemRuleSubCategoryList,
    "scheduling": AuditTrailSchedulingCategoryList,
    "customField": AuditTrailCustomFieldSubCategoriesList,
    "settings": AuditTrailSettingsSubCategoriesList,
    "geofence": AuditTrailGeofenceRuleSubCategoryList,
    "kiosk": AuditTrailKioskSubCategoryList,
    "ptoType": AuditTrailPtoTypeSubCategoryList,
    "ptoAccrualRule": AuditTrailPtoAcrrualRuleSubCategoryList,
    "ptoAccrualRuleAssignments": AuditTrailPtoAcrrualRuleAssignmentsSubCategoryList,
    "company": AuditTrailCompanySubCategoryList,
    "devices": AuditTrailDeviceSubCategoryList,
    "reportTemplates": AuditTrailReportTemplatesSubCategoryList,
}

type AuditTrailEmployeeSubCategories = (typeof AuditTrailEmployeeSubCategoryList)[number];
type AuditTrailEmployeeIntegrationMappingSubCategories = (typeof AuditTrailEmployeeIntegrationMappingSubCategoryList)[number];
type AuditTrailJobSubCategories = (typeof AuditTrailJobSubCategoryList)[number];
type AuditTrailJobIntegrationMappingSubCategories = (typeof AuditTrailJobIntegrationMappingSubCategoryList)[number];
type AuditTrailJobRuleSubCategories = (typeof AuditTrailJobRuleSubCategoryList)[number];
type AuditTrailGroupSubCategories = (typeof AuditTrailGroupSubCategoryList)[number];
type AuditTrailGroupIntegrationMappingSubCategories = (typeof AuditTrailGroupIntegrationMappingSubCategoryList)[number];
type AuditTrailServiceItemSubCategories = (typeof AuditTrailServiceItemSubCategoryList)[number];
type AuditTrailServiceItemIntegrationMappingSubCategories = (typeof AuditTrailServiceItemIntegrationMappingSubCategoryList)[number];
type AuditTrailServiceItemRuleSubCategory = (typeof AuditTrailServiceItemRuleSubCategoryList)[number];
type AuditTrailSchedulingSubCategories = (typeof AuditTrailSchedulingCategoryList)[number];
type AuditTrailPayrollItemSubCategories = (typeof AuditTrailPayrollItemSubCategoryList)[number];
type AuditTrailPayrollItemIntegrationMappingSubCategories = (typeof AuditTrailPayrollItemIntegrationMappingSubCategoryList)[number];
type AuditTrailPayrollItemRuleSubCategory = (typeof AuditTrailPayrollItemRuleSubCategoryList)[number];
type AuditTrailCustomFieldSubCategories = (typeof AuditTrailCustomFieldSubCategoriesList)[number];
type AuditTrailSettingsSubCategories = (typeof AuditTrailSettingsSubCategoriesList)[number];
type AuditTrailGeoenceSubCategories = (typeof AuditTrailGeofenceRuleSubCategoryList)[number];
type AuditTrailKioskSubCategories = (typeof AuditTrailKioskSubCategoryList)[number];
type AuditTrailPtoTypeSubCategory = (typeof AuditTrailPtoTypeSubCategoryList)[number];
type AuditTrailPtoAcrrualRuleSubCategory = (typeof AuditTrailPtoAcrrualRuleSubCategoryList)[number];
type AuditTrailPtoAcrrualRuleAssignmentsSubCategory = (typeof AuditTrailPtoAcrrualRuleAssignmentsSubCategoryList)[number];
type AuditTrailCompanySubCategory = (typeof AuditTrailCompanySubCategoryList)[number];
type AuditTrailDeviceSubCategory = (typeof AuditTrailDeviceSubCategoryList)[number];
type AuditTrailReportTemplatesSubCategory = (typeof AuditTrailReportTemplatesSubCategoryList)[number];

// type AuditTrailSubCategory
// type AuditTrailEmployeeSubCategories = 'create' | 'update' | 'login' | 'recentActivity'; //potentially not real extras here for demo purpose;
// type AuditTrailJobSubCategories = 'create' | 'update';
export type AuditTrailSubCategory<X extends AuditTrailCategory> = 
    X extends "employee" ? AuditTrailEmployeeSubCategories :
    X extends "employeeIntegrationMapping" ? AuditTrailEmployeeIntegrationMappingSubCategories :
    X extends "job" ? AuditTrailJobSubCategories :
    X extends "jobIntegrationMapping" ? AuditTrailJobIntegrationMappingSubCategories :
    X extends "jobRule" ? AuditTrailJobRuleSubCategories :
    X extends "group" ? AuditTrailGroupSubCategories :
    X extends "groupIntegrationMapping" ? AuditTrailGroupIntegrationMappingSubCategories :
    X extends "serviceItem" ? AuditTrailServiceItemSubCategories :
    X extends "serviceItemIntegrationMapping" ? AuditTrailServiceItemIntegrationMappingSubCategories :
    X extends "serviceItemRule" ? AuditTrailServiceItemRuleSubCategory :
    X extends "scheduling" ? AuditTrailSchedulingSubCategories :
    X extends "payrollItem" ? AuditTrailPayrollItemSubCategories :
    X extends "payrollItemIntegrationMapping" ? AuditTrailPayrollItemIntegrationMappingSubCategories :
    X extends "payrollItemRule" ? AuditTrailPayrollItemRuleSubCategory :
    X extends "customField" ? AuditTrailCustomFieldSubCategories :
    X extends "settings" ? AuditTrailSettingsSubCategories :
    X extends "geofence" ? AuditTrailGeoenceSubCategories :
    X extends "kiosk" ? AuditTrailKioskSubCategories :
    X extends "ptoType" ? AuditTrailPtoTypeSubCategory :
    X extends "ptoAccrualRule" ? AuditTrailPtoAcrrualRuleSubCategory :
    X extends "ptoAccrualRuleAssignments" ? AuditTrailPtoAcrrualRuleAssignmentsSubCategory :
    X extends "company" ? AuditTrailCompanySubCategory :
    X extends "devices" ? AuditTrailDeviceSubCategory :
    X extends "reportTemplates" ? AuditTrailReportTemplatesSubCategory :
    never;

export type AuditTrailMetaData = {
    version?: string, //if a particular key needs a new renderer
    comment?: string,
    [key:string]:unknown
}
export type AuditTrailEntry<X extends AuditTrailCategory = AuditTrailCategory> = {
    requestGuid: ApiGuid|null           //null if it did not originate from an API request (system operation in background perhaps)
    category: X
    subCategory: AuditTrailSubCategory<X>
    employeeGuid: ApiGuid|null
    employeeName: string | null         //name of the editor
    ipAddress: string|null,             //null would be for a system operation
    adminUserInfo: null | { name: string, adminId?:number }  //tbd - filled out server side, as client has no way to look into it, maybe send [VeriClock Admin: ab152dfe]
    logDateTime: string, //iso date w/tz/UTC
    resourceGuid: ApiGuid|null,              //employee/job/etc guid or null if not relevant    
    metaData: AuditTrailMetaData,
    beforeObj: JSONType,
    afterObj: JSONType    
}

export type QueryAuditTrailParams = {
    pagination?:Partial<PaginationRequest>,
    searchRange: {
        start: string,  //datetime in UTC, inclusive
        end: string,    //datetime in UTC, exclusive
    },
    category?: AuditTrailCategory
    resourceGuids?: ApiGuid[]    //array always? or both ApiGuid|ApiGuid[] 
} 

export type QueryAuditTrailResponse = {
    pagination: PaginationResponse, 
    entries: AuditTrailEntry[]
}

//type used to store audit version of unique item lists, instead stored as item.id => 1 in a hashtable for direct comparison/diff
export type AuditTrailUniqueArrayInObjectForm = Record<string|number, 1>;
export type AuditObjectInUniqueArray = {
    [key:string]:unknown
}
export type AuditTrailUniqueArrayOfObjects<D extends AuditObjectInUniqueArray> = {
    [key:string]: D
}