//Notes:
// if using a component renderer - it is contained to the cell!  Must modify row styling to not have overflow hidden

//todos:
//[ ] VTable improvement - if using a component, do NOT add overflow:hidden etc styling meant for text
//  - group selector issue debugging!


import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Glyphicon } from 'react-bootstrap';
import useDebounce from '../../../../../../sharedReact/src/hooks/useDebounce';
import { BoldFirstMatchWithCount, BoldFirstMatch } from '../formatting/SearchHints';
import { VCheckbox } from '../VCheckbox';
import { VCol, VGrid, VRow } from '../VGrid';
import { PaginationWidget, ItemCountNameBar } from '../Pagination/PaginationWidget';
import { CsvCell } from '../../../../../../vericlock_api/src/types/Csv';
import { VBetterButton } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import * as CsvLib from '../../../../libs/CsvExporter';
import { GetParentRowIndexFunction, isParentGuidExpanderArray, makeParentGuidExpanderFunctions, SortRowWrapper, ParentGuidExpander } from '../../../../../../lib/src/helpers/hierarchicalSorter';
import { get as lodashGet } from 'lodash';

// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import './RowFlair.css';

// export const RowFlair:React.FC<React.PropsWithChildren<{
//     rowFlairEnabled: boolean
//     show:boolean,
//     // endCallback:() => void
// }>> = (props) => {
//     if(!props.rowFlairEnabled)
//         return <>{props.children}</>
//     return <CSSTransition 
//         in={props.show} 
//         timeout={{enter: 1000}} 
//         classNames="RowFlair" 
//         // addEndListener={props.endCallback}
//         unmountOnExit={true}
//         mountOnEnter={true}
//     >
//         {props.children}
//     </CSSTransition>
// }


// import { FieldPath } from 'react-hook-form';
export type { SortRowWrapper } from '../../../../../../lib/src/helpers/hierarchicalSorter';

type VTableHeaderRenderProps<D=unknown> = {
    column:VTableColumn<D>,
    onClick?: (e: React.MouseEvent) => void 
    sortDirection?: string|null //null if not sorting this col, otherwise direction of sort on this col
    sortPropsForAnchor?: {  //to be spread into the anchor/or thing we are clicking - needed to be extracted from the event in the onClick handler - revisit how this is done if need be
        "data-header-key": string
    }
}
export interface VTableBasicCellRenderProps<D=unknown>
{
    row: VTableRow<D>,
    rowIndex:number,
    column: VTableColumn<D>,
    columnIndex:number,
}
export interface VTableCellRenderProps<D=unknown> extends VTableBasicCellRenderProps<D>
{
    isExpanded:boolean|undefined 
    treeDepth:number|undefined,
    searchString?:string|undefined  //if a search string is typed in, it is passed in here - to allow for fancy rendering
}

type PagingOptions = {
    pageSize?: number, //if defined, overrides the default limit of defaultPageSize,
    pagingControl?: { //user of Vtable will provide paging info
        onPageChange:(pageNum: number) => Promise<void>
        totalItems: number,
        totalPages: number,
        page: number
    }
}
type PagingState<D=unknown> = {
    pagedRows:VTableRow<D>[]
    pageSize: number,
    pageCount: number,
    page: number,
    setPage:  (newPage: number) => Promise<void> //((newPage: number) => void)|((old:number) => number)
}
type SortByRowEntry<D> = (a:SortRowWrapper<D>, b:SortRowWrapper<D>) => number
// type SortByRowEntryValue<D,K extends keyof D> = (a:D[K], b:D[K]) => number
export type SortOption<D> = {
    sortCallback: SortByRowEntry<D>
    direction: 'ASC'|'DESC'|string,
    sortGlyphOrJsx?: string|((direction:string) => JSX.Element)|JSX.Element,
    defaultSort?:boolean    //when a table is constructed with sort options, the first found searching in order with default true becomes the default (will not check for 2nd and error out)
}
// import { IndeterminateCheckbox } from './IndeterminateCheckbox';
export type VTableColumn<D=unknown> =
{
    header: string
    accessor?: keyof D|((props:VTableBasicCellRenderProps<D>) => string|number) //key lookup in D, or an accessor function to retrieve it - send same params that are sent to component
    accessorResolver?: (cellValue:any) => string|number //extracts value via accessor, and passes it to accessorResolver - for indirect lookups (table of guids to render the guid things name for example)
    // maxWidth?: string //css max width key
    style?:React.CSSProperties
    // render?: CellRenderer
    headerComponent?:React.FC<VTableHeaderRenderProps<D>>
    headerComponentManagesSort?:boolean, //if true, VTable will not make header clickable for sort, header component will manage that
    component?: React.FC<VTableCellRenderProps<D>>
    valueInTitle?: boolean //put the value ALSO into the title.  Will go through 'toString()'
    //array of sort callbacks to cycle throughsort callback used as input to [].sort(props.sort)
    // direction string key, callback to do the sort, glyph icon(string) or JSX callback function for the flair for the sort
    sort?: SortOption<D>[],
    numberSort?:boolean,
    addTreeIndentFlair?:boolean //if true, we insert tree flair on this column infront of the rendering
    skipCsvExport?:boolean      //if true and if csvExport is available, this column is skipped
    csvExportValue?:(props: VTableBasicCellRenderProps<D>) => CsvCell
}

export type SelectedRowMap = {
    [key:number]:boolean //index => true map
}

export function makeSelectedRowMapDefaults(length:number, state:'all'|'none')
{
    let map:Record<number,boolean>={};
    for(let i=0; i < length; i++)
    {
        map[i] = true;
    }
    return map;
}


//invoked by the table during a search to optionally render
//a 
// export type SearchSnippetPreviewComponent<D> = React.ComponentType<{
//     item:D,
//     searchString: string    
// }>

type CustomExpandFunctions<D> = {
    doesRowHaveChildren:(row:D) => boolean
    getParentRowIndex:GetParentRowIndexFunction<D> //(row:D) => { rowIndex: number, data: D }|null
    isDescendent:(descendent:D, ancestor:D) => boolean
    getOldestAncestor:(row:SortRowWrapper<D>) => { rowIndex: number, data: D } //can return self
    getOldestSiblingAncestorsOrOldestAncestor: (a:SortRowWrapper<D>,b:SortRowWrapper<D>) => { a: SortRowWrapper<D>, b: SortRowWrapper<D> }
    areSiblings: (a:SortRowWrapper<D>,b:SortRowWrapper<D>) => boolean      //callback to indicate if two list items are siblings (common parent)
}
type VTableRowExpandOptions<D> =
{
    expandType: 'custom'|'parentGuid',
    //expandedRows and onRowExpandClicked if table user wants to control their own state for whatever reason
    expandedRows?: SelectedRowMap //row num => bool
    onRowExpandClicked?: (rowIndex:number|'toggleAll', row?:D) => void
} & 
({
    expandType: 'custom', //user provides the expansion callbacks needed to implement the expansion 
    customExpand: CustomExpandFunctions<D>
}| {
    expandType: 'parentGuid' //D extends { parentGuid: ApiGuid, guid: ApiGuid } and everything is taken care of internally to VTable
})

//internal type - passed in converted to this
type UseExpandFunctions<D> = CustomExpandFunctions<D> & {
    allExpanded:boolean,
    expandedRows: Record<number, boolean>
    onRowExpandClicked?: (rowIndex:number|'toggleAll', row?:D) => void
}
export type ChangeSelectedCallback = (selectedRows:SelectedRowMap) => void;

const defaultPageSize = 50;

export type VTableProps<D=unknown> =
{
    itemCountName?: { singular: string, plural: string },
    disabled?:boolean,  //if true, various controls on the table are disabled (selection checkboxes, pagination controls, etc)
    loading?:boolean,             //if true, loading flair is displayed
    columns: VTableColumn<D>[]
    data: D[]
    rowFlairEnabled?: boolean, //false by default - when on, a standard set of rowflair will be used (adding/removing - if using the removal capability with a rowKey)
    // removeWithFlair?: (rowKey:string|number|(keyof D) => 
    selectedRows?:SelectedRowMap,
    onSelectedChange?: ChangeSelectedCallback
    onSortChange?:(column: VTableColumn<D>, columnIndex:number, sortSettings: {
        sortBy: string;
        sortDirection: string;
    }) => void
    selectCheckboxColumnIndex?: number
    noDataElement?:React.ReactElement<any>
    rowKey?: (keyof D)|((data:D) => string|number)
    extraRowProps?: (props: {
            rowIndex:number,
            row: D,
        }) => Record<string,unknown>,
    paging?: PagingOptions
    leftOfPagingControl?:React.ReactElement<any>
    useLocalStorageForState?:{
        enabled: boolean, //if true, will store sort order, limit adjustments, other config settings in localstorage
        storageKeyPrefix: string, //will prefix internal _vtable prefix using it kv db in local storage
    }, 
    search?:{   //if defined, will filter data of table through array filter using the passed in filter: props.data.filter(props.search.filter) in useMemo callback
        makeFilter?:(searchString:string) => (row:D, index:number, array: D[]) => boolean //
        // SearchHintComponent?: SearchSnippetPreviewComponent<D>  //render snippet under row still rendering after search query filtered, to do stuff like highlight the search in the table: Phone 604-123-<b>2325</b> if the 2325 matched the phone, for example
        renderSearchHint?:(row:D, searchString:string) => JSX.Element|JSX.Element[]|null //null == no default search hint, undef == default search hint
        searchFields?: SearchField<D>[],
        searchTextBoxPlaceholder?:string,
        noResultsFoundElement?: React.ReactElement<any>|string
    },
    expandOptions?: VTableRowExpandOptions<D>
    csvExport?: {
        filename: string,
        csvExportControllerRef?: React.MutableRefObject<CsvExportController|undefined>
        hideCsvExportButton?: boolean //doing our own
    }
    noHeader?:boolean //if true - no header is rendered
}

//Active Jobs
//Job A
//Job B
//  => Job C
//  => Job F

export type SearchField<D> = { //,K extends keyof D> = {
    fieldName: string, // D extends Record<string,unknown> ? FieldPath<D> : Extract<keyof D,string>,
    //cell is D[fieldName] - nor sure how to TS that...
    isMatch?:(cell:any, searchStringLowerCase:string, row: D) => boolean //optional - if present, it is invoked, instead of checking the cell value
    stringValueForPreview?:(cell:any, searchStringLowerCase:string) => string
} & ({
    label: string,
    bodyPreview:true //|((cell:any, searchStringLowerCase:string) => React.ReactNode)
}| {
    bodyPreview:false
})
// extends Record<string,unknown>
function doesFieldMatch<D>(field:SearchField<D>, row:D, searchString: string, v:any):v is string|number
{
    //if an isMatch field is suppolied, use it instead
    const value = lodashGet(row, field.fieldName);

    if(field.isMatch) {
        if(field.isMatch(value, searchString, row))
            return true;
        return false;
    }

    // const value = row[field.fieldName];
    if(value !== null && value !== undefined) //values could be null/missing, should be allowed, or could add more to spec to expect those? vs what...throwing an exception if we didn't expect it?
    {
        if(typeof(value) === 'string')
        {
            if(value.toString().toLowerCase().indexOf(searchString) >= 0)
                return true;
        }
        else if(typeof(value) === 'number') //could assert here that the type is a string or number - not an object...
        {
            if(value.toString().indexOf(searchString) >= 0)
                return true;
        }
        else 
        {
            throw new Error(`Using a non string|number[${field.fieldName}] as a searchable field in the list`);
        }
    }
    return false;
}

function makeDefaultSearchFilter<D>(searchFields:SearchField<D>[], searchStringRaw:string)
{
    //return the filter creator
    
    //return the new filter based on the search string closure
    const searchString = searchStringRaw.trim().toLowerCase();
    if(searchString === '')
        return () => true; //all pass when empty
    return (row:D, index:number, array: D[]) => {
        
        for(const field of searchFields)
        {
            const value = lodashGet(row, field.fieldName);

            if(doesFieldMatch(field, row, searchString, value))
                return true;
        }
        return false;
    }
}
//write summarizer
//
//=> 
//Description: ....15 char job description before search string[search string bolded]15 char after....
function defaultRenderSearchHint<D>(searchFields:SearchField<D>[], row:D, searchString:string)
{ 
    const finds:JSX.Element[] = [];

    for(const field of searchFields)
    {
        if(field.bodyPreview === false) //actually defined AND false == no show
            continue;
        const value = lodashGet(row, field.fieldName);
    
        const v = field.stringValueForPreview ? field.stringValueForPreview(value, searchString) : value;
        if(doesFieldMatch(field, row, searchString, v))
        {
            finds.push(<div key={field.fieldName}>
                <BoldFirstMatchWithCount source={v.toString()} subString={searchString} label={field.label} />
            </div>)
        }
    }
    
    if(finds.length)
        return <div style={{paddingLeft:"2em"}}>{finds}</div>
    return null;        
}

type ProcessedColumn<D=unknown> = VTableColumn<D> & {
    getAttributes: () => any //todo fix
}

type SelectAllState = 'all'|'indeterminate'|'none';
function makeSelectAllFromSelectMap(selectedMap:SelectedRowMap|undefined, dataLength:number):SelectAllState
{
    if(!selectedMap)
        return 'none'; //doesn't matter
        
    let selectedCount = 0;
    let notSelectedCount = 0;

    for(let i=0; i < dataLength && (selectedCount == 0 || notSelectedCount == 0); i++)
    {
        if(selectedMap[i])
            selectedCount++;
        else
            notSelectedCount++;
    }
    if(selectedCount === dataLength)
        return 'all';
    if(notSelectedCount === dataLength)
        return 'none';
    return 'indeterminate';    
}
const defaultColumnHeaderStyles:React.CSSProperties = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
};





function useExpand<D>({expandOptions, data}:{ 
    expandOptions: VTableProps<D>['expandOptions'], 
    data: VTableProps<D>['data']
}) 
{
    const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});
    const externalExpandedRows = expandOptions?.expandedRows;
    const externalOnRowExpandClicked = expandOptions?.onRowExpandClicked;

    const expandType = expandOptions?.expandType;
    const customExpand = expandOptions?.expandType === 'custom' ? expandOptions.customExpand : undefined;
    const expandFunctions:CustomExpandFunctions<any>|undefined = useMemo(() => {
        if(!expandType || data.length === 0)
            return undefined

        if(expandType === 'parentGuid')
        {
            //we know it is an array of parentGuid / guid having items... but TS doesn't
            if(isParentGuidExpanderArray(data))
                //use internal guid/parentGuid functions
                return makeParentGuidExpanderFunctions(data);
        }
        else if(expandType === 'custom')
        {
            //use the functions provided 
            return customExpand;
        }

        throw new Error('unknown expansion type in Vtable props: ' + expandType)
    },[expandType, customExpand, data]);
  
    const onRowExpandClicked = useCallback((rowIndex:number|'toggleAll', row?:D) => { 
        if(expandFunctions)
        {
            if(!externalExpandedRows) {      //does not pass in state for expanded rows, so we manage it internally
                if(rowIndex === 'toggleAll')
                {
                    setExpandedRows(old => {
                        const anyExpanded = Object.entries(old).find(([idx,val]) => val === true);
                        if(anyExpanded !== undefined)
                        {
                            //one or more is expanded, so collapse first
                            return { }; //empty == all contracted
                        }
                        else {
                            //none expanded, so they are all contracted, so expand all
                            const newExp = {...old};
                            data.forEach( (row, ridx) => {
                                if(expandFunctions.doesRowHaveChildren(row))
                                    newExp[ridx] = true; 
                            });

                            return newExp;
                        }

                    });
                }
                else {

                    setExpandedRows(old => {
                        const currentState = old[rowIndex] === true;
                        return { ...old, [rowIndex]: !currentState } //invert the state
                    })
                }
            }
            if(externalOnRowExpandClicked)    //if passed in a click function
                externalOnRowExpandClicked(rowIndex, row);
        }
    },[expandFunctions, externalExpandedRows, externalOnRowExpandClicked, data]);

    const internalExpandOptions:UseExpandFunctions<D>|undefined = useMemo(() => {
        if(!expandFunctions) //kind of annoying extra checks for typeguard purposes - need better typing
            return undefined;

        const rowCountWithChildren = data.reduce( (prev, cur) => {
            if(expandFunctions.doesRowHaveChildren(cur))
                return prev + 1;
            return prev;
        },0);
        const expandedRowCount = Object.entries(expandedRows).reduce( (prev, [key,val]) => {
            if(val)
                return prev+1;
            return prev;
        }, 0);
        const eo:UseExpandFunctions<D>  = {
            expandedRows: externalExpandedRows ? externalExpandedRows : expandedRows,
            doesRowHaveChildren: expandFunctions.doesRowHaveChildren,
            onRowExpandClicked,
            getParentRowIndex: expandFunctions.getParentRowIndex,
            isDescendent:expandFunctions.isDescendent,
            getOldestAncestor: expandFunctions.getOldestAncestor,
            allExpanded:  rowCountWithChildren === expandedRowCount,
            areSiblings: expandFunctions.areSiblings,
            getOldestSiblingAncestorsOrOldestAncestor: expandFunctions.getOldestSiblingAncestorsOrOldestAncestor
        }
        return eo;
        
    },[expandFunctions, onRowExpandClicked, expandedRows, externalExpandedRows, data]);

    return internalExpandOptions;
}

function getNodeDepth<D=unknown>(getParentRowIndex:GetParentRowIndexFunction<D>, item:D)
{
    let depth = 0;
    let parent = getParentRowIndex(item);
    while(parent !== null)
    {
        depth++;
        parent = getParentRowIndex(parent.data); //advance to next parent
    }
    return depth;
}

function useColumns<D=unknown>(props:VTableProps<D>, additionalOptions:{
    expandFunctions:UseExpandFunctions<D>|undefined
}) 
{
    const {
        disabled,
        selectedRows,
        columns, 
        onSelectedChange,
        selectCheckboxColumnIndex=0,       
        // expandOptions:__ensureWeAreNotUsed,          
    } = props;
    const [shiftSelected, setShiftSelected] = useState<boolean>(false);
    const [lastSelectedColumn, setLastSelectedColumn] = useState<{index:number, checked:boolean}>({index:-1, checked:false});
    const expandableRowsEnabled = !!additionalOptions.expandFunctions;
    const onRowExpandClicked = additionalOptions?.expandFunctions?.onRowExpandClicked;
    const expandDoesRowHaveChildrenCallback = additionalOptions.expandFunctions?.doesRowHaveChildren;
    // const [selectedMap, setSelectedMap] = useState<SelectedRowMap>({});
    // const [selectAll, setSelectAll] = useState<SelectAllState>(makeSelectAllFromSelectMap(selectedRows, props.data.length));
    const selectAll = useMemo(() => {
        return makeSelectAllFromSelectMap(selectedRows, props.data.length);
    },[selectedRows, props.data.length]);

    const selectAllMapQuick = useMemo(() => {
        let map:SelectedRowMap = {};
        for(let i=0; i < props.data.length; i++)
        {
            map[i] = true;
        }
        return map;
    },[props.data]);
        
    let cols = useMemo(() => {

        const TableHeaderCheckComponent:React.FC = (props) => {
            const allChecked = selectAll === 'all';
            const indeterminate = selectAll === 'indeterminate';
            return <VCheckbox checked={allChecked} 
                disabled={disabled}
                useBootstrapWrapperClass={false}
                indeterminate={indeterminate}
                toggleChecked={(chk) => {
                    if(allChecked || indeterminate)
                    {
                        // setSelectAll('none');
                        if(onSelectedChange)
                            onSelectedChange({}); //clear the selection
                    }
                    else 
                    {
                        // setSelectAll('all');
                        if(onSelectedChange)
                            onSelectedChange(selectAllMapQuick); 
                    }
            }}/>
        }
        const TableCheckComponent:React.FC<VTableCellRenderProps<D>> = (cellProps) => {
            const rowToggleCheck = (checked:boolean) => {
                let newMap = {...selectedRows, [cellProps.rowIndex]:checked};
                if (shiftSelected && lastSelectedColumn.index > -1) {
                    const min = Math.min(lastSelectedColumn.index, cellProps.rowIndex);
                    const max = Math.max(lastSelectedColumn.index, cellProps.rowIndex);
                    for (let index = min; index <= max; index++) {
                        if (!lastSelectedColumn.checked) {
                            delete newMap[index];
                        } else{
                            newMap[index] = true;
                        }
                    }
                }
                setLastSelectedColumn({index:cellProps.rowIndex, checked:checked});
                if(onSelectedChange)
                    onSelectedChange(newMap);
            }
            if(!selectedRows)
                return <span>missing selectedRows controlled prop</span>

            return <VCheckbox disabled={disabled} toggleChecked={rowToggleCheck} checked={selectedRows[cellProps.rowIndex]} useBootstrapWrapperClass={false}/>
                
        }


        const SelectColumn:VTableColumn<D> = {
            header: 'chk',
            component: TableCheckComponent,
            headerComponent: TableHeaderCheckComponent,
            style: {
                width:"3%", //otherwise passed in columns could cause issues
            }
        }

        const allExpanded =  additionalOptions.expandFunctions?.allExpanded === true;
        const onClickAllExpand = () => {
            onRowExpandClicked && onRowExpandClicked('toggleAll');            
        }
        const onClickRowExpand = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            const rowIdx = e.currentTarget.attributes.getNamedItem('data-row-index');
            if(rowIdx === null)
                throw new Error('data-row-index missing on row expander element');
            const rowIndex= parseInt(rowIdx.value);
            onRowExpandClicked && onRowExpandClicked(rowIndex, props.data[rowIndex]);            
        }
        const TableHeaderExpanderComponent:React.FC = (props) => {
            const expanderGlyph = allExpanded ? 'chevron-down' : 'chevron-right';
            
            return <a onClick={onClickAllExpand}><Glyphicon glyph={expanderGlyph}/></a>            
        }
        const ExpanderColumn:VTableColumn<D> = {
            header: 'expandAll',
            headerComponent: TableHeaderExpanderComponent,
            style: {
                width:"40px", //otherwise passed in columns could cause issues
            },
            component: (props) => {
                
                if(expandDoesRowHaveChildrenCallback && expandDoesRowHaveChildrenCallback(props.row.row))
                {
                    const isExpanded = props.isExpanded; 
                    const expanderGlyph = isExpanded ? 'chevron-down' : 'chevron-right';

                    // const indent = props.treeDepth ? '&nbsp;'.repeat(props.treeDepth) : '';
                    return <><a onClick={onClickRowExpand} data-row-index={props.rowIndex}><Glyphicon glyph={expanderGlyph}/></a></>
                }
                
                return null; //empty
            }
        }

        let cols = [...columns];
        if(onSelectedChange) //if selection is on, add the checkbox column to the appropriate place
            cols.splice(selectCheckboxColumnIndex, 0, SelectColumn);
        if(expandableRowsEnabled)
            cols.splice(0, 0,ExpanderColumn);

        return cols.map(c => {
            let pc:ProcessedColumn<D> = {
                ...c,
                getAttributes: () => {
                    //apply defaults and overwrite with custom styling, if any
                    return { 
                        style: {
                            ...defaultColumnHeaderStyles,
                            ...c.style
                        }
                     }
                }
            }
            return pc;
        })
    }, [disabled, columns, onSelectedChange, selectCheckboxColumnIndex, selectAll, selectedRows,selectAllMapQuick, expandableRowsEnabled,expandDoesRowHaveChildrenCallback,onRowExpandClicked,props.data,additionalOptions.expandFunctions?.allExpanded, shiftSelected, lastSelectedColumn.index, lastSelectedColumn.checked]);
    // useTraceUpdatePropsOnlyFC('columns', 
    //     [columns, onSelectedChange, selectCheckboxColumnIndex, selectAll, selectedRows,selectAllMapQuick, expandableRowsEnabled,expandDoesRowHaveChildrenCallback,onRowExpandClicked,props.data,additionalOptions.expandOptions?.allExpanded]
    // );
    useEffect(() => {
        const windowListener = (e:KeyboardEvent) => {
            if (e.shiftKey != shiftSelected) {//Check first and only update if different. Holding a key it will hammer the event.
                setShiftSelected(e.shiftKey);
            }
        }
        window.addEventListener("keydown", windowListener);
        window.addEventListener("keyup", windowListener);
        return () => {
            window.removeEventListener('keydown',windowListener);
            window.removeEventListener('keyup',windowListener);
        }
      },[shiftSelected, setShiftSelected]
    );
    return cols;
}

// type CellRenderer = React.FC<{ value: any, row: any}>|React.ReactNode;
// type TableCell = {
//     render: ()=>React.ReactNode|string
// }
// type ProcessedRow = {
//     // getAttributes: () => any
//     row: any,
//     cells: TableCell[]
// }

type VTableCell<D=unknown> = 
{
    column: VTableColumn<D>
    getAttributes: () => any
}
export type VTableRow<D=unknown>= {
    cells: VTableCell<D>[]
    row: D,
    rowIndex: number
}

function useRows<D=unknown>({data:originalData, columns, filter, sortCallback, paging, expandFunctions, searchString}:{
    data: D[], 
    columns: ProcessedColumn<D>[], 
    filter?: (row:D, index:number, array:D[])=>boolean,
    sortCallback:null|((a:SortRowWrapper<D>, b:SortRowWrapper<D>)=>number),
    paging?: PagingOptions,
    expandFunctions?: UseExpandFunctions<D>,
    searchString: string
}) 
{
    const doesRowHaveChildren = expandFunctions?.doesRowHaveChildren;
    const getParentRowIndex = expandFunctions?.getParentRowIndex;
    const isRowExpandedMap = expandFunctions?.expandedRows;
    const getOldestAncestor = expandFunctions?.getOldestAncestor;
    const areSiblings = expandFunctions?.areSiblings;
    const isDescendent = expandFunctions?.isDescendent;
    const getOldestSiblingAncestorsOrOldestAncestor= expandFunctions?.getOldestSiblingAncestorsOrOldestAncestor;

    const [page, setPage] = useState(0);

    const onSetPagingPage = useCallback(async (page: number) => {
        if(paging?.pagingControl)
        {
            await paging.pagingControl.onPageChange(page);
        }
        setPage(page);
    },[paging?.pagingControl]);

    const rowData:SortRowWrapper<D>[] = useMemo(() => {
        return originalData.map((item, rowIndex) => {
            return {
                rowIndex,   //original row index
                data: item
            }
        });
    },[originalData]);

    let rowsFromSortAndFilter = useMemo(() => {
        // console.log('making new rows');
        const sortChain:((a:SortRowWrapper<D>,b:SortRowWrapper<D>) => number)[] = [];
        
        if(getParentRowIndex && isDescendent && getOldestAncestor && areSiblings && getOldestSiblingAncestorsOrOldestAncestor)
        {
            const A_sortUp = -1;
            const B_sortUp = 1;
            
            const expansionAwareSorter = (a:SortRowWrapper<D>,b:SortRowWrapper<D>):number => {

                if(areSiblings(a,b))
                {
                    if(sortCallback) {
                        const rTmp = sortCallback(a,b);
                        if(rTmp !== 0)
                            return rTmp;
                    }

                    return a.rowIndex - b.rowIndex;
                }
                if(isDescendent(a.data, b.data))
                    return B_sortUp; //a should be under B always
                else if(isDescendent(b.data,a.data))
                    return A_sortUp;
                
                

                //not siblings/not related - sort these two by their top-level-ancestor's sorting 
                
                const { a: oldA, b: oldB } = getOldestSiblingAncestorsOrOldestAncestor(a,b);
                
                // const oldA = getOldestAncestor(a);
                // const oldB = getOldestAncestor(b);
                if(sortCallback) {
                    const rTmp = sortCallback(oldA,oldB);
                    if(rTmp != 0)
                        return rTmp;
                    return oldA.rowIndex - oldB.rowIndex;
                } else {
                    return oldA.rowIndex - oldB.rowIndex;
                }
                
                // if(sortCallback)
                //     return(sortCallback(oldA,oldB))   

                //fall through as they are both null, or both the same parent
                return 0;
            }
            sortChain.push(expansionAwareSorter);

            sortChain.push((a,b) => {
                return a.rowIndex - b.rowIndex; //
            });
        }
        if(sortCallback) 
            sortChain.push(sortCallback);
        
        //ultimate final sorter by original order
        sortChain.push((a,b) => a.rowIndex - b.rowIndex);

        
        const superSort = (a:SortRowWrapper<D>,b:SortRowWrapper<D>) => {
            for(let i=0; i < sortChain.length; i++)
            {
                let result = sortChain[i](a, b);
                if(result != 0)
                    return result; //not same, so pass back the result
                //loop to next callback
            }
            return 0; //same
        }

        const filteredData = !filter ? rowData : rowData.filter((r,index) => filter(r.data, index, originalData));
        const sortedData = superSort.length === 0 ? filteredData : filteredData.sort(superSort);

        let sortedRows:VTableRow<D>[] = sortedData.map( (row:SortRowWrapper<D>) => { //for each row of data
            
            let pr:VTableRow<D> = {
                row: row.data,    //original data
                cells : columns.map(c => {
                    let pc:VTableCell<D> = {
                        column: c,
                        getAttributes: c.getAttributes       
                    };
                    return pc;
                }),
                rowIndex: row.rowIndex
            };
            return pr;
        });
        
//todo - if expansion is slow, could move this out to a new memo - so it only updates on changes to the expasion state
        return sortedRows;
        //filter out unexpanded rows if expansion is enabled (detected by existence of the children callback for now)
    }, [originalData, rowData, columns, filter, getParentRowIndex, isDescendent, getOldestAncestor,sortCallback, areSiblings,getOldestSiblingAncestorsOrOldestAncestor]);
    //useTraceUpdatePropsOnlyFC('VTable:rowsFromSortAndFilter rerender', [originalData, rowData, columns, filter, getParentRowIndex, isDescendent, getOldestAncestor,sortCallback, areSiblings]);

    //??? searching + expansion issue
    //  just don't do expansion filtering if search filtering is on...?
    const searchInProgress = searchString.length > 0; //this triggers a re-run of the useMemo block below only on search/no search - not relevent otherwise
    const expansionAdjustedRows = useMemo( () => {
        if(!isRowExpandedMap || !doesRowHaveChildren || !getParentRowIndex || searchInProgress) //typeguard, but also not enabled
            return rowsFromSortAndFilter;
        
        return rowsFromSortAndFilter.filter(row => {  
            //need to walk parents up to top level to make sure we aren't collapsed

            
            let parent = getParentRowIndex(row.row);
            while(parent !== null)
            {
                if(!isRowExpandedMap[parent.rowIndex])  //parent in chain NOT expanded - we are not visible
                    return false;
                parent = getParentRowIndex(parent.data); //advance to next parent
            }
            //fall through, no parent is hidden
            return true;
        });
    },[rowsFromSortAndFilter, isRowExpandedMap, doesRowHaveChildren, getParentRowIndex,searchInProgress]);


    const pagingState:PagingState<D>|undefined = useMemo( () => {
        
        if(!paging) //paging is not on
            return undefined;

        if(paging.pagingControl) //simulated paging
        {
            return {
                pageSize: 1, //doesn't matter
                pagedRows: expansionAdjustedRows, //all
                pageCount: paging.pagingControl.totalPages,
                page: paging.pagingControl.page,
                setPage:onSetPagingPage
            }
        }

        const pageSize = paging.pageSize ? paging.pageSize : defaultPageSize;        
        const pageCount = Math.ceil(expansionAdjustedRows.length / pageSize); //how many pages (final page could be partial sized)   
        const actualPage = (page >= pageCount) ? pageCount - 1 : page; //clamp page to end page if we've gone over
        const pagedRows = expansionAdjustedRows.slice(actualPage * pageSize, actualPage * pageSize + pageSize);
        return {
            pagedRows,
            pageSize,
            pageCount,
            page:actualPage,
            setPage:onSetPagingPage
        }
    }, [paging, expansionAdjustedRows, page,onSetPagingPage])

    return {
        allRows: rowsFromSortAndFilter,
        rows: pagingState ? pagingState.pagedRows : expansionAdjustedRows,
        paging: pagingState
    }
}

function extractDefaultSort<D=unknown>(columns: VTableColumn<D>[]):null|{ sortBy: string, sortDirection: string }
{
    for(let i=0; i < columns.length; i++)
    {
        const sort = columns[i].sort;
        if(sort)
        {
            for(let s=0; s < sort.length; s++)
            {
                const sortOption = sort[s];
                if(sortOption.defaultSort)
                {
                    return {
                        sortBy: columns[i].header,
                        sortDirection: sortOption.direction
                    }
                }
            }
        }
    }
    return null; //no default sort found
}

//dev helper - throw an error if a sort direction is used twice - won't work properly, need to be unique so error out at dev time
function makeSureSortIsNotGimpy<D=unknown>(column: VTableColumn<D>)
{
    if(!column.sort)
        return; 

    let sortId:Record<string,true> = {}
    column.sort.forEach(s => {
        if(sortId[s.direction]) {
            console.warn(s);
            throw new Error(`VTableColumn[${column.header}] Sort direction [${s.direction}] seen twice in sort list`);
        }
        sortId[s.direction] = true;
    });
}
function useSorting<D=unknown>({columns, onSortChange}: VTableProps<D>)
{
    // const [sortBy, setSortBy] = useState<null|string>(null);
    // const [sortDirection, setSortDirection] = useState<null|string>(null);
    const [sortSettings, setSortSettings] = useState<null|{ sortBy: string, sortDirection: string }>(() => extractDefaultSort(columns));
    
    const toggleSortBy = useCallback( (header:string) => {
        const columnIndex = columns.findIndex(c => c.header === header);
        const column = columnIndex >= 0 ? columns[columnIndex] : undefined;
        const sort = column?.sort;
        if(!column || !sort)
            throw new Error('Could not find column (or sort option) by header value: ' + header); //really shouldn't be possible if we were able to call the toggle function
        
        let newSortSettings = null;
        if(!sortSettings || sortSettings.sortBy !== header) 
        {   //not set yet, or new column
            newSortSettings = {
                sortBy: header,
                sortDirection: sort[0].direction 
            }
        }
        else {
            //fall through, we are on the same header, just advance the direction to the next option, cycling back to front of list
            const sortIndex = sort.findIndex(s => s.direction === sortSettings.sortDirection);
            const nextIndex = (sortIndex + 1) % sort.length; //cycle to next sort type, rolling back to 0
            newSortSettings =  {
                sortBy: header,
                sortDirection: sort[nextIndex].direction
            }
        }
        setSortSettings(newSortSettings);
        // setSortSettings(old => {
        //     if(!old || old.sortBy !== header) {   //not set yet, or new column
        //         return {
        //             sortBy: header,
        //             sortDirection: sort[0].direction 
        //         }
        //     }
        //     //fall through, we are on the same header, just advance the direction to the next option, cycling back to front of list
        //     const sortIndex = sort.findIndex(s => s.direction === old.sortDirection);
        //     const nextIndex = (sortIndex + 1) % sort.length; //cycle to next sort type, rolling back to 0
        //     return {
        //         sortBy: header,
        //         sortDirection: sort[nextIndex].direction
        //     }
        // });
        if(onSortChange)
            onSortChange(column, columnIndex, newSortSettings)
    },[columns, sortSettings, onSortChange]);

    // const makeSorter = useCallback(() => {
    //     const column = columns.find(c => c.header === sortBy);
    //     const sort = column?.sort;
    //     if(!column || !sort)
    //         return null;
    //     const sortOptionsForDirection = sort.find(s => s.direction === sortDirection);
    //     if(!sortOptionsForDirection)
    //         throw new Error('cannot find sort options for sort dir: ' + sortOptionsForDirection);
        
    //     return sortOptionsForDirection.sortCallback;
    // },[sortBy, sortDirection, columns]);

    const sorter = useMemo(() => {
        const column = columns.find(c => c.header === sortSettings?.sortBy);
        const sort = column?.sort;
        if(!column || !sort)
            return null;
        makeSureSortIsNotGimpy<D>(column);
        const sortOptionsForDirection = sort.find(s => s.direction === sortSettings?.sortDirection);
        if(!sortOptionsForDirection)
            throw new Error('cannot find sort options for sort dir: ' + sortOptionsForDirection);
        
        return sortOptionsForDirection.sortCallback;
    },[sortSettings?.sortBy, sortSettings?.sortDirection, columns]);
    //useTraceUpdatePropsOnlyFC('Vtable:sorter render', [sortBy, sortDirection, columns]);
    return { toggleSortBy, sortBy:sortSettings?.sortBy, sortDirection:sortSettings?.sortDirection, sorter }
}
function useSearchFilter<D=unknown>(props:VTableProps<D>, additionalProps: { 
    searchString: string
})
{
    const makeFilter = props.search?.makeFilter;
    const searchFilter = useMemo(() => {
        if(makeFilter)
            return makeFilter(additionalProps.searchString);
        else if(props.search?.searchFields)
            return makeDefaultSearchFilter(props.search?.searchFields, additionalProps.searchString);
        return undefined;
    },[additionalProps.searchString, makeFilter, props.search?.searchFields]);
    return searchFilter;
}
function useTable<D=unknown|ParentGuidExpander>(props:VTableProps<D>)
{
    const [searchStringPreDebounce, setSearchString] = useState("");
    const searchStringDebounced = useDebounce(searchStringPreDebounce, 300);
    const searchString = searchStringPreDebounce.length === 0 ? "" : searchStringDebounced.toLowerCase();
    const { toggleSortBy, sortBy, sortDirection, sorter } = useSorting(props);
    const expandFunctions = useExpand({ expandOptions: props.expandOptions, data: props.data });
    const columns = useColumns(props, {
        expandFunctions
    });
    const searchFilter = useSearchFilter(props, { searchString })
    const { allRows, rows, paging } = useRows({
        data: props.data, 
        paging: props.paging,
        columns, 
        filter: searchFilter,
        sortCallback: sorter,
        expandFunctions,
        searchString
    });
    return {
        noHeader: props.noHeader,
        disabled: props.disabled,
        columns, 
        rows, 
        allRows,
        searchString,
        setSearchString,
        toggleSortBy,
        sortBy, 
        sortDirection,
        paging,
        expandedRows: expandFunctions?.expandedRows,
        expandGetParentRowIndex: expandFunctions?.getParentRowIndex
    }//, rows}
}

function LoadingFlair()
{
    const [dotCount,setDotCount] = useState(1);
    useEffect(() => {
        const timerId = setInterval(() => {
            setDotCount(old => (old+1)%4); //0,1,2,3,0,1,2,3
        },500);
        return () => {
            clearInterval(timerId);
        }   
    })
    return <>Loading{''.padEnd(dotCount,'.')}</>
}
function getRowKey<D=unknown>(rowIndex:number, row:D, rowKey?:keyof D|((row:D)=>string|number))
{
    if(rowKey === undefined)
        return rowIndex;
     
    if(typeof(rowKey) === 'function')
        return rowKey(row);

    else if(typeof(rowKey) === 'string' || typeof(rowKey) === 'number')
    {
        if(row && typeof(row) === 'object') {
            const k = row[rowKey];
            if(typeof(k) === 'string' || typeof(k) === 'number')
                return k; //is good;
            //fall through, bad
            throw new Error('VTable row[rowKey] in object must be a string or number')
        }
        throw new Error('VTable cannot access rowKey in row - not an object/array');
    }

        
    throw new Error('unknown type of rowKey in VTable props');
}

const FullWidth:React.FC<React.PropsWithChildren<{ 
    columns: number
    borderTopOff?: boolean
}>> = (props) => {
    const style:React.CSSProperties = props.borderTopOff ? {
        borderTop: "none"
    } : {};
    return <tr><td style={style} colSpan={props.columns}>{props.children}</td></tr>
}
const VTableCssStyle:CSSProperties = {
    tableLayout: "fixed"
}
export function SortGlyph<D>(props:{
    direction:string|null|undefined,
    column: VTableColumn<D>
}) 
{
    if(!props.direction)
        return null;

    if(props.column.sort)
    {
        const sortOption = props.column.sort.find(r => r.direction === props.direction)
        if(sortOption) {
            if(sortOption.sortGlyphOrJsx === undefined) //null will not get here, allowing for a way to have no entry
            {
                //no glyph defined
                if(sortOption.direction === 'ASC')
                    return <Glyphicon glyph={'arrow-down'} />
                else if(sortOption.direction === 'DESC')
                    return <Glyphicon glyph={'arrow-up'} />
                else {
                    //non-standard direction, will optionally not render
                    return null;
                }
            }
            else if(typeof(sortOption.sortGlyphOrJsx) === 'function')
            {
                return sortOption.sortGlyphOrJsx(props.direction);
            }
            else if(typeof(sortOption.sortGlyphOrJsx) === 'object')
            {
                return sortOption.sortGlyphOrJsx;
            }
            else {
                return <Glyphicon glyph={sortOption.sortGlyphOrJsx} />
            }
        }
    }
    console.error('props.column.sort is misconfigured');
    return null; //no need to totally implode if this is misconfigured
}
function VTablePagingBar<D=unknown>({disabled, paging: { page, pageCount, setPage, ...restPaging }, ...props}:{ 
    paging:PagingState<D> 
    itemCountName?: { singular: string, plural: string },   
    count: number,
    disabled?:boolean
})
{
    return <PaginationWidget 
        disabled={disabled} 
        pagination={{ page, perPage: restPaging.pageSize, totalRecords: props.count, pageCount }} 
        setPage={setPage}
        itemCountName={props.itemCountName} 
        zeroIndexed={true}
    />
}
function TreeFlair({treeDepth}:{treeDepth:number})
{
    const jsx = [...Array(treeDepth)].map((x,i) => <React.Fragment key={i}>&nbsp;&nbsp;</React.Fragment>);
    return <>{jsx}<Glyphicon glyph="arrow-right" />&nbsp;</>
}

function getCellVal<D>(row:D, column: VTableColumn<D>, cellRenderProps:VTableBasicCellRenderProps<D>)
{
    let cellVal = typeof(column.accessor) === 'function' 
            ? column.accessor(cellRenderProps) 
            : ( (typeof(column.accessor) === "string" || typeof(column.accessor) === "number") 
                && typeof(row) === 'object') && row ? row[column.accessor] : undefined;

    if(cellVal === null)
        cellVal = ''; //map null to empty
    else if(typeof(cellVal) !== 'number' && typeof(cellVal) !== 'string')
    {
        cellVal = ''; //unrenderable outcome as is
        console.warn(`cannot render cell w/accessor[${String(column.accessor)}] - not string|number`);
    }
    if(column.accessorResolver)
        cellVal = column.accessorResolver(cellVal); //resolve the real value and replace

    return cellVal;
}

function isColumnInDownload<D=unknown>(c:VTableColumn<D>)
{
    if(c.skipCsvExport)
        return false;
    if(c.accessor || c.accessorResolver || c.csvExportValue)
        return true;
    return false;
}

function downloadCsv<D=unknown>(exportConfig:Required<VTableProps>['csvExport'], columns:ProcessedColumn<D>[], rows:VTableRow<D>[])
{
    let csvRows:CsvCell[][] = [];
    let exportColumns = columns.filter(isColumnInDownload);
    let csvHeader = exportColumns.map(c => c.header);
    csvRows.push(csvHeader);

    rows.forEach( (row,rowIndex) => {
        let csvRow:CsvCell[] = [];
        row.cells.forEach( (cell, columnIndex) => {
            if(!isColumnInDownload(cell.column)) //skip if not in the output
                return;
            let cellRenderProps:VTableBasicCellRenderProps<D> = {
                row,
                rowIndex:row.rowIndex,
                column:cell.column,
                columnIndex,
            };
            if(cell.column.csvExportValue)
            {                
                csvRow.push(cell.column.csvExportValue(cellRenderProps));
            }
            else //if(cell.column.accessorResolver)
            {
                const cellValue = getCellVal(row.row, cell.column, cellRenderProps);
                csvRow.push(cellValue);
            }
        });

        csvRows.push(csvRow);
        
    })


    
    CsvLib.exportDataToCSV(exportConfig.filename, csvRows);
}

export type CsvExportController = {
    triggerCsvExport: () => void 
}
export function VTable<D=unknown>(props:VTableProps<D>)
{
    const { 
        noHeader=false,
        columns, 
        rows, 
        allRows,
        searchString, 
        setSearchString, 
        toggleSortBy,
        sortBy, 
        sortDirection,
        paging,
        expandedRows,
        expandGetParentRowIndex,
        disabled
     } = useTable(props);//, rows } = VT;
    // const { rowFlairEnabled } = props;
    // sortBy, setSortBy
    // const [searchString, setSearchString] = useState("");
    const searchRef = useRef<HTMLInputElement>(null);

    const thereAreRowsToSearch = props.data.length > 0;
    let searchJsx = useMemo(() => {
        if(!props.search || !thereAreRowsToSearch)  //if search isn't on, or no rows to search do not show the search bar
            return null;
        return <div className="" style={{width:"100%"}}>
            <input disabled={disabled} ref={searchRef} type="search" className="form-control" 
                placeholder={props.search.searchTextBoxPlaceholder ? props.search.searchTextBoxPlaceholder : "Search..."}
                onChange={e => setSearchString(e.currentTarget.value)} />
        </div>
    },[props.search, setSearchString, thereAreRowsToSearch, disabled]);

    const headerClickToSort = (e:React.MouseEvent) => {
        let header = e.currentTarget.attributes.getNamedItem('data-header-key');
        toggleSortBy(header ? header.value : '');
    }

    const exportTableAsCsv = useCallback(() => {
        if(props.csvExport)
            downloadCsv(props.csvExport, columns, allRows);
        else {
            alert('CSV Export not enabled');
            console.error(props.csvExport); //not going to happen, but in case we can debug
        }
    },[props.csvExport, columns, allRows]);

    useMemo(() => {
        if(props.csvExport?.csvExportControllerRef)
        {
            props.csvExport.csvExportControllerRef.current = {
                triggerCsvExport: exportTableAsCsv
            }
        }
    },[props.csvExport?.csvExportControllerRef, exportTableAsCsv]);


    const pagingJsx = paging ? <VTablePagingBar disabled={disabled} paging={paging} count={props.data.length} itemCountName={props.itemCountName} /> 
                        : <ItemCountNameBar itemCountName={props.itemCountName} count={props.data.length} />
    return(
        <>      
        {(props.leftOfPagingControl || searchJsx) && <VGrid>
            <VRow style={{display:"flex"}}>
                <VCol xs={12} md={8} style={{ margin: 'auto'}}>
                    {props.leftOfPagingControl}{pagingJsx}
                </VCol>
                <VCol xs={12} md={4} style={{ margin: 'auto'}}>
                    {searchJsx}
                </VCol>                       
            </VRow>
        </VGrid>}  
      <table className="table table-condensed" style={VTableCssStyle}>
        {!noHeader && <thead>     
            <tr>     
            {columns.map( (col,i) => <th key={i} {...col.getAttributes()}>
                <HeaderWrap column={col} sortBy={sortBy} sortDirection={sortDirection} headerClickToSort={headerClickToSort} /> 
                {/* {col.headerComponent ? 
                    <col.headerComponent 
                        column={col} 
                        onClick={headerClickToSort} 
                        sortDirection={sortBy === col.header ? sortDirection : null}
                        sortPropsForAnchor={{ "data-header-key": col.header }}
                    /> : 
                    col.sort ? <a data-header-key={col.header} onClick={headerClickToSort}>{col.header}
                    {sortBy === col.header && <SortGlyph column={col} direction={sortDirection}/>}</a> : col.header} */}
            </th>)}
          </tr>
        </thead>}
        <tbody>
        {
            (rows.length === 0) ? //if no data
                (props.loading ?    //then if there is loading flair requested, shw it
                    <FullWidth columns={columns.length}><LoadingFlair /></FullWidth>
                    : props.noDataElement ? 
                        props.search && searchString.length > 0 ? 
                            <FullWidth columns={columns.length}>{props.search.noResultsFoundElement ? props.search.noResultsFoundElement : <>No Results Found</>}</FullWidth> : 
                            <FullWidth columns={columns.length}>{props.noDataElement}</FullWidth> : null) //otherwise if there is a no data element, show it, otherwise nothing to do
            :
            rows.map( (r,index) => {
                //use the passed in search hint, or the dfault, or none if renderSearchHint is set to null
                const searchHintJsx = props.search && searchString.length > 0 && 
                    (props.search.renderSearchHint ? props.search.renderSearchHint(r.row, searchString) 
                    : props.search.renderSearchHint !== null && props.search.searchFields && defaultRenderSearchHint(props.search.searchFields, r.row, searchString))
            return(
                    <React.Fragment key={getRowKey(r.rowIndex, r.row)}>
                        <tr {...(props.extraRowProps ? props.extraRowProps({ rowIndex:r.rowIndex, row:r.row }) : undefined)}>
                            {r.cells.map( (cell,columnIndex) => {
                                // console.log(`Render cell[${i}] in row: ${r.row.id}`);
                                
                                const isExpanded = expandedRows !== undefined ? expandedRows[r.rowIndex] : undefined;
                                const cellRenderProps:VTableCellRenderProps<D> = {
                                    row:r,
                                    rowIndex:r.rowIndex,
                                    column:cell.column,
                                    columnIndex,
                                    isExpanded,
                                    treeDepth: expandGetParentRowIndex ? getNodeDepth<D>(expandGetParentRowIndex, r.row) : 0,
                                    searchString: searchString.length > 0 ? searchString : undefined,
                                }
                                let treeFlairJsx = null;
                                if(cell.column.addTreeIndentFlair && cellRenderProps.treeDepth) {
                                    treeFlairJsx = <TreeFlair treeDepth={cellRenderProps.treeDepth} />
                                }
                                if(cell.column.component)
                                {
                                    const CellComp = cell.column.component;
                                    return <td key={columnIndex} {...cell.getAttributes()}>{treeFlairJsx}<CellComp {...cellRenderProps}/></td>
                                }
                                // let cellVal = typeof(cell.column.accessor) === 'function' 
                                //             ? cell.column.accessor(cellRenderProps) 
                                //             : ( (typeof(cell.column.accessor) === "string" || typeof(cell.column.accessor) === "number") 
                                //                 && typeof(r.row) === 'object') && r.row ? r.row[cell.column.accessor] : undefined;
                                
                                // if(cellVal === null)
                                //     cellVal = ''; //map null to empty
                                // else if(typeof(cellVal) !== 'number' && typeof(cellVal) !== 'string')
                                // {
                                //     cellVal = ''; //unrenderable outcome as is
                                //     console.warn(`cannot render cell w/accessor[${cell.column.accessor}] - not string|number`);
                                // }
                                // if(cell.column.accessorResolver)
                                //     cellVal = cell.column.accessorResolver(cellVal); //resolve the real value and replace
                                
                                const cellVal = getCellVal(r.row, cell.column, cellRenderProps);

                                const titleProp = cell.column.valueInTitle && cellVal !== undefined ? 
                                    { title: cellVal.toString() } : {};

                                return <td key={columnIndex} {...titleProp} {...cell.getAttributes()}>
                                    {treeFlairJsx}
                                    {cellVal === undefined ? "No accessor" : 
                                        cellRenderProps.searchString ? <BoldFirstMatch source={cellVal.toString()} subString={searchString} /> : cellVal
                                    }
                                    </td>
                            })}
                        </tr>
                        {searchHintJsx &&
                        <FullWidth key={getRowKey(r.rowIndex, r.row, props.rowKey) + '_search'} columns={columns.length} borderTopOff={true}>
                            {searchHintJsx}
                        </FullWidth>}
                </React.Fragment>
            );
        })} 
        </tbody>
      </table>
        {props.paging && <VGrid>
            <VRow>
                <VCol xs={12} md={8}>
                    {props.leftOfPagingControl}{pagingJsx}
                </VCol>
            </VRow>
        </VGrid>}
        {props.csvExport && !props.csvExport.hideCsvExportButton && rows.length > 0 && <div>
            <VBetterButton type="button" onClick={exportTableAsCsv}><FontAwesomeIcon icon={faDownload} />&nbsp; Export to CSV</VBetterButton>
        </div>}
      </>
    );
}

function HeaderWrap<D=unknown>({column,sortBy,sortDirection,headerClickToSort}: {
    column:VTableColumn<D>,
    sortBy?:string,
    sortDirection?:string,
    headerClickToSort?: (e: React.MouseEvent) => void
})
{
    if(column.headerComponent)
    {
        if(!column.sort || column.headerComponentManagesSort ) //no sort options or self sorting
        {
            return <column.headerComponent 
                column={column} 
                onClick={headerClickToSort} 
                sortDirection={sortBy === column.header ? sortDirection : null}
                sortPropsForAnchor={{ "data-header-key": column.header }}
            />
        }
        else  //no click to sort, then if there IS sort, we wrap
        {
            return <a data-header-key={column.header} onClick={headerClickToSort}>
                <column.headerComponent 
                    column={column} 
                    onClick={headerClickToSort} 
                    sortDirection={sortBy === column.header ? sortDirection : null}
                    sortPropsForAnchor={{ "data-header-key": column.header }}
                />
                {sortBy === column.header && <SortGlyph column={column} direction={sortDirection}/> }
            </a>
        }
    }
    if(column.sort)
        return <a data-header-key={column.header} onClick={headerClickToSort}>
                {column.header}{sortBy === column.header && <SortGlyph column={column} direction={sortDirection}/>}
        </a>
    
    return <>{column.header}</>
}
/*
        // hooks => {
        //     hooks.visibleColumns.push(columns => [
        //       // Let's make a column for selection
        //       {
        //         id: 'selection',
        //         // The header can use the table's getToggleAllRowsSelectedProps method
        //         // to render a checkbox
        //         Header: ({ getToggleAllRowsSelectedProps }) => (
        //           <div>
        //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        //           </div>
        //         ),
        //         // The cell can use the individual row's getToggleRowSelectedProps method
        //         // to the render a checkbox
        //         Cell: ({ row }) => (
        //           <div>
        //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        //           </div>
        //         ),
        //       },
        //       ...columns,
        //     ])
        //   });
*/

// export type SortOptionNumber<D,K extends keyof D> = {
//     direction: string,
//     key: D[K] extends number ? K : never,
//     sortCallback: (a:D, b:D) => number,
//     sortGlyphOrJsx: string|((direction:string) => JSX.Element)|JSX.Element
// }

// function makeStandardNumberSortOptions<T extends Record<K, number>,K=keyof T>(rowKey: T[K] extends number ? K : never)
// {
//     return useMemo( () => {
//         const sortDirections:SortOption<T>[] = [
            
//         ]
//         return sortDirections;
//     ]},[]);
// }       

// const sortNumberAsc = 
// {
//     direction: 'ASC',
//     sortCallback: (a:T, b:T) => {
//         const x = a[y];

//         return a[rowKey] - b[rowKey]
//     },
//     sortGlyphOrJsx: 'arrow-down',
// }


// export const VTableSortStandardNumber:SortOption<T>[] = [
//     {
//         direction: 'ASC',
//         sortCallback: (a: T, b:T) => {
//             return a.duration - b.duration;
//         }
//     }
// ]