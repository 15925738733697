import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner } from '@fortawesome/pro-duotone-svg-icons';


import './VButton.css';

export type VButtonSize = "xs"|"sm"|"default"|"lg";
type VButtonType = 'primary'|'danger'|'warning'|'info'|'default'|'link'|'success';

export interface VButtonProps
{
    className?:string, //merged into className on end
    size?: VButtonSize; //normal is default
    type?: VButtonType; //primary is default
    busy?: boolean,
    disabled?: boolean,
    children?: any,  //is this needed? -> or is there an 'extends react-props' type of thing
    clickValue?: any, //passed back on click
    onClick?: (clickValue:any, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    glyph?:"plus"|"wrench"|"copy"|"trash"|"search"|"time", //add as needed?  Or just bit bullet and add all?
    glyphSeparator?:boolean,
    isSubmitButton?: boolean,
    style?: React.CSSProperties
}

export function VButton(props:VButtonProps)
{
    //extras of special type to allow HTML Button attribs
    let extras:React.ButtonHTMLAttributes<HTMLButtonElement> = {};
    if(props.busy)
    {
        //button is busy, so disable it just in case
        extras.disabled = true;
    }
    if(props.disabled)
        extras.disabled = props.disabled;
        
    if(props.onClick)
    {
        extras.onClick = function(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)
        {
            if(props.onClick) props.onClick(props.clickValue, event);
        }
    }
    if(props.isSubmitButton)
        extras.type="submit";
    else
        extras.type="button";
    //setup the element's class with type + sizing
    let className = "btn";
    if(props.type)
        className += ' btn-' + props.type; //types are currently bs class suffixes
    else
        className += ' btn-primary';
    
    switch(props.size)
    {
        case "xs": className += " btn-xs"; break;
        case "sm": className += " btn-sm"; break;
        case "lg": className += " btn-lg"; break;
    }
    let glyphiconJsx = null;
    if(props.glyph)
    {
        let glyphClass = `glyphicon glyphicon-${props.glyph}`;
        glyphiconJsx = <span className={glyphClass}></span>;
    }

    if(props.className)
        className += ' ' + props.className;
    //pass extras to the button via destructuring 
    let normalButton = <button className={className} {...extras} style={props.style}>{glyphiconJsx}{props.children}</button>;

    //if busy, wrap the button with our current busy indicator + hidden styling
    if(props.busy)
    {
        return(<span className="vloading">{normalButton}</span>);
    }
    return(normalButton);
}

export interface VButtonProps
{
    size?: VButtonSize; //normal is default
    type?: VButtonType; //primary is default
    busy?: boolean,
    disabled?: boolean,
    children?: any,  //is this needed? -> or is there an 'extends react-props' type of thing
    clickValue?: any, //passed back on click
    onClick?: (clickValue:any, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    glyph?:"plus"|"wrench"|"copy"|"trash"|"search"|"time", //add as needed?  Or just bit bullet and add all?
    glyphSeparator?:boolean,
    isSubmitButton?: boolean,
    style?: React.CSSProperties
}


type VBetterButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    bsSize?:VButtonSize,
    bsType?:VButtonType,
    busy?: boolean|null|undefined
    // busy2?: boolean|null
    glyph?:"plus"|"wrench"|"copy"|"trash"|"search"|"time", //add as needed?  Or just bit bullet and add all?
    // isSubmitButton?: boolean //not needed - just put "type" on the real button, pass through
};
export const VBetterButton = React.forwardRef<HTMLButtonElement,VBetterButtonProps>( (props, ref) => 
{
    const { bsSize, bsType='primary', busy, className,  ...restProps } = props;

    let ourClass = "btn btn-" + bsType;
    switch(bsSize)
    {
        case "xs": ourClass += " btn-xs"; break;
        case "sm": ourClass += " btn-sm"; break;
        case "lg": ourClass += " btn-lg"; break;
    }
    if(className)
        ourClass = className + ' ' + ourClass;

    let glyphiconJsx = null;
    if(props.glyph)
    {
        let glyphClass = `glyphicon glyphicon-${props.glyph}`;
        glyphiconJsx = <span className={glyphClass}></span>;
    }
    const buttonJsx = <button ref={ref} className={ourClass} disabled={busy?true:false} {...restProps}>{glyphiconJsx}
        {busy ? <span style={{position:'relative'}}><span style={{visibility:"hidden"}}>
            {props.children}
        </span>
        <span className="faLoadingBreakoutAndCenter"><FontAwesomeIcon icon={faSpinner} spin={true}/></span>
        </span> : <>{props.children}</>}
    </button>;

    // if(busy)
    // {
    //     return(<span className="vloading btn">{buttonJsx}</span>);
    // }
    return buttonJsx;
});
