import { GpsData, GeofenceMap, CustomFieldDataGeneric } from './ClockEvent';
import { ApiGuid } from './ApiGuid';

export enum OfflineEventClockType {
    clockIn = 'in',
    clockOut = 'out',
}

export interface OfflineEvent {
    // Minumum Required Fields
    rootGuid: ApiGuid,              // On clock in rootGuid can optionally be defined by the client to make tracking offline events easier.
    type: OfflineEventClockType,           
    employeeGuid: ApiGuid,
    createDate: Date,

    // Optional clock in/out options
    jobGuid?: ApiGuid,              // Used by clock in only to select job
    serviceItemGuid?: ApiGuid,      // Used by clock in only to select service item
    report?: string,
    customFieldData?: CustomFieldDataGeneric[],
    
    // These fields are only used if location is enabled.
    gpsAuthStatus?: string,
    gpsError?: string,
    gpsData?: GpsData,

    flags: number, 

    // These values in app_clock_event_details for gps locate feature
    devicePlatform?: number,
    notificationsEnabled?: boolean,
    geofences?:GeofenceMap
}

type KioskOfflineClockEventCommon = {
    rootGuid: ApiGuid, //clock in event, it was created 
    employeeGuid: ApiGuid,
    createTimestamp: number,

    report: string | null,
    customFieldData: CustomFieldDataGeneric[]

    selfie?: {
        filename: string,    // path prefixed by file:// for use in img components
        extraData: {
            height: number,
            width: number,
            // orientation: number,
            size: number,           //bytes
        }
    }
}
export type KioskOfflineClockInEvent = KioskOfflineClockEventCommon & {
    type: OfflineEventClockType.clockIn //in or out - eventually edit? maybe not since the event structure is too different

    startTimestamp: number,
    jobGuid: ApiGuid|null,
    serviceItemGuid: ApiGuid|null,
}
export type KioskOfflineClockOutEvent = KioskOfflineClockEventCommon & {
    type: OfflineEventClockType.clockOut //in or out - eventually edit? maybe not since the event structure is too different
    
    endTimestamp: number,
}

export const CommonPictureSyncOperationList = ['clockIn','clockOut'] as const;
export type CommonPictureSyncOperation = typeof CommonPictureSyncOperationList[number];

export type KioskOfflineEvent = KioskOfflineClockInEvent | KioskOfflineClockOutEvent;
export type WorkerAppOfflineClockInEvent = KioskOfflineClockInEvent;
export type WorkerAppOfflineClockOutEvent = KioskOfflineClockOutEvent;
export type WorkerAppOfflineEvent = WorkerAppOfflineClockInEvent | WorkerAppOfflineClockOutEvent; 