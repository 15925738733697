import { ApiGuid } from './ApiGuid';
import { ThirdPartyContactAllOptionalForCreate } from './Contact';
import { ThingStatus } from './Thing';
import { DeepPartial } from './TypescriptHelpers';

export const MaxNestedJobs = 5;

//query options are combined
export type JobStatus = ThingStatus;

export interface JobQueryOptions
{
    //defaults to 'active' if ommitted
    status?: JobStatus|'activeInactive'|JobStatus[],

    //searchs the job NAME or CODE for this text
    // will enhance soon to search the combined job code AND name as well, and possibly the job description
    searchText?: string,

    //exact match job code - 
    jobCode?: number,

    //include the access control information if true (which group/employees have access to the job or are denied access)
    includeAccessControlList?: boolean,
    includeAdminNotes?: boolean,

    jobGuids?: ApiGuid[]

    extraJobGuids?: ApiGuid[] //extra jobs to query for - like deleted jobs amongst actives

    teamView?: boolean, //if true, requesting access for team view purposes
    modifiedSinceDate?: string,
}
export type GroupEmployeeAssignment = 
{
    employeeGuids:ApiGuid[],
    groupGuids:ApiGuid[]
}
export type Job =
{
    guid: ApiGuid,
    code: number, //soon to be string
    name: string,
    description: string,
    parentGuid: ApiGuid|null,
    createDate: string,
    updateDate: string,
    status: JobStatus,
    accessControl: 'none'|'denyList'|'allowList',
    otExempt: boolean,
    autoDeductionExempt: boolean,
    ptoExempt: boolean,
    employeeAccessList?: ApiGuid[], //employee guid list denied/allowed by .accessControl setting - this data is only included if .includeAccessControlList=true in the api query 
    groupAccessList?: ApiGuid[], //group guid list denied/allowed by .accessControl setting - this data is only included if .includeAccessControlList=true in the api query

    assignment?: GroupEmployeeAssignment,
    adminCanAssign: boolean,    //if assignments are not all, can an admin/manager override the assignments and use the job for someone that doesn't otherwise have it assigned/access

    budgetDollars: number|null,
    budgetHours: number|null,
    budgetDollarsMonthly: number|null,
    budgetHoursMonthly: number|null,
    budgetDollarsWeekly: number|null,
    budgetHoursWeekly: number|null,
    budgetDollarsDaily: number|null,
    budgetHoursDaily: number|null,
    budgetDollarsPayroll: number|null,
    budgetHoursPayroll: number|null,
    thirdPartyContactGuid: ApiGuid|null, //todo - move INTO thirdPartyContact probably
    otherInfo1: string|null,
    otherInfo2: string|null,
    otherInfo3: string|null,
    otherInfo4: string|null,
    otherInfo5: string|null,
    otherInfo6: string|null,

    addressLine1:string,
    addressLine2:string,
    city:string,
    countryCode:string,
    stateCode:string,
    postalCode:string,
    phoneNumber1:string,
    phoneNumber2:string,
    privateNotes?:string,
    updateSyncId: number,   //incremented each time employee is updated
}

type JobExtraCreateUpdateParams = {
    parentCode?:number|null          //can optionally specify the parent's job code, instead of guid, during create - can work with job from higher in import list as it will be created by the time this job gets inserted    
    parentName?:string|null         //used by integrations
    syncId?: string | undefined;
    syncName?: string | undefined;
    jobGuid?: string | undefined;
    jobType?: "Customer" | "Job" | undefined;
    customData?: string 
} & {
    thirdPartyContact?: ThirdPartyContactAllOptionalForCreate
}

type JobCreateParams = {
    code?: number|null
}
export type JobAllOptional = DeepPartial<Omit<Job,'code'> & JobCreateParams>;
export type JobForCreate = Omit<JobAllOptional & Required<Pick<Job,'name'>>,'guid'|'createDate'|'code'|'updateSyncId'> & JobExtraCreateUpdateParams & JobCreateParams;
export type JobForUpdate = Omit<JobAllOptional & Required<Pick<Job,'guid'>>,'createDate'> & JobExtraCreateUpdateParams;
export type JobForCreateOrUpdate = JobForCreate|JobForUpdate;
export function isJobForUpdate(job:Partial<JobForUpdate>):job is JobForUpdate
{
    return typeof(job.guid) !== "undefined";
}
export type JobForKioskActive = Pick<Job,'guid'|'code'|'name'|'description'|'parentGuid'|'createDate'|'accessControl'|'assignment'|'adminCanAssign'> & {status:'active'}
export type JobForKioskInactiveDeleted = {
    guid: ApiGuid,
    status: 'inactive'|'deleted',
};
export type JobForKiosk = JobForKioskActive | JobForKioskInactiveDeleted;
export function isJobForKioskActive(job:JobForKiosk):job is JobForKioskActive
{
    if(job.status === 'active')
        return true;
    return false;
}

// Make them actually distinct but still mean the same properties
// by using inheritance, rather than just pointing them to the same thing
export interface JobForWorkerAppActive extends JobForKioskActive {}
export interface JobForWorkerAppInactiveDeleted extends JobForKioskInactiveDeleted {}

//export type JobForWorkerAppActive = JobForKioskActive;
// export type JobForWorkerAppInactiveDeleted = JobForKioskInactiveDeleted;
export type JobForWorkerApp = JobForWorkerAppActive | JobForWorkerAppInactiveDeleted;
export function isJobForWorkerAppActive(job:JobForWorkerApp):job is JobForWorkerAppActive
{
    if(job.status === 'active')
        return true;
    return false;
}
export type JobBudgetRange = 'allTime'|'monthly'|'weekly'|'daily'|'payroll';

export type JobBudgetInfo = {
    hasTimeGuard: boolean
    missingRatesByPayrollItem: {
        employeeGuid: ApiGuid,
        employeeFullName: string,           //"eddard stark"
        payrollItems: string[],             //array of items by name ["Regular"]
        _payrollItem: Record<string, true> //helper map - {Regular: true}, 

    }[],
    missingPayrollItemsByTimeType: {
        _timeType: Record<string, true> //time type helper map
        employeeFullName:string,
        employeeGuid: ApiGuid, 
        timeTypes:string[]
    }[],

    totalDollars: number,
    totalHours: number
}


export type JobBudgetTypeInfo = {
    label: string,
    range: JobBudgetRange
    hours: 'budgetHours'|'budgetHoursMonthly'|'budgetHoursWeekly'|'budgetHoursDaily'|'budgetHoursPayroll',
    dollars: 'budgetDollars'|'budgetDollarsMonthly'|'budgetDollarsWeekly'|'budgetDollarsDaily'|'budgetDollarsPayroll'
}
export const AllJobBudgets:JobBudgetTypeInfo[] = [
    {   label: "All Time Cost/Budget",
        range: 'allTime',
        hours: 'budgetHours',
        dollars: 'budgetDollars',
    },
    {   label: "Monthly Cost/Budget",
        range: 'monthly',
        hours: 'budgetHoursMonthly',
        dollars: 'budgetDollarsMonthly',
    },
    {   label: "Weekly Cost/Budget",
        range: 'weekly',
        hours: 'budgetHoursWeekly',
        dollars: 'budgetDollarsWeekly',
    },

    {   label: "Daily Cost/Budget",
        range: 'daily',
        hours: 'budgetHoursDaily',
        dollars: 'budgetDollarsDaily',
    },

    {   label: "Payroll Cost/Budget",
        range: 'payroll',
        hours: 'budgetHoursPayroll',
        dollars: 'budgetDollarsPayroll',
    },
];


export interface RequestJobCreateUpdateListParams {
    jobs: Array<JobForCreate|JobForUpdate>
    clientType?: number
}