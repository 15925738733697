import { ApiGuid } from './ApiGuid';
import { ThingStatus } from './Thing';

export interface PayrollInfo {
    guid: ApiGuid,
    name: string,
    description: string,
    status: "active"|"inactive"|"deleted",
    type: "hourly"|"overtime"|"salary",
    linkedGuid: ApiGuid|null,
    linkedName: string|null,
    multiplier: number|null,
    createdDate: string,
    updateSyncId: number,   //incremented each time employee is updated
}

export type PayrollInfoForCreate = Partial<Omit<PayrollInfo, 'guid'|'linkedName'|'createdDate'|'updateSyncId'>> & Required<Pick<PayrollInfo,'name'|'type'>>
export type PayrollInfoForUpdate = Partial<Omit<PayrollInfo, 'linkedName'|'createdDate'>> & Required<Pick<PayrollInfo,'guid'>>
export type PayrollInfoForCreateOrUpdate = PayrollInfoForCreate|PayrollInfoForUpdate;
export function isPayrollItemForUpdate(pi:Partial<PayrollInfoForUpdate>):pi is PayrollInfoForUpdate
{
    return typeof(pi.guid) !== "undefined";
}
export interface EmployeePayrollInfo {
    payrollItemGuid: ApiGuid,
    payrollItemName: string,
    payrollValueType: "hourly"|"overtime"|"salary",
    payrollValue: number|null,
    linkedGuid: ApiGuid|null,
    linkedName: string|null,
    multiplier?: number|null,
}

export interface EditEmployeePayrollInfoOptions {
    employeeGuid: ApiGuid,
    payrollItemGuid: ApiGuid,
    payrollValue:number|null,
}

export type RequestEmployeesPayrollInfoParams = {
    employeeStatus: ThingStatus[]
}
export type EmployeesPayrollInfoResponse = {
    payrollMap: {
        [key: ApiGuid]: EmployeePayrollInfo[]
    }
}

export type PayrollItemQueryStatusType = ThingStatus|ThingStatus[];
export type PayrollItemsQueryParams = {
    status: PayrollItemQueryStatusType
}