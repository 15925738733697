import { ApiGuid } from "./ApiGuid";
import { ThingStatus } from "./Thing";

export const enum CustomFieldType
{
    List="list",
    Number="number",
    String="string"
}
const validCustomFieldTypes:Record<CustomFieldType,true> = {
    'list': true,
    'number': true,
    'string': true   
}
export const CustomFieldTypeList = Object.keys(validCustomFieldTypes) as CustomFieldType[];
export function isValidCustomFieldType(type:string):boolean
{    
    return validCustomFieldTypes[type as CustomFieldType] === true
}

export type CustomFieldBase =
{
    guid: ApiGuid,
    status: ThingStatus,
    type: CustomFieldType,
    name: string,
    clockInEnabled: boolean,
    clockOutEnabled: boolean,
    phoneEnabled: boolean,
    smsEnabled: boolean,
    webEnabled: boolean,
    adminOnly: boolean,         //was defined as adminOnly?: boolean, removed ? - unsure why it was optional
    employeeCanView: boolean,
    globalOn: boolean,
    globalOptional: boolean,
    jobs: ApiGuid[],
    employees: ApiGuid[],
    groups: ApiGuid[],
    serviceItems: ApiGuid[], //added ~2023-10-20 - all in DB do not have it...so need to construct it if not present
    //added 2023-11-08 - used for exclude filters
    includeAllItems: boolean, 
    excludeJobs: ApiGuid[],
    excludeEmployees: ApiGuid[],
    excludeGroups: ApiGuid[],
    excludeServiceItems: ApiGuid[],
}
export type CustomFieldNumber = CustomFieldBase &
{
    type: CustomFieldType.Number,
    maxValue:number|null,
    minValue:number|null,
}
export type CustomFieldString = CustomFieldBase & {
    type: CustomFieldType.String,
    maxLength: 512, //todo - make user configurabel
}

export type CustomFieldListItem =
{
    code: number,
    name: string,
    description: string,
    guid: ApiGuid,
}

export type CustomFieldList = CustomFieldBase &
{
    listItems: CustomFieldListItem[]
}

export type CustomField = CustomFieldString | CustomFieldNumber | CustomFieldList;
export type CustomFieldForUpdate = CustomField;
export type CustomFieldForCreate = Omit<CustomFieldForUpdate, 'guid'>;
export type CustomFieldForCreateOrUpdate =CustomFieldForUpdate| CustomFieldForCreate;
export type CustomFieldForCreateOrUpdateForm = Partial<CustomFieldForCreateOrUpdate>;

export function isCustomFieldString(field:CustomField|CustomFieldForCreateOrUpdate):field is CustomFieldString { return field.type === CustomFieldType.String; }
export function isCustomFieldNumber(field:CustomField|CustomFieldForCreateOrUpdate):field is CustomFieldNumber { return field.type === CustomFieldType.Number; }
export function isCustomFieldList(field:CustomField|CustomFieldForCreateOrUpdate):field is CustomFieldList { return field.type === CustomFieldType.List; }

export function isCustomFieldForUpdate(field:CustomFieldForCreateOrUpdate|CustomFieldForCreateOrUpdateForm):field is CustomFieldForUpdate
{
    return (field as any).guid !== undefined; //if guid is undefined, it is for create, and thus not an update version        
}

export type CreateCustomFieldRequestParams = {
    customField: CustomFieldForCreate
}
export type CreateCustomFieldResponse = {
    customField: CustomField
}
export type UpdateCustomFieldRequestParams = {
    customField: CustomFieldForUpdate
}
export type UpdateCustomFieldResponse = {
    customField: CustomField
}
export type DeleteCustomFieldResponse = {
    customField: null
}
export type MoveCustomFieldRequestParams = {
    customField:CustomFieldForUpdate, 
    direction: 'up'|'down'
}
export type MoveCustomFieldResponse = {
    customFields: CustomField[] //respond with ALL custom fields so as to allow simplistic replacement with the latest incase there were access from another location
}