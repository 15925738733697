// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("mozilla_blu.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vloading>* {
    visibility: hidden;
}
.vloading {
    background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});
	background-position:center;
	background-repeat:no-repeat;
}

.faLoadingBreakoutAndCenter {
    position:absolute;
    right:50%;
    transform: translateX(50%);
}`, "",{"version":3,"sources":["webpack://./webApp/src/ui/components/core/VButton/VButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,wDAAqC;CACxC,0BAA0B;CAC1B,2BAA2B;AAC5B;;AAEA;IACI,iBAAiB;IACjB,SAAS;IACT,0BAA0B;AAC9B","sourcesContent":[".vloading>* {\n    visibility: hidden;\n}\n.vloading {\n    background-image:url(mozilla_blu.gif);\n\tbackground-position:center;\n\tbackground-repeat:no-repeat;\n}\n\n.faLoadingBreakoutAndCenter {\n    position:absolute;\n    right:50%;\n    transform: translateX(50%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
