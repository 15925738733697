// import { DayOfWeek, TimeOfDay } from "./Datetime";

import { ApiGuid } from "./ApiGuid"
import { PtoSettings } from "./Pto"


export type TimeSettings = {
    managersCanUseGroupClock: boolean,          //true => managers can group clock their managed employees in
    managersCanEditTimesheets: boolean,         //true => managers can edit timesheets of their managed employees
    managersCanApproveTimesheets: boolean,     //true if defined as true, or if undefined && managersCanEditTimesheets is undefined or true as well
    preventEditingOfApprovedTime: boolean,      //true => approved time cannot be edited
    gpsSettings: {
        useGPSPermissionWarnings: boolean,        //true => if GPS settings on app are in conflict with what is needed, a warning is shown
        forceGPSPermissions: boolean              //true => if warnings would be shown, they block clock in
    },
    signature: SignatureSettings,
    pto: PtoSettings,
    inToOutTime: boolean,
    inToOutTimeThreshold: number,
    popoverShiftEditor: boolean,
    preventWebClock: boolean,
    preventAppClock: boolean,
    requireClockInPhoto: boolean,
    requireClockOutPhoto: boolean,
    requireClockInPhotoOnKiosk: boolean,
    requireClockOutPhotoOnKiosk: boolean,
    createEventDurationMode: 'allowBoth'|'onlyDuration'|'onlyTimeStamp',
    oldEventEditBlock: {
        type: "none"|"priorWeek"|"priorMonth"|"priorPayPeriod"|"specificDate"|"hoursAfterStart",
        specificDate: string|undefined,
        dayOffset: number,
        hoursAfterStart: number
    },
    timeEntryMinimumRulesEnabled: boolean
}

export type SignatureSettings = {
    enabled:boolean,
    allEmployees:boolean,
    employeeList:ApiGuid[], //api version uses employee guid array
    autoSend: boolean,
    autoSendDay: number,
    autoSendTime: string,
    autoSendMode: SignatureAutoSendMode,
    canSignManagersAcct: boolean,
    canSignExternal: boolean,
    requireManagerSig: boolean,
    exportSignedTimeOnly: boolean,
    regularEmployeeCanSearch: boolean,
    underTotalsTextOn: boolean,
    underTotalsText: string,
    sendEmailNotification: boolean,
    sendSmsNotification: boolean,
    sendPushNotification: boolean,
}

export enum SignatureAutoSendMode {
    DAILY = "daily",
    WEEKLY = "weekly",
    PAYROLL = "payroll",
}

export interface TpaSignatureSettings {
    id?:number,
    enabled:boolean,
    canSignOnEmployeeAccount:boolean,
    canSignOnPaper:boolean,
    canUseSms:boolean,
    autoSend:false,
    autoSendDay:number,
    autoSendTime:string,
    autoSendMode:SignatureAutoSendMode,
    tpaOrderedReportFields:string[],
    allEmployees:boolean,
    groupGuids:ApiGuid[],
    employeeGuids:ApiGuid[],
    allJobs:boolean,
    jobGuids:ApiGuid[],
    contactEmployeeGuid:ApiGuid|null,
    ccEmails:boolean,
    includeActivityNotes:boolean,
    includeEmployeeEmail:boolean,
    includeEmployeeHomePhone:boolean,
    includeEmployeeCellPhone:boolean,
    includeEmployeeOtherPhone:boolean,
    includeEmployeeOtherInfo1:boolean,
    includeEmployeeOtherInfo2:boolean,
    includeEmployeeOtherInfo3:boolean,
    includeWageAndCost:boolean,
    useFooter:boolean,
    footerText:string,
    useStringCustomization:boolean,
    stringCustomizationText:string,
}

export const TimeSettingsDefaults = {
    inToOutTimeThreshold: 5,
    oldEventEditBlock: {
        type: 'none',
        dayOffset: 0,
        hoursAfterStart: 48
    }
}

export type PermissionSettings = {
    managersCanEditAlerts?: boolean,
    managersCanEditJobs?: boolean,
    managersCanViewAllJobs?: boolean, //going away - replaced with two new checkboxes. If THIS is unchecked historically, then the two new checkboxes will be checked by default
    managersCanViewJobsAssignedToTheirManagedGroups?: boolean,
    managersCanViewJobsAssignedToTheirManagedEmployees?:boolean,
    managersCanGenerateSignatureRequests:boolean,
    managersCanEditSchedules: boolean,
    managersCanEditEmployees?: boolean,
    managersCanViewReports?: boolean,
    managersCanApprovePTO?: boolean,
    managersCanEditServiceItems?:boolean //stubbed out
    managersCanViewAuditTrail?: boolean
    managersCanViewFullAuditTrail?: boolean
    managersCanSkipRequiredFieldsOnTimesheetEdit?: boolean,

    //controls how job assignements are honored
    employeeJobAssignmentApplicability?: 'individualAndGroup'|'individualOnly'|'groupOnly'; //historical default is 'individualAndGroup'
}

export type TimeRoundingType = 'nearest'|'down'|'up';

export type TimeManipulationSettings = {
    addEnabled: boolean,
    addHours: number,
    addSign: number,
    approvedTime: 'all'|'approved'|'not_approved',
    perHours: number,
    roundClockInTime: number,
    roundClockInTimeDirection: TimeRoundingType,
    roundClockOutTime: number,
    roundClockOutTimeDirection: TimeRoundingType,
    roundClockInFirstShiftOnly: boolean,
    roundClockOutLastShiftOnly: boolean,
}

export type ScheduledDeduction = {
    guid?:ApiGuid,
    selectedAllEmployees: boolean,
    selectedEmployeeList: ApiGuid[],
    selectedGroupList: ApiGuid[],
    deductHours: number,
    deductHoursPer: number,
    deductStart: string,
}

export type TimeEntryMinimumRule = {
    employee: 'any'|ApiGuid,
    group : 'any'|ApiGuid,
    job: 'any'|ApiGuid,
    serviceItem: 'any'|ApiGuid
    minutes: number,
    createDate: string|null
}
export type TimeEntryMinimumRuleForCreate = Omit<TimeEntryMinimumRule,'createDate'>;

export type QueryTimeEntryMinimumRuleResponse = {
    rules: TimeEntryMinimumRule[]
}
export type CreateTimeEntryMinimumRuleParams = {
    rule: TimeEntryMinimumRuleForCreate
}

export type CreateTimeEntryMinimumRuleResponse = {
    rule: TimeEntryMinimumRule
}

export type DeleteTimeEntryMinimumRulesParams = {
    rules: TimeEntryMinimumRule[]  
};

export type TimeEntryMinimumRuleEngineLookupFunctionParams = {
    serviceItemGuid: ApiGuid|null,
    employeeGuid:ApiGuid|null, 
    groupGuid:ApiGuid|null,
    jobGuid:ApiGuid|null
}
export type TimeEntryMinimumRuleEngineLookupFunction = (options:TimeEntryMinimumRuleEngineLookupFunctionParams) => TimeEntryMinimumRule|null;
