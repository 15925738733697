import { ApiGuid } from "./ApiGuid"

export type PayrollItemRuleOriginal = {

    payType: number,
    employeeGuid: ApiGuid | null,
    employeeName: string
    groupGuid: ApiGuid | null,
    groupName: string
    jobGuid: ApiGuid | null,
    jobName: string,
    serviceItemGuid: ApiGuid | null,
    serviceItemName: string,
    payrollItemGuid: ApiGuid | null,
    payrollItemName: string,
}
export type PayrollItemRuleOriginalForCreateUpdate = Omit<PayrollItemRuleOriginal,'employeeName'|'jobName'|'groupName'|'serviceItemName'|'payrollItemName'>
export type PayrollItemRule = {
    timeType: number,
    employee: 'any'|ApiGuid,
    group : 'any'|ApiGuid,
    job: 'any'|ApiGuid,
    serviceItem: 'any'|ApiGuid
    payrollItem: 'none'|ApiGuid
    createDate: string
}
export type PayrollItemRuleForCreate = Omit<PayrollItemRule,'createDate'>

export function convertPayrollItemRuleOriginalToApi(rule:PayrollItemRuleOriginal):PayrollItemRule
{
    return {
        timeType: rule.payType,
        employee: rule.employeeGuid === null ? 'any' : rule.employeeGuid,
        group: rule.groupGuid === null ? 'any' : rule.groupGuid,
        job: rule.jobGuid === null ? 'any' : rule.jobGuid,
        serviceItem: rule.serviceItemGuid === null ? 'any' : rule.serviceItemGuid,
        payrollItem: rule.payrollItemGuid === null ? 'none' : rule.payrollItemGuid,
        createDate: 'not set'
    }
}
export function convertPayrollItemRuleApiToOriginal(rule:PayrollItemRuleForCreate):PayrollItemRuleOriginalForCreateUpdate
{
    return {
        payType: rule.timeType,
        employeeGuid: rule.employee === 'any' ? null : rule.employee,        
        groupGuid: rule.group === 'any' ? null : rule.group,        
        jobGuid: rule.job === 'any' ? null : rule.job,        
        serviceItemGuid: rule.serviceItem === 'any' ? null : rule.serviceItem,        
        payrollItemGuid: rule.payrollItem === 'none' ? null : rule.payrollItem,
    }
}